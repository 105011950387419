import { Avatar, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import noimage from "../../assets/no-image.png";
import { ColumnType, Table } from "../table";

export function SupplierDeliveryDistributingDetailLine({ list }: { list: any }) {
  const columns = useHeader();

  return <Table columns={columns} name="supplier-delivery-distributing-lines.list" dataSource={list} pagination={false} />;
}

const useHeader = (): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "80px",
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Avatar src={record?.image ?? noimage} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "DeHUB код",
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.refCode ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "SKU",
    dataIndex: "skuCode",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.skuCode ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Бараа нэр",
    dataIndex: "nameApp",
    render: (record) => {
      if (record?.nameApp) {
        return (
          <Text size="sm" c="#44566C" w="max-content">
            {record?.nameApp ?? "-"}
          </Text>
        );
      } else {
        return (
          <Text size="sm" c="#44566C" w="max-content">
            {record?.name ?? "-"}
          </Text>
        );
      }
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.unit?.name || record?.unit || "-"}
        </Text>
      );
    },
  },
  {
    title: "Захиалсан тоо",
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record.quantity ?? 0}
        </Text>
      );
    },
  },
  {
    title: "Хүлээлгэж өгсөн тоо",
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record.confirmedQuantity ?? 0}
        </Text>
      );
    },
  },
];
