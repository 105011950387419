import { ActionIcon, Avatar, Badge, Box, Button, Divider, Flex, Grid, Group, Input, LoadingOverlay, Select, Text, Tooltip } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconChevronDown, IconClearAll, IconFileText, IconInfoSquare, IconPhoto, IconShoppingCart } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSWR from "swr";
import { NetworkApi, StaffApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { PageLayout } from "../../components/layout";
import { ColumnType, Table } from "../../components/table";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { INetwork } from "../../interfaces/INetwork";
import { IPermissions } from "../../interfaces/IPermissions";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export function SupplierCustomerList() {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [regNumber, setRegNumber] = React.useState("");
  const [debouncedRegNumber] = useDebouncedValue(regNumber, 600);
  const [partnerName, setPartnerName] = React.useState("");
  const [debouncedPartner] = useDebouncedValue(partnerName, 600);
  const [businessName, setBusinessName] = React.useState("");
  const [debouncedBusiness] = useDebouncedValue(businessName, 600);
  const [staff, setStaff] = React.useState("");
  const [debouncedStaff] = useDebouncedValue(staff, 600);
  const [regionName, setRegionName] = React.useState("");
  const [debouncedRegion] = useDebouncedValue(regionName, 600);
  const [categoryName, setCategoryName] = React.useState("");
  const [debouncedCategory] = useDebouncedValue(categoryName, 600);
  const [priorityName, setPriorityName] = React.useState("");
  const [debouncedPriority] = useDebouncedValue(priorityName, 600);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { distributionAreas, clientClassifications } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    user,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          navigate(`/supplier-customer/detail/${record.id}`);
          break;
        }
        case "order": {
          navigate(`/supplier-customer/order/${record.id}`);
          break;
        }
        default:
      }
    },
  });

  const { data: staffData } = useSWR(`/ord/staff/select/data`, async () => {
    try {
      if (!user?.currentBusinessId) {
        return [];
      } else if ((user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0) {
        let res = await StaffApi.select({ businessId: user?.currentBusinessId });
        return res;
      }
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
  });

  const excel = async () => {
    setIsLoading(true);
    try {
      let res = await NetworkApi.list({
        filter: {
          regNumber: "",
          partnerName: "",
          businessName: "",
          staffId: "",
          regionId: "",
          categoryId: "",
          priorityId: "",
          paymentTermId: "",
          excel: `${true}`,
        },
        offset: {
          limit: 20,
          page: 1,
        },
      });

      window.open(res.excelUrl as string, "_blank");
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
    setIsLoading(false);
  };

  const onClear = () => {
    setRegNumber("");
    setPartnerName("");
    setBusinessName("");
    setStaff("");
    setRegionName("");
    setCategoryName("");
    setPriorityName("");
  };

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <PageLayout
      title="Харилцагчийн жагсаалт"
      subTitle="Гэрээт харилцагчдын жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[
        <Flex key={1} gap="md" align="center">
          <Tooltip label="Файл" color="dark" position="bottom" withArrow={false} onClick={() => excel()}>
            <ActionIcon variant="outline" size="lg">
              <IconFileText />
            </ActionIcon>
          </Tooltip>
        </Flex>,
      ]}>
      <Flex direction={"column"} gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
        <Box
          sx={(theme) => ({
            background: theme.white,
            borderRadius: 8,
            border: `1px solid ${theme.colors.gray[2]}`,
            padding: "16px 20px",
            height: "100%",
            minHeight: "60vh",
          })}
          w={"100%"}>
          <Grid mb="xs">
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <Input
                value={regNumber as any}
                leftSectionWidth={80}
                placeholder="хайх"
                style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                leftSection={
                  <Group w={"calc(100% - 20px)"} align="center">
                    <Text size="sm" fw={450} c="#44566C">
                      {"ТТД"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
                onChange={(e: any) => {
                  setRegNumber(e?.currentTarget?.value);
                }}
                minLength={7}
                maxLength={7}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <Input
                value={partnerName}
                leftSectionWidth={160}
                placeholder="хайх"
                maxLength={100}
                style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                leftSection={
                  <Group w={"calc(100% - 20px)"} align="center">
                    <Text size="sm" fw={450} c="#44566C">
                      {"Партнерийн нэр"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
                onChange={(e) => {
                  setPartnerName(e.currentTarget.value);
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <Input
                placeholder="сонгох"
                leftSectionWidth={150}
                value={businessName}
                onChange={(e) => {
                  setBusinessName(e.currentTarget.value);
                }}
                maxLength={45}
                leftSection={
                  <Group w={"calc(100% - 20px)"} align="center">
                    <Text size="sm" fw={450} c="#44566C">
                      {"Бизнесийн нэр"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
              />
            </Grid.Col>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0 && (
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Select
                  data={(staffData || [])?.map((c: any) => {
                    return {
                      label: c?.firstName,
                      value: c?.id,
                    };
                  })}
                  placeholder="сонгох"
                  leftSectionWidth={150}
                  onChange={(e: string | null) => {
                    if (e) setStaff(e);
                  }}
                  value={staff}
                  style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                  rightSection={<IconChevronDown size="1rem" stroke={1} />}
                  clearable
                  leftSection={
                    <Group w={"calc(100% - 20px)"} align="center">
                      <Text size="sm" fw={450} c="#44566C">
                        {"Ажилтны нэр"}
                      </Text>
                      <Text>:</Text>
                    </Group>
                  }
                />
              </Grid.Col>
            )}

            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <Select
                data={(distributionAreas || [])
                  ?.filter((c) => c.isParent === true)
                  ?.map((c: any) => {
                    return {
                      label: c?.name,
                      value: c?.id,
                    };
                  })}
                placeholder="сонгох"
                leftSectionWidth={150}
                onChange={(e: string | null) => {
                  if (e) setRegionName(e);
                }}
                value={regionName}
                style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                rightSection={<IconChevronDown size="1rem" stroke={1} />}
                clearable
                leftSection={
                  <Group w={"calc(100% - 20px)"} align="center">
                    <Text size="sm" fw={450} c="#44566C">
                      {"Бүсчлэл"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <Select
                data={(clientClassifications || [])
                  ?.filter((c: any) => c.isParent === true)
                  ?.map((c: any) => {
                    return {
                      label: c?.name,
                      value: c?.id,
                    };
                  })}
                placeholder="сонгох"
                leftSectionWidth={150}
                onChange={(e: string | null) => {
                  if (e) setCategoryName(e);
                }}
                value={categoryName}
                style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                rightSection={<IconChevronDown size="1rem" stroke={1} />}
                clearable
                leftSection={
                  <Group w={"calc(100% - 20px)"} align="center">
                    <Text size="sm" fw={450} c="#44566C">
                      {"Ангилал"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
              />
            </Grid.Col>

            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <Select
                data={(clientClassifications || [])
                  ?.filter((c: any) => c.isParent === false)
                  ?.map((c: any) => {
                    return {
                      label: c?.name,
                      value: c?.id,
                    };
                  })}
                placeholder="сонгох"
                leftSectionWidth={150}
                onChange={(e: string | null) => {
                  if (e) setPriorityName(e);
                }}
                value={priorityName}
                style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                rightSection={<IconChevronDown size="1rem" stroke={1} />}
                clearable
                leftSection={
                  <Group w={"calc(100% - 20px)"} align="center">
                    <Text size="sm" fw={450} c="#44566C">
                      {"Зэрэглэл"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
              />
            </Grid.Col>

            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <Group align="right">
                <ActionIcon
                  onClick={() => {
                    onClear();
                  }}
                  color=""
                  variant="light"
                  size={36}>
                  <IconClearAll />
                </ActionIcon>
              </Group>
            </Grid.Col>
          </Grid>
          <Divider color="gray.5" my={20} />
          <Table
            name="supplier-contract-customer.list"
            filters={{
              regNumber: debouncedRegNumber,
              partnerName: debouncedPartner,
              businessName: debouncedBusiness,
              staffId: debouncedStaff,
              regionId: debouncedRegion,
              categoryId: debouncedCategory,
              priorityId: debouncedPriority,
              paymentTermId: "",
              excel: `${false}`,
            }}
            columns={columns}
            loadData={(data) => NetworkApi.list(data!)}
          />
        </Box>
      </Flex>
    </PageLayout>
  );
}

const useHeader = ({ onClick, user }: { user: any; onClick: (key: string, record: INetwork) => void }): ColumnType<INetwork>[] => [
  {
    title: "Үйлдэл",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_NET").length > 0 ? (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isCreate).length > 0 ? (
            <Tooltip label="Шинэ захиалга" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("order", record)}>
                <IconShoppingCart />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Шинэ захиалга" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconShoppingCart />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Партнер нэр",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.partner?.refCode ?? "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.partner?.businessName ?? "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Бизнес нэр",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.refCode ?? "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.profileName ?? "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C">
          {record?.regNumber ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээн авах салбар",
    sorter: true,
    dataIndex: "receiverBrach",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.receiverBrach?.refCode ?? "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.receiverBrach?.name ?? "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Менежер",
    sorter: true,
    dataIndex: "staff",
    render: (record) => {
      return (
        <Flex gap={10} align="center">
          <Avatar src={record?.staff?.avatar ? record?.staff?.avatar : noimage}>
            <IconPhoto />
          </Avatar>
          <Text size="sm" c="#44566C">
            {record?.staff?.lastName ? record?.staff?.lastName[0] : "-"}.{record?.staff?.firstName ?? "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Санхүү",
    sorter: true,
    dataIndex: "receiverFinStaff",
    render: (record) => {
      return (
        <Flex gap={10} align="center">
          <Avatar src={record?.receiverFinStaff?.avatar ? record?.receiverFinStaff?.avatar : noimage}>
            <IconPhoto />
          </Avatar>
          <div>
            <Text size="sm" c="#44566C">
              {record?.receiverFinStaff?.lastName ? record?.receiverFinStaff?.lastName[0] : "-"}.
              {record?.receiverFinStaff?.firstName ? record?.receiverFinStaff?.firstName : "-"}
            </Text>
            <Text size="sm" c="cyan">
              {record?.receiverFinStaff?.email ? record?.receiverFinStaff?.email : "-"}
            </Text>
          </div>
        </Flex>
      );
    },
  },
  {
    title: "Бүсчлэл",
    sorter: true,
    dataIndex: "region",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C">
          {record?.region?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Чиглэл",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C">
          {record?.direction?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Ангилал",
    sorter: true,
    dataIndex: "category",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C">
          {record?.category?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Зэрэглэл",
    sorter: true,
    dataIndex: "priority",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C">
          {record?.priority?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "businessStatus",
    render: (record) => {
      return record?.businessStatus === "ACTIVE" ? (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          color="green"
          styles={{
            label: {
              overflow: "visible",
              textOverflow: "unset",
            },
          }}>
          Идэвхтэй
        </Badge>
      ) : (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          color="gray"
          styles={{
            label: {
              overflow: "visible",
              textOverflow: "unset",
            },
          }}>
          Идэвхгүй
        </Badge>
      );
    },
  },
  {
    title: "Хариуцдаг менежер",
    sorter: true,
    dataIndex: "receiverStaff",
    render: (record) => {
      return (
        <Flex gap={10} align="center">
          <Avatar src={record?.receiverStaff?.avatar ? record?.receiverStaff?.avatar : noimage}>
            <IconPhoto />
          </Avatar>
          <div>
            <Text size="sm" c="#44566C">
              {record?.receiverStaff?.lastName ? record?.receiverStaff?.lastName[0] : "-"}.
              {record?.receiverStaff?.firstName ? record?.receiverStaff?.firstName : "-"}
            </Text>
            <Text size="sm" c="cyan">
              {record?.receiverStaff?.email ? record?.receiverStaff?.email : "-"}
            </Text>
          </div>
        </Flex>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Гэрээт харилцагчид",
  },
];
