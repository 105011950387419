import { createSlice } from "@reduxjs/toolkit";
import { IGeneral } from "../interfaces/IGeneral";

const initialState: IGeneral = {
  businessSectors: [],
  businessTypes: [],
  deliveryStatus: [],
  discountTypes: [],
  invoiceStatus: [],
  invoiceTypes: [],
  loginTypes: [],
  modules: [],
  orderStatus: [],
  orderTypes: [],
  paymentStatus: [],
  permissions: [],
  deliveryDateTypes: [],
  deliveryNoteStatus: [],
  orderPurchaseTypes: [],
  orderSalesTypes: [],
  paymentMethod: [],
  pullSheetStatus: [],
  paymentTermConditions: [],
  zipCodes: [],
  paymentTerms: [],
  distributionAreas: [],
  clientClassifications: [],
  dashboardPieFilters: [],
  menus: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    initGeneral: (state, { payload }: { payload: IGeneral }) => {
      const {
        businessSectors,
        businessTypes,
        deliveryStatus,
        discountTypes,
        invoiceStatus,
        invoiceTypes,
        loginTypes,
        modules,
        orderStatus,
        orderTypes,
        paymentStatus,
        permissions,
        deliveryDateTypes,
        deliveryNoteStatus,
        orderPurchaseTypes,
        orderSalesTypes,
        paymentMethod,
        pullSheetStatus,
        paymentTermConditions,
        zipCodes,
        paymentTerms,
        distributionAreas,
        clientClassifications,
        dashboardPieFilters,
        menus,
      } = payload;

      state.businessSectors = businessSectors;
      state.businessTypes = businessTypes;
      state.deliveryStatus = deliveryStatus;
      state.discountTypes = discountTypes;
      state.invoiceStatus = invoiceStatus;
      state.invoiceTypes = invoiceTypes;
      state.loginTypes = loginTypes;
      state.modules = modules;
      state.orderStatus = orderStatus;
      state.orderTypes = orderTypes;
      state.paymentStatus = paymentStatus;
      state.permissions = permissions;
      state.deliveryDateTypes = deliveryDateTypes;
      state.deliveryNoteStatus = deliveryNoteStatus;
      state.orderPurchaseTypes = orderPurchaseTypes;
      state.orderSalesTypes = orderSalesTypes;
      state.paymentMethod = paymentMethod;
      state.pullSheetStatus = pullSheetStatus;
      state.paymentTermConditions = paymentTermConditions;
      state.zipCodes = zipCodes;
      state.paymentTerms = paymentTerms;
      state.distributionAreas = distributionAreas;
      state.clientClassifications = clientClassifications;
      state.dashboardPieFilters = dashboardPieFilters;
      state.menus = menus;

      return state;
    },

    updateBusinessSectors: (state, { payload }: { payload: any }) => {
      state.businessSectors = payload;

      return state;
    },
  },
});

export const generalReducer = generalSlice.reducer;

export const { initGeneral, updateBusinessSectors } = generalSlice.actions;
