import { ActionIcon, Alert, Avatar, Badge, Box, Button, Divider, Flex, Grid, Group, Modal, Text } from "@mantine/core";
import { IconDeviceFloppy, IconEdit, IconPhoto, IconTextPlus, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import noimage from "../../assets/no-image.png";
import { IAttachments } from "../../interfaces/IAttachments";
import { IPackageGoods } from "../../interfaces/IPackageGoods";
import { currencyFormat } from "../../utils";
import { dateFormat } from "../../utils/date";
import { NumberField } from "../form/number-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { ColumnType, Table } from "../table";
import { BuyerCustomerDynamicAddForm } from "./dynamic-add-form";
import { BuyerCustomerDynamicPackageForm } from "./dynamic-package-form";

type Props = {
  values?: any;
  setFieldValue?: any;
  errors?: any;
  paymentData?: any;
};

export function BuyerCustomerDynamicForm({ values, setFieldValue, errors, paymentData }: Props) {
  const now_date = new Date();
  const [edit, setEdit] = useState<string>("");
  const [action, setAction] = useState<string[]>([]);

  const columns = useHeader({
    edit,
    setEdit,
    setFieldValue,
    onClick: (key, item) => {
      if (key === "remove") {
        setFieldValue("lines", [...values.lines.filter((i: any, index: number) => item.index !== index)]);
      } else if (key === "edit") {
        setAction(["edit", item]);
      }
    },
  });

  const columnsAttachments = useHeaderAttachments({
    edit,
    setEdit,
    onClick: (key, item) => {
      if (key === "remove") {
        setFieldValue("attachments", [...values.attachments.filter((i: any, index: number) => item.index !== index)]);
      } else if (key === "edit") {
        setAction(["edit", item]);
      }
    },
  });

  const len = Object.keys(values?.lines || [])?.length;

  return (
    <Box
      sx={(theme) => ({
        background: theme.white,
        borderRadius: 1,
        border: `1px solid ${theme.colors.gray[2]}`,
        padding: "0px 20px",
      })}>
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          {"Бараа бүтээгдэхүүн, ажил үйлчилгээ"}
        </Text>
        <Flex gap="md" align="center">
          <ActionIcon variant="outline" size="md" color="#3BC9DB">
            <IconTextPlus onClick={() => setAction(["add", values])} />
          </ActionIcon>
          <Button variant="outline" size="xs" onClick={() => setAction(["package", values])} color="#3BC9DB">
            Багцаар нэмэх
          </Button>
        </Flex>
      </Flex>
      <Divider color="gray" my="xs" />
      <Table columns={columns} name="values.lines" dataSource={values.lines} pagination={false} />
      <Divider color="gray" my="xs" />
      <Alert color="red" title={errors.items} withCloseButton hidden={!errors.items}>
        Та бараа оруулна уу.
      </Alert>
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          {"Захиалгын нэгтгэл"}
        </Text>
      </Flex>
      <Divider color="gray" my="xs" />
      <Grid mb={5}>
        <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
          <Grid>
            <Grid.Col span={12}>
              <TextareaField name="senderNote" label="Харилцагчийн нэмэлт мэдээлэл" placeholder="Тайлбар оруулах: " max={255} />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextareaField name="senderAdditionalNote" label="Нэмэлт тэмдэглэл" placeholder="Тэмдэглэл оруулах: " max={255} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
          <Box
            h="100%"
            sx={(theme) => ({
              backgroundColor: theme.white,
              borderLeft: `2px outset #5fc6d9`,
            })}
            px={20}
            py={16}>
            <Grid>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Захиалгад тоо
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {len} төрөл
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Нийт тоо ширхэг
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {values?.lines?.reduce((total: number, item: any) => total + item!.quantity || 0, 0) || 0} ширхэг
                </Text>
              </Grid.Col>

              <Grid.Col span={12}>
                <Text size="lg" fw={500} c="#25262B">
                  {"Тооцоолол"}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Захиалгын нийт дүн
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat(values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ml={47}>
                  Тооцсон НӨАТ
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat((values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ml={47}>
                  Тооцсон НХАТ
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat((values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Тооцсон хөнгөлөлт
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat(
                    (values?.lines || [])?.reduce(
                      (total: number, item: any) =>
                        total +
                          (item?.discountType === "PERCENTAGE"
                            ? (item?.price * item?.quantity * item?.discountValue) / 100
                            : item?.quantity * item?.discountValue) || 0,
                      0,
                    ) || 0,
                  )}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  {"Нийт дүн"}
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {!values?.lines
                    ? currencyFormat(0)
                    : currencyFormat(
                        values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                          (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                          (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                          (values?.lines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? (item?.price * item?.quantity * item?.discountValue) / 100
                                  : item?.quantity * item?.discountValue) || 0,
                            0,
                          ),
                      )}
                </Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Төлбөр баталгаажуулах
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                {!values?.lines || !paymentData ? (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(0)}
                  </Text>
                ) : paymentData?.condition === "COD" ? (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(
                      ((values?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                        (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                        (values?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                        (values?.lines || [])?.reduce(
                          (total: number, item: any) =>
                            total +
                              (item?.discountType === "PERCENTAGE"
                                ? (item?.price * item?.quantity * item?.discountValue) / 100
                                : item?.quantity * item?.discountValue) || 0,
                          0,
                        )) *
                        paymentData?.advancePercent) /
                        100,
                    )}
                  </Text>
                ) : (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(0)}
                  </Text>
                )}
              </Grid.Col>

              <Grid.Col span={6}>
                <Text size="lg" fw={500} c="#44566C">
                  Төлбөр баталгаажуулах огноо, цаг
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                {paymentData?.condition === "COD" ? (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {dateFormat(now_date.setDate(now_date.getDate() + paymentData?.confirmationDay))}
                  </Text>
                ) : (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {"-"}
                  </Text>
                )}
              </Grid.Col>
            </Grid>
          </Box>
        </Grid.Col>
      </Grid>
      <Divider color="gray" my="xs" />
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          {"Хавсралт нэмэх"}
        </Text>
        <Flex gap="md" align="center">
          <ActionIcon variant="outline" size="md" color="#3BC9DB">
            <IconTextPlus
              onClick={() => {
                setFieldValue("attachments", [
                  ...(values?.attachments || []),
                  {
                    name: "",
                    unit: "",
                    price: 0,
                    quantity: 0,
                    discountType: "",
                    discountValue: 0,
                    isEdit: true,
                    isNew: true,
                  },
                ]);
              }}
            />
          </ActionIcon>
        </Flex>
      </Flex>
      <Divider color="gray" my="xs" />

      <Table columns={columnsAttachments} name="values.attachments" dataSource={values.attachments} pagination={false} />
      <Divider color="gray" my="xs" />
      <Modal
        opened={action[0] === "package"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="1200px"
        padding={0}
        centered>
        <BuyerCustomerDynamicPackageForm values={values} setFieldValue={setFieldValue} setAction={setAction} />
      </Modal>
      <Modal
        opened={action[0] === "add"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="900px"
        padding={0}
        centered>
        <BuyerCustomerDynamicAddForm values={values} action={action} setFieldValue={setFieldValue} setAction={setAction} />
      </Modal>
    </Box>
  );
}

const useHeader = ({
  edit,
  setEdit,
  onClick,
  setFieldValue,
}: {
  edit: any;
  setFieldValue: any;
  setEdit: any;
  onClick: (e: string, e1: any) => void;
}): ColumnType<IPackageGoods>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Group>
        <Avatar size={40} src={record?.image !== null ? record?.image : noimage} radius={5}>
          <IconPhoto />
        </Avatar>
      </Group>
    ),
  },
  {
    title: "SKU код",
    dataIndex: "skuCode",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.skuCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бараа нэр, хувилбарын нэр",
    dataIndex: "nameApp",
    render: (record, index) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.nameApp || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.unit?.name || record?.unit || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    render: (record, index) => {
      return (
        <Group align="right">
          <Text size="sm" fw={500} c="#44566C">
            {currencyFormat(record?.price || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Хөнгөлөлт",
    dataIndex: "discountValue",
    render: (record, index) => {
      let count;
      count =
        record?.discountType === "PERCENTAGE"
          ? ((record?.price || 0) * (record?.quantity || 0) * record.discountValue) / 100
          : (record?.quantity || 0) * record?.discountValue;
      return (
        <Text size="sm" fw={500} c="#44566C">
          {currencyFormat(count || 0)}
        </Text>
      );
    },
  },
  {
    title: "Захиалах тоо",
    dataIndex: "quantity",
    render: (record, index) => {
      return (
        <Group align="center">
          {edit === index ? (
            <NumberField max={9000000} min={0} name={`lines[${index}].quantity`} placeholder="Тоо хэмжээ" size="xs" />
          ) : (
            <Text w={150}>
              <Badge color="cyan" size="md" radius="sm" variant="outline">
                {record.quantity || 0}
              </Badge>
            </Text>
          )}
          <ActionIcon onClick={() => setEdit(edit !== index ? index : null)}>{edit === index ? <IconDeviceFloppy /> : <IconEdit />}</ActionIcon>
        </Group>
      );
    },
  },
  {
    title: "НӨАТ",
    dataIndex: "hasVat",
    render: (record) => {
      let count;
      count = (record?.quantity || 0) * record?.vatAmount;
      return (
        <Text size="sm" fw={500} c="#44566C" w={"max-content"}>
          {currencyFormat(count)}
        </Text>
      );
    },
  },
  {
    title: "НХАТ",
    dataIndex: "hasTax",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" w={"max-content"}>
          {currencyFormat(record?.taxAmount)}
        </Text>
      );
    },
  },
  {
    title: "Үнийн дүн",
    dataIndex: "name",
    render: (record: any) => {
      let count;
      count = ((record?.price || 0) + (record?.vatAmount || 0)) * (record?.quantity || 1);
      return (
        <Group align="right">
          <Text size="sm" fw={500} c="#44566C" w={"max-content"}>
            {currencyFormat(count || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record, index) => {
      return (
        <>
          <Flex gap={8} align="center" w={"max-content"}>
            <ActionIcon onClick={() => onClick("remove", { ...record, index })} color="red">
              <IconTrash />
            </ActionIcon>
          </Flex>
        </>
      );
    },
  },
];

const useHeaderAttachments = ({ edit, setEdit, onClick }: { edit: any; setEdit: any; onClick: (e: any, e1: any) => void }): ColumnType<IAttachments>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record, index) => {
      if (record.isEdit) {
        return <TextField w={320} name={`attachments[${index}].name`} placeholder="нэр:" />;
      } else
        return (
          <Text size="sm" fw={500} c="#44566C">
            {record?.name || "-"}
          </Text>
        );
    },
  },
  {
    title: "Урл",
    dataIndex: "url",
    render: (record, index) => {
      if (record.isEdit) {
        return <TextField w={320} name={`attachments[${index}].url`} placeholder="урл:" />;
      } else
        return (
          <Text size="sm" fw={500} c="#44566C">
            {record?.url || "-"}
          </Text>
        );
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    render: (record, index) => {
      if (record.isEdit) {
        return <TextareaField name={`attachments[${index}].description`} placeholder="Тайлбар:" label="" max={100} />;
      } else
        return (
          <Text size="sm" fw={500} c="#44566C">
            {record?.description || "-"}
          </Text>
        );
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record, index) => {
      return (
        <>
          <Flex gap={8} align="center" w={"max-content"}>
            <ActionIcon onClick={() => onClick("remove", { ...record, index })} color="red">
              <IconTrash />
            </ActionIcon>
          </Flex>
        </>
      );
    },
  },
];
