import { Badge, Button, Divider, Flex, Grid, Group, Input, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { dateTimeSecFormat } from "../../utils/date";
import { Form } from "../form";
import { PageLayout } from "../layout";
import { SupplierShippingCustomerDetailDeliveryNoteList } from "./delivery-note.list";

type initData = {
  name: string;
};

const schema = yup.object({});

export function SupplierShippingCustomerDetailForm({ action }: { action: any[] }) {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const { pullSheetStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<initData>({
    name: "",
  });

  return (
    <Form validationSchema={schema} initialValues={data} onSubmit={() => {}}>
      {() => {
        return (
          <PageLayout
            title="Ачилтын падааны дэлгэрэнгүй"
            subTitle="Ачилтын дэлгэрэнгүй мэдээлэл"
            breadcrumb={breadcrumbs}
            extra={[
              <Button
                onClick={() => {
                  navigate("/supplier-shipping");
                }}
                key={1}
                variant="default">
                Буцах
              </Button>,
            ]}>
            <Divider my={20} color="gray.5" />
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Код: ">
                  <Text fw={500} c="cyan">
                    {action[1]?.refCode || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Төлөв: ">
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(pullSheetStatus || []).find((c: any) => c.code === action[1]?.pullSheetStatus)?.color} 1px solid`,
                      color: `${(pullSheetStatus || []).find((c: any) => c.code === action[1]?.pullSheetStatus)?.color}`,
                    }}>
                    {(pullSheetStatus || []).find((c: any) => c?.code === action[1]?.pullSheetStatus)?.name || "-"}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={2}>
                <Input.Wrapper label="Ачилт эхэлсэн огноо, цаг: ">
                  <Text fw={500} c="cyan">
                    {dateTimeSecFormat(action[1]?.startedDate)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={2}>
                <Input.Wrapper label="Ачилт дууссан огноо, цаг: ">
                  <Text fw={500} c="cyan">
                    {dateTimeSecFormat(action[1]?.endedDate)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Агуулахын нэр: ">
                  <Text fw={500} c="cyan">
                    {action[1]?.warehouse?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Хүргэлт хариуцсан: ">
                  <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
                    <Group>
                      <Text fw={500} c="cyan">
                        {action[1]?.staff?.lastName !== null ? action[1]?.staff?.lastName[0] : "-"}.{" "}
                        {action[1]?.staff?.firstName !== null ? action[1]?.staff?.firstName : "-"}
                      </Text>
                      <Text c="dimmed" fz={14} fw={500}>
                        {`(`}
                        {action[1]?.staff?.phone || "-"}
                        {`)`}
                      </Text>
                    </Group>
                    <Text fw={500} c="cyan">
                      {action[1]?.staff?.email || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Агуулах хариуцсан нярав: ">
                  <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
                    <Group>
                      <Text fw={500} c="cyan">
                        {action[1]?.warehouse?.warehouseUser?.lastName !== null ? action[1]?.warehouse?.warehouseUser?.lastName[0] : "-"}.{" "}
                        {action[1]?.warehouse?.warehouseUser?.firstName !== null ? action[1]?.warehouse?.warehouseUser?.firstName : "-"}
                      </Text>
                      <Text c="dimmed" fz={14} fw={500}>
                        {`(`}
                        {action[1]?.warehouse?.warehouseUser?.phone || "-"}
                        {`)`}
                      </Text>
                    </Group>
                    <Text fw={500} c="cyan">
                      {action[1]?.warehouse?.warehouseUser?.email || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Ачилт зөвшөөрсөн хэрэглэгч: ">
                  <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
                    <Group>
                      <Text fw={500} c="cyan">
                        {action[1]?.regUser?.lastName !== null ? action[1]?.regUser?.lastName[0] : "-"}.
                        {action[1]?.regUser?.firstName !== null ? action[1]?.regUser?.firstName : "-"}
                      </Text>
                      <Text c="dimmed" fz={14} fw={500}>
                        {`(`}
                        {action[1]?.regUser?.phone ?? "-"}
                        {`)`}
                      </Text>
                    </Group>
                    <Text fw={500} c="cyan">
                      {action[1]?.regUser?.email ?? "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Ачилт зөвшөөрсөн огноо, цаг:">
                  <Text fw={500} c="cyan">
                    {dateTimeSecFormat(action[1]?.createdAt)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label={`Ачилтанд хамаарах захиалгын тоо: ${action[1]?.deliveryNotes?.length ?? 0}`}>
                  {action[1]?.pullSheets?.length !== 0 ? (
                    <Group gap="xs">
                      {(action[1]?.deliveryNotes || []).map((c: any, index: number) => {
                        return (
                          <Badge size="md" radius="sm" variant="outline" key={index} mr={3}>
                            {c?.refCode || "-"}
                          </Badge>
                        );
                      })}
                    </Group>
                  ) : (
                    <Text fw={500} c="cyan">
                      {"-"}
                    </Text>
                  )}
                </Input.Wrapper>
              </Grid.Col>
            </Grid>

            <Divider my={20} color="gray.5" />

            <SupplierShippingCustomerDetailDeliveryNoteList list={action[1]?.pullSheetLines || []} />
          </PageLayout>
        );
      }}
    </Form>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/supplier-shipping",
    label: "Ачилт удирдлага",
  },
  {
    label: "Ачилтын падаан",
  },
];
