import { Button, Center, CloseButton, Divider, Flex, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { DeliveryApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";
import { message } from "../../utils/message";
import { FormLayout } from "../layout";

export function SupplierSplitForm({
  action,
  onCancel,
  reload,
  setChecked,
  success,
}: {
  action: any[];
  onCancel: () => void;
  reload: any;
  setChecked?: any;
  success: () => void;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await DeliveryApi.create([...action[1]?.map((c: any) => c.id)]);
      setChecked([]);
      setLoading(false);
      message.success("Таны хүсэлт амжилттай.");
      reload();
      onCancel && onCancel();
      success();
    } catch (error: any) {
      message.error(error.message || "Хүсэлт амжилтгүй.");
    }
    setLoading(false);
  };

  return (
    <FormLayout title="Хүргэлт хуваарилах" my={20} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
      <LoadingOverlay visible={loading} />

      <Divider color="gray.5" my={20} />

      <Center>
        <Text fw={500} fz={24} c="gray.7">
          Хүргэлт хуваарилах?
        </Text>
      </Center>

      <Center>
        <Text fw={500} fz={18} c="gray.7">
          Та хүргэлт хуваарилахдаа итгэлтэй байна уу.
        </Text>
      </Center>

      <Divider color="gray.5" my={20} />

      <Flex direction="row" justify="space-between">
        <Button variant="outline" onClick={() => onCancel && onCancel()}>
          Болих
        </Button>

        {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_ASSIGN").length > 0 && (
          <Button variant="filled" onClick={() => onSubmit()}>
            Зөвшөөрөх
          </Button>
        )}
      </Flex>
    </FormLayout>
  );
}
