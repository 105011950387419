import { Badge, Button, Flex, Text, Tooltip } from "@mantine/core";
import { IconInfoSquare } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { BackOrderApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { ISplit } from "../../interfaces/ISplit";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { dateTimeSecFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";

export function BackOrderList() {
  const { orderStatus, paymentStatus, deliveryNoteStatus, invoiceStatus, orderPurchaseTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    orderStatus,
    paymentStatus,
    orderPurchaseTypes,
    deliveryNoteStatus,
    invoiceStatus,
    onClick: (key, record) => {},
  });

  return (
    <Flex direction={"column"} gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
      <Table
        name="back-order.list"
        filters={{
          orderStatus: "",
        }}
        columns={columns}
        loadData={(data) => BackOrderApi.list(data!)}
      />
    </Flex>
  );
}

const useHeader = ({
  onClick,
  orderPurchaseTypes,
  orderStatus,
  deliveryNoteStatus,
  paymentStatus,
  invoiceStatus,
}: {
  orderPurchaseTypes: IReference[];
  orderStatus: IReference[];
  onClick: (key: string, record: ISplit) => void;
  deliveryNoteStatus: IReference[];
  paymentStatus: IReference[];
  invoiceStatus: IReference[];
}): ColumnType<ISplit>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Tooltip label="Дэлгэрэнгүй" position="bottom">
          <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
            <IconInfoSquare />
          </Button>
        </Tooltip>
      );
    },
  },
  {
    title: "PO #",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.purchaseCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "PO огноо, цаг",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {dateTimeSecFormat(record?.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "PO төрөл",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <>
          {record?.purchaseType ? (
            <Badge
              size="md"
              radius="sm"
              variant="outline"
              color="gray"
              style={{
                border: `${(orderPurchaseTypes || []).find((c: any) => c.code === record.purchaseType)?.color} 1px solid`,
                color: `${(orderPurchaseTypes || []).find((c: any) => c.code === record.purchaseType)?.color}`,
              }}
              styles={{
                label: {
                  overflow: "visible",
                },
              }}>
              {(orderPurchaseTypes || [])?.find((c: any) => c?.code === record?.purchaseType)?.name || "-"}
            </Badge>
          ) : (
            <Text size="sm" c="#44566C">
              {"-"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Худалдан авагч",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.senderBusiness?.partner?.refCode || "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.senderBusiness?.partner?.businessName || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.senderBusiness?.refCode || "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.senderBusiness?.profileName || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Нийлүүлэгчийн нэр",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.receiverBusiness?.partner?.refCode || "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.receiverBusiness?.partner?.businessName || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.receiverBusiness?.refCode || "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.receiverBusiness?.profileName || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "PO статус",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      if (record?.type === "SALES") {
        return (
          <Badge
            size="md"
            radius="sm"
            variant="outline"
            style={{
              border: `${(orderStatus || []).find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
              color: `${(orderStatus || []).find((c: any) => c.code === record.orderStatus)?.color}`,
            }}
            styles={{
              label: {
                overflow: "visible",
              },
            }}>
            {(orderStatus || []).find((c: any) => c.code === record.orderStatus)?.sentName || "-"}
          </Badge>
        );
      } else {
        return (
          <Badge
            size="md"
            radius="sm"
            variant="outline"
            style={{
              border: `${(orderStatus || []).find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
              color: `${(orderStatus || []).find((c: any) => c.code === record.orderStatus)?.color}`,
            }}
            styles={{
              label: {
                overflow: "visible",
              },
            }}>
            {(orderStatus || []).find((c: any) => c.code === record.orderStatus)?.receivedName || "-"}
          </Badge>
        );
      }
    },
  },
  {
    title: "Хүргэлт огноо",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {dateTimeSecFormat(record?.deliveryDate || "-")}
        </Text>
      );
    },
  },
  {
    title: "Хүргэлт статус",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <>
          {record?.deliveryNote !== null ? (
            <Badge
              size="md"
              radius="sm"
              variant="outline"
              style={{
                border: `${(deliveryNoteStatus || []).find((c: any) => c.code === record?.deliveryNote?.deliveryNoteStatus)?.color} 1px solid`,
                color: `${(deliveryNoteStatus || []).find((c: any) => c.code === record?.deliveryNote?.deliveryNoteStatus)?.color}`,
              }}
              styles={{
                label: {
                  overflow: "visible",
                },
              }}>
              {(deliveryNoteStatus || []).find((c: any) => c?.code === record?.deliveryNote?.deliveryNoteStatus)?.name || "-"}
            </Badge>
          ) : (
            <Text size="sm" c="#44566C">
              {"-"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Хүргэлт хариуцсан",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.deliveryNote?.staff?.lastName || "-"}, {record?.deliveryNote?.staff?.firstName || "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.deliveryNote?.staff?.email || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Хүлээн авах салбар",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.receiverBranch?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээн авах ажилтан",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="cyan" w="max-content">
          {record?.receiverStaff?.lastName || "-"}, {record?.receiverStaff?.firstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Захиалгын дүн",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {tugrug(record?.orderAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Төлсөн дүн",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {tugrug(record?.paidAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Үлдэгдэл төлбөр",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {tugrug(record?.amountToPay || 0)}
        </Text>
      );
    },
  },
  {
    title: "Төлбөрийн статус",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <>
          {record?.invoiceId !== null ? (
            <Badge
              size="md"
              radius="sm"
              variant="outline"
              style={{
                border: `${(paymentStatus || []).find((c: any) => c.code === record?.invoice?.paymentStatus)?.color} 1px solid`,
                color: `${(paymentStatus || []).find((c: any) => c.code === record?.invoice?.paymentStatus)?.color}`,
              }}
              styles={{
                label: {
                  overflow: "visible",
                },
              }}>
              {(paymentStatus || []).find((c: any) => c.code === record?.invoice?.paymentStatus)?.name || "-"}
            </Badge>
          ) : (
            <Text size="sm" c="#44566C">
              {"-"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Нэхэмжлэх №",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.invoice?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх статус",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <>
          {record?.invoiceId !== null ? (
            <Badge
              size="md"
              radius="sm"
              variant="outline"
              style={{
                border: `${(invoiceStatus || []).find((c: any) => c.code === record?.invoice?.invoiceStatus)?.color} 1px solid`,
                color: `${(invoiceStatus || []).find((c: any) => c.code === record?.invoice?.invoiceStatus)?.color}`,
              }}
              styles={{
                label: {
                  overflow: "visible",
                },
              }}>
              {(invoiceStatus || []).find((c: any) => c.code === record?.invoice?.invoiceStatus)?.name || "-"}
            </Badge>
          ) : (
            <Text size="sm" c="#44566C">
              {"-"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Нэхэмжлэх баталсан",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#243f60">
          {(record?.type === "SALES" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
            ? `${record?.respondedUser?.lastName ? record?.respondedUser?.lastName[0] : "-"}.${record?.respondedUser?.firstName || "-"}`
            : `${record?.reviewedUser?.lastName ? record?.reviewedUser?.lastName[0] : "-"}.${record?.reviewedUser?.firstName || "-"}`}
        </Text>
      );
    },
  },
  {
    title: "Төлбөрийн нөхцөл",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.paymentTerm?.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "PO өгсөн",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.type === "PURCHASE" ? `${record?.orderedUser?.lastName[0] || "-"}.${record?.orderedUser?.firstName || "-"}` : "-"}
        </Text>
      );
    },
  },
  {
    title: "PO хянасан",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C">
          {(record?.type === "PURCHASE" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
            ? `${record?.respondedUser?.lastName ? record?.respondedUser?.lastName[0] : "-"}.${record?.respondedUser?.firstName || "-"}`
            : `${record?.reviewedUser?.lastName ? record?.reviewedUser?.lastName[0] : "-"}.${record?.reviewedUser?.firstName || "-"}`}
        </Text>
      );
    },
  },
];
