import { ActionIcon, Button, Center, CloseButton, Divider, Flex, LoadingOverlay, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconExclamationMark } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router";
import { OrderApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { FormLayout } from "../layout";

type Props = {
  action?: any;
  setActions?: any;
  reload?: any;
  reviewed?: any;
};

export function SupplierSalesDynamicReviewForm({ action, setActions, reload, reviewed }: Props) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = async (value: string) => {
    setLoading(true);
    try {
      switch (value) {
        case "REJECT": {
          await OrderApi.review(action?.id, {
            review: false,
          });
          setActions([]);
          message.success("Хүсэлтийг амжилттай гэрээт харилцагчийн захиалгыг татгалзсан.");
          if (reviewed !== "reviewed") {
            reload();
          } else {
            navigate("/supplier-sales");
          }
          break;
        }
        case "CONFIRM": {
          await OrderApi.review(action?.id, {
            review: true,
          });
          setActions([]);
          message.success("Хүсэлтийг амжилттай гэрээт харилцагчийн захиалгыг зөвшөөрсөн.");
          if (reviewed !== "reviewed") {
            reload();
          } else {
            navigate("/supplier-sales");
          }
          break;
        }
      }
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <div className={classes.style}>
      <FormLayout title="Захиалга хянах" subTitle="Гэрээт харилцагчийн захиалгыг хянах" extra={[<CloseButton key="cancel" onClick={() => setActions([])} />]}>
        <LoadingOverlay visible={loading} />
        <Divider color="gray" my="xs" />
        <Flex sx={{ position: "relative" }} direction="column" gap="md">
          <Center>
            <ActionIcon size={100} radius={100} variant="light" color="yellow">
              <IconExclamationMark size="4.125rem" />
            </ActionIcon>
          </Center>
          <Center>
            <Text fw={500} fz={24} c="gray.7">
              Захиалга хянахдаа итгэлтэй байна уу?
            </Text>
          </Center>
          <Center>
            <Text w={"540px"} ta="center" c="gray.8" fz={18}>
              Таны захиалсан <span style={{ fontSize: 18, fontWeight: 500 }}>{action?.salesCode !== null ? action?.salesCode : action?.purchaseCode} </span>{" "}
              дугаартай захиалгын мэдээлэл <span style={{ fontSize: 18, fontWeight: 500 }}>{action?.receiverBusiness?.profileName || "-"}</span>- д автоматаар
              илгээгдэх болно. Хэрэв та захиалга хянах үйлдэлдээ итгэлтэй байвал 'Зөвшөөрөх' сонгот хийнэ үү. Үгүй бол 'Татгалзах' сонголт хийнэ үү.
            </Text>
          </Center>

          <Divider color="gray" mt={20} />
          <Flex direction="row" justify="space-between" mb={15}>
            <Button variant="outline" onClick={() => setActions([])}>
              Болих
            </Button>

            <Flex gap="md">
              <Button
                variant="light"
                color="red"
                onClick={() => {
                  onSubmit("REJECT");
                }}>
                Татгалзах
              </Button>
              <Button
                variant="filled"
                onClick={() => {
                  onSubmit("CONFIRM");
                }}>
                Зөвшөөрөх
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </FormLayout>
    </div>
  );
}

const useStyles = createStyles(() => ({
  style: {
    marginTop: 20,
  },
}));
