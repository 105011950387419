/* eslint-disable react-hooks/exhaustive-deps */
import { Image, LoadingOverlay, Modal } from "@mantine/core";
import { IconBorderAll } from "@tabler/icons-react";
import { useState } from "react";
import useSwr from "swr";
import { GeneralApi } from "../../apis";
import Background from "../../assets/background-blur.svg";
import BackgroundLine from "../../assets/background-line.svg";
import BackgroundPattern from "../../assets/bg-pattern.png";
import LoginImage from "../../assets/logo.svg";
import CreateLayout from "../../components/create-layout/create-layout";
import VerifyPage from "./verify";
import { createStyles } from "@mantine/emotion";

const CreatePage = () => {
  const [action, setAction] = useState<any[]>([]);
  const { classes } = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, isLoading } = useSwr(`pub/init/general`, async () => await GeneralApi.initPub(), {
    fallbackData: {
      partnerTypes: [],
    },
  });

  if (isLoading) {
    return <LoadingOverlay visible />;
  } else
    return (
      <CreateLayout>
        <Image className={classes.background} src={Background} />
        <Image className={classes.backgroundLine} src={BackgroundLine} />
        <div className={classes.container}>
          <div className={classes.image}>
            <img src={LoginImage} alt={"svg"} />
          </div>
          <div className={classes.card}>
            <img className={classes.bgBlur} src={BackgroundPattern} alt="" />
            <div className={classes.content}>
              <div>
                <div className={classes.row}>
                  <IconBorderAll color="white" />
                  <div className={classes.text}>DeHub Network</div>
                </div>
                <div className={classes.loginText}>Бүртгүүлэх</div>
              </div>
            </div>
          </div>
        </div>

        <Modal size={750} title={false} withCloseButton={false} centered opened={action[0] === "verify"} onClose={() => {}} padding={0}>
          <VerifyPage action={action} setAction={setAction} />
        </Modal>
      </CreateLayout>
    );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    height: "100%",
    maxWidth: "1600px",
    margin: "auto",
    paddingTop: 150,
  },
  card: {
    position: "relative",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ffffff09",
    flexWrap: "wrap",
  },
  content: {
    zIndex: 1,
    display: "flex",
    paddingInline: 40,
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    paddingTop: 35,
    paddingBottom: 35,
  },
  bgBlur: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  image: {
    height: "800px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: theme.white,
    fontSize: 16,
    fontWeight: 400,
  },
  loginText: {
    marginTop: 15,
    fontSize: 40,
    color: theme.white,
    fontWeight: 800,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  background: {
    position: "absolute",
    opacity: 0.5,
    bottom: 0,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
  },
  info: {
    display: "flex",
    color: "white",
  },
}));

export default CreatePage;
