import { LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router";
import useSwr from "swr";
import { OrderApi } from "../../apis";
import { SupplierSalesForm } from "../../components/supplier-sales/form";

export function SupplierSalesEdit() {
  const params = useParams();

  const { data, isLoading, isValidating } = useSwr(
    `/ord/order/supplier/get.${params.id}.${params.receiverBusinessId}`,
    async () => await OrderApi.get(params.id as string),
    {
      fallback: { data: null },
    },
  );

  if (!data && isLoading && isValidating)
    return (
      <div>
        <LoadingOverlay visible />
      </div>
    );

  return (
    <>
      {data && (
        <>
          <LoadingOverlay visible={isLoading} />
          <SupplierSalesForm action={["edit", data]} />
        </>
      )}
    </>
  );
}
