/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider, Grid, Input, Text } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSWR from "swr";
import { IAuth } from "../../interfaces/IAuth";
import { authDeliveryApprove } from "../../store/Auth";
import { dateTimeSecFormat } from "../../utils/date";
import { PageLayout } from "../layout";
import SupplierDeliveryDistributingTable from "./table";

export function SupplierDeliveryDistributingForm() {
  const breadcrumbs = useBreadcrumb();
  const nowMoment = new Date();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, approve } = useSelector((state: { auth: IAuth }) => state.auth);
  const [pullSheet, setPullSheet] = React.useState<string>();
  const [deliveryData, setDeliveryData] = React.useState<string>(approve.deliveryNotes);

  const { data } = useSWR(`/ord/delivery_note/approve.${pullSheet}`, async () => {
    if (!pullSheet) {
      return [];
    } else {
      let res = (approve?.warehouses || []).filter((item: any) => item.id !== pullSheet);
      let deliveryApprove = {
        warehouses: res,
        deliveryNotes: approve.deliveryNotes,
        staff: approve.staff,
        // delivery: (approve.deliveryNotes || []).map((c: any) => {
        //   (c.lines || []).map((c: any) => c.variantId);
        // }),
        delivery: approve.deliveryNotes,
      };

      dispatch(authDeliveryApprove(deliveryApprove));
      // let delivery = approve.deliveryNotes;
      // setDeliveryData(delivery);

      return (res || []).length === 0 && navigate("/supplier-delivery");
    }
  });

  let obj = Object.fromEntries((approve.deliveryNotes || []).map((c: any) => c.lines));

  return (
    <PageLayout title="Харилцагчийн захиалгын жагсаалт" subTitle="Гэрээт харилцагчид хүргэх захиалгууд" breadcrumb={breadcrumbs}>
      <Divider color="gray" />
      <Grid my="xs">
        <Grid.Col span={4}>
          <Input.Wrapper>
            <Text fw={500} c="cyan">
              {user?.lastName || "-"}, {user?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Input.Wrapper>
            <Text fw={500} c="cyan">
              {dateTimeSecFormat(nowMoment)}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Input.Wrapper>
            <Text fw={500} c="cyan">
              {"-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      <Divider color="gray" />
      <SupplierDeliveryDistributingTable
        warehouses={approve.warehouses}
        deliveryNote={approve.deliveryNotes}
        staff={approve.staff}
        setPullSheet={setPullSheet}
        delivery={(approve.deliveryNotes || []).map((c: any) => c)}
      />
      <Divider color="gray" />
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/supplier-delivery",
    label: "Хүргэлт удирдлага",
  },
  {
    label: "Ачилт зөвшөөрөх",
  },
];
