import { ActionIcon, Badge, Button, Checkbox, Divider, Flex, Grid, Group, Table as MantineTable, Modal, Select, Text, Tooltip } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import { useDebouncedValue } from "@mantine/hooks";
import { IconCalendarDue, IconChevronDown, IconClearAll, IconInfoSquare } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSWR from "swr";
import { OrderApi, SplitApi } from "../../apis";
import { SupplierSplitForm } from "../../components/supplier-split/split-form";
import { ITableRef } from "../../components/table";
import { CustomTable } from "../../components/table/customTable";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { ISplit } from "../../interfaces/ISplit";
import { dateTimeSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export function SupplierSplitList({
  setSplitChecked,
  action,
  splitChecked,
  setAction,
  success,
}: {
  action?: any;
  setSplitChecked?: any;
  splitChecked?: any;
  setAction?: any;
  success: () => void;
}) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const ref = useRef<ITableRef>(null);
  const [orderType, setOrderType] = React.useState("");
  const [debouncedOrderType] = useDebouncedValue(orderType, 600);
  const [business, setBusiness] = React.useState("");
  const [debouncedBusiness] = useDebouncedValue(business, 600);
  const [startDate, setStartDate] = React.useState<any>("");
  const [debouncedStartDate] = useDebouncedValue(startDate, 600);
  const [endDate, setEndDate] = React.useState<any>("");
  const [debouncedEndDate] = useDebouncedValue(endDate, 600);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { orderStatus, orderTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [queryBusiness] = React.useState<string>("");

  const [tableData, setTableData] = React.useState<{ count: number; rows: any[] }>({
    count: 0,
    rows: [],
  });

  const onClick = (type: string, row: ISplit) => {
    switch (type) {
      case "detail": {
        navigate(`/supplier-sales/detail/${row?.id}`);
        break;
      }
      default:
    }
  };

  const { data: businessData } = useSWR(`/ord/order/business_select/data/${queryBusiness}`, async () => {
    if ((user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0) {
      try {
        let res = await OrderApi.business({ query: queryBusiness, limit: "" });
        return res;
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
      }
    }
  });

  const onCLickCheck = (key: string, row: any) => {
    if (key === "select_all") {
      setSplitChecked(row);
    }
    if (key === "unselect_all") {
      setSplitChecked([]);
    }
    if (key === "select") {
      if (splitChecked.some((item: any) => item.id === row.id)) {
        setSplitChecked([...splitChecked.filter((item: any) => item.id !== row.id)]);
      } else setSplitChecked([...splitChecked, row]);
    }
  };

  const onClear = () => {
    setOrderType("");
    setBusiness("");
    setStartDate("");
    setEndDate("");
  };

  const reload = async () => {
    ref.current?.reload();
  };

  const onCancel = async () => {
    setAction([]);
  };

  let checkedData: any[];
  let recordData: any[];
  checkedData = (splitChecked || []).filter((item: any) => item?.orderStatus === "AUTHORIZED" && item?.deliveryNote === null);
  recordData = (tableData?.rows || []).filter((item: any) => item?.orderStatus === "AUTHORIZED" && item?.deliveryNote === null);

  return (
    <>
      <Flex direction="column" gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
        <Grid>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Select
              data={[{ code: "", sentName: "Бүгд" }, ...(orderStatus || [])]?.map((item: any) => {
                return {
                  label: item.sentName,
                  value: item.code,
                };
              })}
              placeholder="сонгох"
              leftSectionWidth={200}
              onChange={(e: string | null) => {
                if (e) setOrderType(e);
              }}
              value={orderType}
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              rightSection={<IconChevronDown size="1rem" stroke={1} />}
              clearable
              leftSection={
                <Group w={"calc(100% - 20px)"} align="center">
                  <Text size="sm" fw={450} c="#44566C">
                    {"Захиалгын төлөв"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
            />
          </Grid.Col>

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0 && (
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <Select
                data={(businessData || [])?.map((item: any) => {
                  return {
                    label: item.profileName,
                    value: item.id,
                  };
                })}
                placeholder="сонгох"
                leftSectionWidth={200}
                onChange={(e: string | null) => {
                  if (e) setBusiness(e);
                }}
                value={business}
                style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                rightSection={<IconChevronDown size="1rem" stroke={1} />}
                clearable
                leftSection={
                  <Group w={"calc(100% - 20px)"} align="center">
                    <Text size="sm" fw={450} c="#44566C">
                      {"Харилцагч бизнес"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
              />
            </Grid.Col>
          )}
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Flex gap="xs" align="center">
              <DatePickerInput
                type="range"
                value={[startDate, endDate]}
                placeholder="сонгох"
                leftSectionWidth={100}
                onChange={(e) => {
                  if (e[0] && e[1]) {
                    setStartDate(e[0] && e[0]);
                    setEndDate(e[1] && e[1]);
                  }
                }}
                style={{ borderRight: "none", borderRadius: "4px 0 0 4px", flex: 1 }}
                rightSection={<IconCalendarDue size="1rem" stroke={1} />}
                leftSection={
                  <Group w={"calc(100% - 20px)"} align="center">
                    <Text size="sm" fw={450} c="#44566C">
                      {"Огноо"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
                valueFormat="YYYY-MM-DD"
              />
              <ActionIcon
                onClick={() => {
                  onClear();
                }}
                color=""
                variant="light"
                size={36}>
                <IconClearAll />
              </ActionIcon>
            </Flex>
          </Grid.Col>
        </Grid>

        <Divider color="gray.5" my={20} />

        <CustomTable
          onResult={(thisTableData: { count: number; rows: any[] }) => {
            setTableData(thisTableData);
          }}
          ref={ref}
          colCount={18}
          thead={
            <MantineTable.Thead>
              <MantineTable.Tr>
                <MantineTable.Th rowSpan={2}>#</MantineTable.Th>
                <MantineTable.Th rowSpan={2}>
                  <Checkbox
                    onChange={() => {}}
                    onClick={() => {
                      if (
                        Array.isArray(recordData) &&
                        Array.isArray(checkedData) &&
                        recordData.length === checkedData.length &&
                        recordData.every((val: any, index: number) => val === checkedData[index])
                      ) {
                        onCLickCheck("unselect_all", recordData);
                      } else onCLickCheck("select_all", recordData);
                    }}
                    checked={checkedData?.length === recordData?.length}
                  />
                </MantineTable.Th>
                <MantineTable.Th rowSpan={2}>Үйлдэл</MantineTable.Th>
                <MantineTable.Th
                  colSpan={6}
                  className={`${classes.control}`}
                  style={{
                    textAlign: "center",
                  }}>
                  Борлуулалтын захиалга
                </MantineTable.Th>
                <MantineTable.Th
                  colSpan={7}
                  className={`${classes.control}`}
                  style={{
                    textAlign: "center",
                  }}>
                  Харилцагчийн мэдээлэл
                </MantineTable.Th>
              </MantineTable.Tr>
              <MantineTable.Tr>
                <MantineTable.Th className={classes.control}>№</MantineTable.Th>
                <MantineTable.Th className={classes.control}>Огноо, цаг</MantineTable.Th>
                <MantineTable.Th className={classes.control}>Төрөл</MantineTable.Th>
                <MantineTable.Th className={classes.control}>Төлөв</MantineTable.Th>
                <MantineTable.Th className={classes.control}>бүртгэсэн</MantineTable.Th>
                <MantineTable.Th className={classes.control}>Хянасан</MantineTable.Th>
                <MantineTable.Th className={classes.control}>Партнер нэр</MantineTable.Th>
                <MantineTable.Th className={classes.control}>ТТД</MantineTable.Th>
                <MantineTable.Th className={classes.control}>Бизнес нэр</MantineTable.Th>
                <MantineTable.Th className={classes.control}>Хүлээн авах салбар</MantineTable.Th>
                <MantineTable.Th className={classes.control}>Хүлээн авах</MantineTable.Th>
                <MantineTable.Th className={classes.control}>Бүсчлэл</MantineTable.Th>
                <MantineTable.Th className={classes.control}>Чиглэл</MantineTable.Th>
              </MantineTable.Tr>
            </MantineTable.Thead>
          }
          tbody={(rows: any[]) => {
            return (
              <MantineTable.Tbody>
                {(rows ?? []).map((record, index) => {
                  return (
                    <MantineTable.Tr key={record.id ?? index}>
                      <MantineTable.Td>
                        <Text size="sm" fw={500} c="#44566C">
                          {index + 1}
                        </Text>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <Checkbox
                          onChange={() => {}}
                          onClick={() => {
                            onCLickCheck("select", record);
                          }}
                          checked={splitChecked.some((item: any) => item.id === record.id)}
                          disabled={record?.orderStatus === "AUTHORIZED" && record?.deliveryNote === null ? false : true}
                        />
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <Tooltip label="Дэлгэрэнгүй" position="bottom">
                          <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                            <IconInfoSquare />
                          </Button>
                        </Tooltip>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.salesCode || "-"}
                        </Text>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {dateTimeSecFormat(record?.createdAt)}
                        </Text>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {orderTypes?.find((c: any) => c?.code === record?.type)?.name || "-"}
                        </Text>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          style={{
                            border: `${(orderStatus || []).find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
                            color: `${(orderStatus || []).find((c: any) => c.code === record.orderStatus)?.color}`,
                          }}
                          styles={{
                            label: {
                              overflow: "visible",
                            },
                          }}>
                          {(orderStatus || []).find((c: any) => c.code === record.orderStatus)?.sentName || "-"}
                        </Badge>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.type === "SALES" ? `${record?.orderedUser?.lastName[0] || "-"}.${record?.orderedUser?.firstName || "-"}` : "-"}
                        </Text>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {(record?.type === "PURCHASE" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
                            ? `${record?.respondedUser?.lastName ? record?.respondedUser?.lastName[0] : "-"}.${record?.respondedUser?.firstName || "-"}`
                            : `${record?.reviewedUser?.lastName[0] || "-"}.${record?.reviewedUser?.firstName || "-"}`}
                        </Text>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <div>
                          <Text size="sm" fw={500} c="#44566C" w="max-content">
                            {record?.receiverBusiness?.partner?.refCode || "-"}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-content">
                            {record?.receiverBusiness?.partner?.businessName || "-"}
                          </Text>
                        </div>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.receiverBusiness?.regNumber || "-"}
                        </Text>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <div>
                          <Text size="sm" fw={500} c="#44566C" w="max-content">
                            {record?.receiverBusiness?.refCode || "-"}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-content">
                            {record?.receiverBusiness?.profileName || "-"}
                          </Text>
                        </div>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.receiverBranch?.name || "-"}
                        </Text>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <div>
                          <Text size="sm" fw={500} c="#44566C" w="max-content">
                            {record?.receiverStaff?.lastName[0] || "-"}.{record?.receiverStaff?.firstName || "-"}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-content">
                            {record?.receiverStaff?.phone || "-"}
                          </Text>
                        </div>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.region?.name || "-"}
                        </Text>
                      </MantineTable.Td>
                      <MantineTable.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.direction?.name || "-"}
                        </Text>
                      </MantineTable.Td>
                    </MantineTable.Tr>
                  );
                })}
              </MantineTable.Tbody>
            );
          }}
          name="supplier-split.list"
          filters={{
            orderStatus: debouncedOrderType,
            businessId: debouncedBusiness,
            startDate: debouncedStartDate,
            endDate: debouncedEndDate,
            excel: `${false}`,
          }}
          loadData={(data) => SplitApi.list(data!)}
        />
      </Flex>
      <Modal
        opened={action[0] === "split"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="md"
        padding={0}
        centered>
        <SupplierSplitForm action={action} onCancel={onCancel} reload={reload} setChecked={setSplitChecked} success={success} />
      </Modal>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: "relative",
  },
  style: {
    width: "auto",
    textAlign: "center",
  },
  control: {
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  child: {
    display: "flex",
  },
  icon: {
    display: "flex",
  },
}));
