import { LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router";
import useSWR from "swr";
import { PullSheetApi } from "../../apis";
import { SupplierShippingCustomerDetailForm } from "./form";

export function SupplierShippingCustomerDetail() {
  const params = useParams();

  const { data, isLoading } = useSWR(`/delivery_note/detail/${params.id}`, async () => await PullSheetApi.get(params.id as string), {
    fallback: { data: null },
  });

  return (
    <>
      {data && (
        <>
          <LoadingOverlay visible={isLoading} />
          <SupplierShippingCustomerDetailForm action={["detail", data]} />
        </>
      )}
    </>
  );
}
