import { Badge, Button, Flex, Group, Table, Text, Tooltip } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconInfoSquare } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DropShippingApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IOrder } from "../../interfaces/IOrder";
import { IPermissions } from "../../interfaces/IPermissions";
import { tugrug } from "../../utils";
import { dateTimeSecFormat } from "../../utils/date";
import { ITableRef } from "../table";
import { CustomTable } from "../table/customTable";

export function SupplierDropShippingDelivery() {
  const { classes } = useStyles();
  const ref = React.useRef<ITableRef>(null);
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { orderStatus, paymentStatus, deliveryNoteStatus, orderTypes, invoiceStatus, orderPurchaseTypes } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );

  const onClick = (key: any, record: any) => {
    switch (key) {
      case "detail": {
        navigate(`/supplier-drop-shipping/delivery/detail/${record.id}`);
        break;
      }
      default:
    }
  };

  return (
    <Flex direction={"column"} gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
      <CustomTable
        ref={ref}
        colCount={36}
        thead={
          <Table.Thead>
            <Table.Tr>
              <Table.Th rowSpan={2}>#</Table.Th>
              {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DRSHP").length > 0 && (
                <Table.Th rowSpan={2}>Үйлдэл</Table.Th>
              )}
              <Table.Th
                className={`${classes.control}`}
                style={{
                  textAlign: "center",
                }}
                colSpan={6}>
                Борлуулалтын захиалга
              </Table.Th>
              <Table.Th
                className={`${classes.control}`}
                style={{
                  textAlign: "center",
                }}
                colSpan={7}>
                Харилцагчийн мэдээлэл
              </Table.Th>
              <Table.Th
                className={`${classes.control}`}
                style={{
                  textAlign: "center",
                }}
                colSpan={4}>
                Хүргэлтийн мэдээлэл
              </Table.Th>
              <Table.Th
                className={`${classes.control}`}
                style={{
                  textAlign: "center",
                }}
                colSpan={7}>
                Төлбөрийн мэдээлэл
              </Table.Th>
              <Table.Th
                className={`${classes.control}`}
                style={{
                  textAlign: "center",
                }}
                colSpan={6}>
                PO
              </Table.Th>
            </Table.Tr>
            <Table.Tr>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                SO дугаар
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 200 }}>
                Огноо, цаг
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 200 }}>
                Төрөл
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 100 }}>
                Статус
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Бүртгэсэн
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Хянасан
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Партнер нэр
              </Table.Th>
              <Table.Th className={`${classes.control}`}>ТТД</Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Бизнес нэр
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Хүлээн авах салбар
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 200 }}>
                Хүлээн авах ажилтан
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Бүсчлэл
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Чиглэл
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 200 }}>
                Хүргэх огноо
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Статус
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 200 }}>
                Хүргэлт хариуцсан
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Хүргэсэн огноо
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Нийт дүн
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Төлсөн дүн
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Төлөх дүн
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 200 }}>
                Төлбөрийн статус
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 200 }}>
                Нөхцөл
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Нэхэмжлэх №
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Нэхэмжлэх статус
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                PO дугаар
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 200 }}>
                Огноо, цаг
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Төрөл
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 150 }}>
                Статус
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 200 }}>
                Захиалсан
              </Table.Th>
              <Table.Th className={`${classes.control}`} style={{ minWidth: 200 }}>
                Хянасан
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
        }
        tbody={(rows: IOrder[]) => {
          return (
            <Table.Tbody>
              {rows.map((record, index) => {
                return (
                  <Table.Tr key={record.id ?? index}>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {index + 1}
                      </Text>
                    </Table.Td>

                    {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DRSHP").length > 0 && (
                      <Table.Td>
                        <Flex gap="sm">
                          <Tooltip label="Дэлгэрэнгүй" position="bottom">
                            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                              <IconInfoSquare />
                            </Button>
                          </Tooltip>
                        </Flex>
                      </Table.Td>
                    )}
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {record?.salesCode || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {dateTimeSecFormat(record?.createdAt)}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {orderTypes?.find((c: any) => c?.code === record?.type)?.name || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        style={{
                          border: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
                          color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                        }}>
                        {orderStatus.find((c: any) => c.code === record.orderStatus)?.sentName || "-"}
                      </Badge>
                    </Table.Td>
                    <Table.Td>
                      <Group>
                        <div>
                          <Text size="sm" fw={500} c="#44566C">
                            {record?.type === "SALES" ? (record?.orderedUser?.lastName, record?.orderedUser?.firstName || "-") || "-" : "-"}
                          </Text>
                        </div>
                      </Group>
                    </Table.Td>
                    <Table.Td>
                      <Group>
                        <div>
                          <Text size="sm" fw={500} c="#44566C">
                            {(record?.type === "PURCHASE" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
                              ? (record.respondedUser?.lastName, record.respondedUser?.firstName) || "-"
                              : (record?.reviewedUser?.lastName, record?.reviewedUser?.firstName)}
                          </Text>
                        </div>
                      </Group>
                    </Table.Td>
                    <Table.Td>
                      <Group>
                        <div>
                          <Text size="sm" fw={500} c="#44566C">
                            {record?.receiverBusiness?.partner?.refCode || "-"}
                          </Text>
                          <Flex gap={5} wrap="nowrap">
                            <Text size="sm" fw={500} c="cyan">
                              {record?.receiverBusiness?.partner?.businessName || "-"}
                            </Text>
                          </Flex>
                        </div>
                      </Group>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {record?.receiverBusiness?.regNumber || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Group>
                        <div>
                          <Text size="sm" fw={500} c="#44566C">
                            {record?.receiverBusiness?.refCode || "-"}
                          </Text>
                          <Flex gap={5} wrap="nowrap">
                            <Text size="sm" fw={500} c="cyan">
                              {record?.receiverBusiness?.profileName || "-"}
                            </Text>
                          </Flex>
                        </div>
                      </Group>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="cyan">
                        {record?.receiverBranch?.name || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Group>
                        <div>
                          <Text size="sm" fw={500} c="#44566C">
                            {record?.receiverStaff?.lastName || "-"}, {record?.receiverStaff?.firstName || "-"}
                          </Text>
                          <Flex gap={5} wrap="nowrap">
                            <Text size="sm" fw={500} c="cyan">
                              {record?.receiverStaff?.phone || "-"}
                            </Text>
                          </Flex>
                        </div>
                      </Group>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {record?.region?.name || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {record?.direction?.name || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="cyan">
                        {dateTimeSecFormat(record?.deliveryDate || "-")}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        style={{
                          border: `${deliveryNoteStatus.find((c: any) => c.code === record.deliveryNote?.deliveryNoteStatus)?.color} 1px solid`,
                          color: `${deliveryNoteStatus.find((c: any) => c.code === record.deliveryNote?.deliveryNoteStatus)?.color}`,
                        }}>
                        {deliveryNoteStatus.find((c: any) => c?.code === record?.deliveryNote?.deliveryNoteStatus)?.name || "-"}
                      </Badge>
                    </Table.Td>
                    <Table.Td>
                      <Group>
                        <div>
                          <Text size="sm" fw={500} c="#44566C">
                            {record?.deliveryNote?.staff?.lastName || "-"}, {record?.deliveryNote?.staff?.firstName || "-"}
                          </Text>
                          <Flex gap={5} wrap="nowrap">
                            <Text size="sm" fw={500} c="cyan">
                              {record?.deliveryNote?.staff?.email || "-"}
                            </Text>
                          </Flex>
                        </div>
                      </Group>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {dateTimeSecFormat(record?.deliveryNote?.deliveredDate)}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C" ta="end">
                        {tugrug(record?.totalAmount || 0)}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C" ta="end">
                        {tugrug(record?.paidAmount || 0)}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C" ta="end">
                        {tugrug(record?.amountToPay || 0)}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        style={{
                          border: `${paymentStatus.find((c: any) => c?.code === record?.invoice?.paymentStatus)?.color} 1px solid`,
                          color: `${paymentStatus.find((c: any) => c?.code === record?.invoice?.paymentStatus)?.color}`,
                        }}>
                        {paymentStatus.find((c: any) => c.code === record?.invoice?.paymentStatus)?.name || "-"}
                      </Badge>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="cyan">
                        {record?.paymentTerm?.description || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {record?.invoice?.refCode || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        style={{
                          border: `${invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.color} 1px solid`,
                          color: `${invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.color}`,
                        }}>
                        {invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.name || "-"}
                      </Badge>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {record?.purchaseCode || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="cyan">
                        {dateTimeSecFormat(record?.respondedDate)}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        color="gray"
                        style={{
                          border: `${orderPurchaseTypes.find((c: any) => c.code === record.purchaseType)?.color} 1px solid`,
                          color: `${orderPurchaseTypes.find((c: any) => c.code === record.purchaseType)?.color}`,
                        }}>
                        {orderPurchaseTypes?.find((c: any) => c?.code === record?.purchaseType)?.name || "-"}
                      </Badge>
                    </Table.Td>
                    <Table.Td>
                      {record?.type === "SALES" ? (
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          style={{
                            border: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
                            color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                          }}>
                          {orderStatus.find((c: any) => c.code === record.orderStatus)?.sentName || "-"}
                        </Badge>
                      ) : (
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          style={{
                            border: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
                            color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                          }}>
                          {orderStatus.find((c: any) => c.code === record.orderStatus)?.receivedName || "-"}
                        </Badge>
                      )}
                    </Table.Td>
                    <Table.Td>
                      <div>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.type === "PURCHASE" ? (record?.orderedUser?.firstName || "-", record?.orderedUser?.firstName || "-") : "-"}
                        </Text>
                        <Text size="sm" fw={500} c="cyan" w="max-content">
                          {record?.type === "PURCHASE" ? (record?.orderedUser?.phone || "-", record?.orderedUser?.phone || "-") : "-"}
                        </Text>
                      </div>
                    </Table.Td>
                    <Table.Td>
                      <div>
                        <Text size="sm" fw={500} c="#243f60" w="max-content">
                          {(record?.type === "SALES" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
                            ? (record.respondedUser?.lastName ? record.respondedUser?.lastName[0] : "-" + record.respondedUser?.firstName) || "-"
                            : (record?.reviewedUser?.lastName, record?.reviewedUser?.firstName)}
                        </Text>
                        <Text size="sm" fw={500} c="cyan" w="max-content">
                          {(record?.type === "SALES" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
                            ? record.respondedUser?.phone || "-"
                            : record?.reviewedUser?.phone || "-"}
                        </Text>
                      </div>
                    </Table.Td>
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          );
        }}
        name="supplier-drop-shipping-delivery.list"
        filters={{
          isDropshipping: `${true}`,
          orderStatus: "",
          excel: `${false}`,
        }}
        loadData={(data: any) => DropShippingApi.list(data!)}
      />
    </Flex>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: "relative",
  },
  style: {
    width: "auto",
    textAlign: "center",
  },
  control: {
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  child: {
    display: "flex",
  },
  icon: {
    display: "flex",
  },
}));
