import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async (data: any) => {
  return httpRequest.get("/ord/network", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/ord/network/${id}`);
};

export const select = async (data: any) => {
  return httpRequest.get(`/ord/network/select`, data);
};
