import { Paper, Tabs, Text } from "@mantine/core";
import { IconTruckDelivery } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { PageLayout } from "../../components/layout";
import { SupplierShippingCustomerList } from "../../components/supplier-shipping-customer/list";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";

export function SupplierShippingTab() {
  const breadcrumbs = useBreadcrumb();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [activeTab, setActiveTab] = React.useState<string>("CUSTOMER");

  const onTabChange = (_index: any) => {
    setActiveTab(_index);
  };

  return (
    <>
      <PageLayout title="Ачилт удирдлага жагсаалт" subTitle="Гэрээт харилцагчид хандаж борлуулалтын захиалга бүртгэх" breadcrumb={breadcrumbs}>
        <Paper radius="sm" p="md" withBorder>
          <Tabs defaultValue="1" value={activeTab} onChange={onTabChange}>
            <Tabs.List>
              {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_PS_LIST").length > 0 && (
                <Tabs.Tab leftSection={<IconTruckDelivery color="#44566C" />} value="CUSTOMER">
                  <Text size="sm" fw={500} c="#44566C">
                    Ачилт удирдлага
                  </Text>
                </Tabs.Tab>
              )}
            </Tabs.List>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_PS_LIST").length > 0 && (
              <Tabs.Panel value="CUSTOMER" mt="md">
                {activeTab === "CUSTOMER" && <SupplierShippingCustomerList />}
              </Tabs.Panel>
            )}
            <Tabs.Panel value="DOMESTIC" mt="md">
              {activeTab === "DISTRIBUTING" && <></>}
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </PageLayout>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Ачилт удирдлага жагсаалт",
  },
];
