import { ActionIcon, Badge, Box, Button, Collapse, Flex, Grid, Group, Input, Modal, Text } from "@mantine/core";
import { IconMapPin, IconSquareArrowDown, IconSquareArrowUp } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { dateTimeSecFormat } from "../../utils/date";
import { Form } from "../form";
import { PageLayout } from "../layout";
import { SupplierSalesDynamicReviewForm } from "../supplier-sales/review-form";
import { PurchaseSalesConfirmForm } from "./confirm-form";
import { PurchaseSalesDynamicDetailForm } from "./dynamic-detail-form";

const schema = yup.object({});

export type IFormData = {
  regNumber: string;
};

type Props = {
  action?: any;
};

export function PurchaseSalesDetailForm({ action }: Props) {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [collapseOpen, setCollapseOpen] = React.useState(true);
  const [collapsedOpen, setCollapsedOpen] = React.useState(true);
  const [actions, setActions] = React.useState<string[]>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { orderStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IFormData>({
    regNumber: "",
  });

  return (
    <>
      <Form validationSchema={schema} onSubmit={() => {}} initialValues={data}>
        {({ values }) => {
          return (
            <PageLayout
              title="Захиалгын мэдээлэл"
              subTitle="Гэрээт харилцагчийн захиалгын дэлгэрэнгүй мэдээлэл"
              breadcrumb={breadcrumbs}
              extra={[
                <Button onClick={() => navigate("/supplier-purchase")} key={1} variant="outline">
                  Буцах
                </Button>,
                <>
                  {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_RES").length > 0 && (
                    <Button
                      onClick={() => setActions(["detail-confirm"])}
                      disabled={action[1]?.orderStatus === "REVIEWED" && user?.currentBusiness?.id === action[1]?.receiverBusiness?.id ? false : true}
                      key={2}
                      variant="outline"
                      color="teal">
                      Зөвшөөрөх
                    </Button>
                  )}
                </>,
                <>
                  {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_RES").length > 0 && (
                    <Button
                      onClick={() => setActions(["detail-reject"])}
                      disabled={action[1]?.orderStatus === "REVIEWED" && user?.currentBusiness?.id === action[1]?.receiverBusiness?.id ? false : true}
                      key={3}
                      variant="outline"
                      color="red">
                      Татгалзах
                    </Button>
                  )}
                </>,
                <>
                  {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST").length > 0 && (
                    <Button
                      onClick={() => setActions(["review"])}
                      key={4}
                      variant="outline"
                      disabled={action[1]?.orderStatus === "REGISTERED" && user?.currentBusiness?.id === action[1]?.senderBusiness?.id ? false : true}>
                      Хянах
                    </Button>
                  )}
                </>,
              ]}>
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex mih={10} gap="md" justify="space-between" align="center" direction="row" wrap="wrap">
                  <Group>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      style={{
                        border: `${orderStatus.find((c: any) => c.code === action[1].orderStatus)?.color} 1px solid`,
                        color: `${orderStatus.find((c: any) => c.code === action[1].orderStatus)?.color}`,
                      }}>
                      {orderStatus.find((c: any) => c.code === action[1].orderStatus)?.sentName || "-"}
                    </Badge>
                    <Text size="sm" fw={700} c="cyan">
                      {(action && action[1]?.salesCode) || "-"}
                    </Text>
                  </Group>
                  <Group>
                    <div>
                      <Group>
                        <Text size="sm" fw={500} c="#44566C">
                          {"бүртгэсэн"}:
                        </Text>
                        <Text size="sm" fw={600} c="#44566C">
                          {action && action[1]?.orderedUser?.lastName !== null ? action[1]?.orderedUser.lastName[0] : "-"}.{" "}
                          {action[1]?.orderedUser.firstName !== null ? action[1]?.orderedUser.firstName : "-"}
                        </Text>
                      </Group>
                      <Flex gap={5} wrap="nowrap" justify="flex-end">
                        <Text size="sm" fw={500} c="cyan">
                          {dateTimeSecFormat(action[1]?.createdAt)}
                        </Text>
                      </Flex>
                    </div>
                  </Group>
                </Flex>
              </Box>
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                  <Text fw={500}>{"ХАРИЛЦАГЧИЙН МЭДЭЭЛЭЛ"}</Text>
                  <ActionIcon variant="transparent">
                    {!collapseOpen ? (
                      <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapseOpen(true)} />
                    ) : (
                      <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapseOpen(false)} />
                    )}
                  </ActionIcon>
                </Flex>
              </Box>

              <Collapse in={collapseOpen}>
                <Box
                  sx={(theme) => ({
                    background: theme.white,
                    borderRadius: 5,
                    border: `1px solid ${theme.colors.gray[2]}`,
                    padding: "20px 20px",
                  })}
                  mb={5}>
                  <Grid>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Партнерийн нэр:">
                        <Text fw={500} c="cyan" mt={5}>
                          {action[1]?.receiverBusiness?.partner?.refCode || "-"}, {action[1]?.receiverBusiness?.partner?.businessName || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Нийлүүлэгчийн нэр:">
                        <Text fw={500} c="cyan" mt={5}>
                          {action[1]?.receiverBusiness?.refCode || "-"}, {action[1]?.receiverBusiness?.profileName || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                        <Text fw={500} c="cyan" mt={5}>
                          {action[1]?.receiverBusiness?.regNumber || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Төлбөрийн нөхцөл:">
                        <Text fw={500} c="cyan">
                          {action[1]?.paymentTerm?.description || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Нийлүүлэгчийн менежер:">
                        <Text fw={500} c="cyan">
                          {action[1]?.receiverStaff?.lastName || "-"}, {action[1]?.receiverStaff?.firstName || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Худалдан авагчийн менежер:">
                        <Text fw={500} c="cyan">
                          {action[1]?.staff?.lastName || "-"}, {action[1]?.staff?.firstName || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах хаяг:">
                        <Group>
                          <ActionIcon variant="transparent">
                            <IconMapPin color="#3BC9DB" />
                          </ActionIcon>
                          <Text fw={500} c="cyan">
                            {action[1]?.receiverBranch?.province || "-"}, {action[1]?.receiverBranch?.khoroo || "-"},{" "}
                            {action[1]?.receiverBranch?.khoroolol || "-"}, {action[1]?.receiverBranch?.khotkhonBair || "-"},{" "}
                            {action[1]?.receiverBranch?.khashaaDavkhar || "-"}, {action[1]?.receiverBranch?.khaalgaDugaar || "-"}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах салбар:">
                        <Text fw={500} c="cyan">
                          {action[1]?.receiverBranch?.name || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Захиалгыг хүлээн авах:">
                        <Text fw={500} c="cyan">
                          {action && action[1]?.deliveryType === "CUSTOM_DATE" ? "Өөр өдөр сонгох" : "Хуваарь өдөр"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах ажилтан:">
                        <Text fw={500} c="cyan">
                          {"-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хамгийн ойрхон хүргэх боломжтой хуваарьт өдөр:">
                        <Text fw={500} c="cyan">
                          {action && action[1]?.deliveryType === "CUSTOM_DATE" ? dateTimeSecFormat(action && action[1]?.deliveryDate) : "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                  </Grid>
                </Box>
              </Collapse>

              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                  <Text fw={500}>{"ЗАХИАЛГЫН ЗҮЙЛС"}</Text>
                  <ActionIcon variant="transparent">
                    {!collapsedOpen ? (
                      <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapsedOpen(true)} />
                    ) : (
                      <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapsedOpen(false)} />
                    )}
                  </ActionIcon>
                </Flex>
              </Box>

              <Collapse in={collapsedOpen}>
                <PurchaseSalesDynamicDetailForm action={action} />
              </Collapse>
            </PageLayout>
          );
        }}
      </Form>
      <Modal
        opened={actions[0] === "review"}
        onClose={() => {
          setActions([]);
        }}
        withCloseButton={false}
        size="600px"
        padding={0}
        centered>
        <SupplierSalesDynamicReviewForm action={action && action[1]} setActions={setActions} />
      </Modal>
      <Modal
        opened={actions[0] === "detail-confirm" || actions[0] === "detail-reject"}
        onClose={() => {
          setActions([]);
        }}
        withCloseButton={false}
        size="600px"
        padding={0}
        centered>
        <PurchaseSalesConfirmForm action={action && action[1]} setActions={setActions} constant={actions && actions[0]} />
      </Modal>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/supplier-purchase",
    label: "Захилцагчийн захиалгын жагсаалт",
  },
  {
    label: "Захиалгын дэлгэрэнгүй",
  },
];
