import { ActionIcon, Badge, Box, Button, Collapse, Flex, Grid, Group, Input, LoadingOverlay, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconMap2, IconSquareArrowDown, IconSquareArrowUp } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { DeliveryApi, DropShippingApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { dateTimeSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { PageLayout } from "../layout";
import { SupplierDropShippingProductForm } from "./product-form";

const schema = yup.object({});

export type IFormData = {
  senderNote: string;
  senderAdditionalNote: string;
  attachments: [];
};

export function SupplierDropShippingForm({ action }: { action: any }) {
  const params = useParams();
  const navigate = useNavigate();

  const breadcrumbs = useBreadcrumb();
  const { classes } = useStyles();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { orderStatus, deliveryDateTypes, deliveryNoteStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [collapse, setCollapse] = React.useState(true);
  const [collapseOpen, setCollapseOpen] = React.useState(true);
  const [collapsedOpen, setCollapsedOpen] = React.useState(true);
  const [collapsedOpenDelivery, setCollapsedOpenDelivery] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IFormData>({
    senderNote: "",
    senderAdditionalNote: "",
    attachments: [],
    ...(action && action[0] === "detail" ? { ...action[1] } : {}),
  });

  const onSubmit = async (value: any) => {
    setLoading(true);
    try {
      await DropShippingApi.update(params.id as string, {
        senderNote: value?.senderNote,
        senderAdditionalNote: value?.senderAdditionalNote,
        attachments: (value?.attachments || [])?.map((item: any) => {
          return {
            description: item?.description,
            name: item?.name,
            url: item?.url,
          };
        }),
      });
      message.success("Таны хүсэлт амжилттай.");
      navigate("/supplier-drop-shipping");
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const staticDay = [
    {
      id: "1",
      date: "2021-12-28",
      day: "Мягмар",
    },
    {
      id: "2",
      date: "2021-12-29",
      day: "Лхагва",
    },
    {
      id: "3",
      date: "2021-12-30",
      day: "Пүрэв",
    },
    {
      id: "4",
      date: "2021-12-31",
      day: "Баасан",
    },
    {
      id: "5",
      date: "2022-01-01",
      day: "Бямба",
    },
    {
      id: "6",
      date: "2022-01-02",
      day: "Ням",
    },
  ];

  const onDelivery = async () => {
    setLoading(true);
    try {
      await DeliveryApi.create([params.id]);
      message.success("Таны хүсэлт амжилттай.");
      navigate("/supplier-drop-shipping");
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <>
      <Form validationSchema={schema} initialValues={data} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => {
          return (
            <PageLayout
              key={1}
              title="Захиалгын мэдээлэл "
              subTitle="Харилцагчид хүргэхээр бүртгэсэн захиалгын мэдээлэл"
              breadcrumb={breadcrumbs}
              extra={[
                <Button
                  onClick={() => {
                    navigate("/supplier-drop-shipping");
                  }}
                  key="cancel"
                  variant="outline">
                  Буцах
                </Button>,
                <>
                  {params?.type === "delivery" && (
                    <Button
                      onClick={() => onDelivery()}
                      disabled={action && action[1]?.deliveryNote !== null && user?.currentBusinessId !== action[1]?.cdropshipperBusinessId}
                      key="delivery">
                      Хүргэлт хуваарилах
                    </Button>
                  )}
                </>,
              ]}>
              <LoadingOverlay visible={loading} />
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex mih={10} gap="md" justify="space-between" align="center" direction="row" wrap="wrap">
                  <Group>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      style={{
                        border: `${(orderStatus || []).find((c: any) => c.code === action[1].orderStatus)?.color} 1px solid`,
                        color: `${(orderStatus || []).find((c: any) => c.code === action[1].orderStatus)?.color}`,
                      }}>
                      {(orderStatus || []).find((c: any) => c.code === action[1].orderStatus)?.sentName || "-"}
                    </Badge>
                    <Text size="sm" fw={700} c="cyan">
                      {action[1]?.salesCode}
                    </Text>
                  </Group>
                  <Group>
                    <div>
                      <Group>
                        <Text size="sm" fw={500} c="#44566C">
                          {"бүртгэсэн"}:
                        </Text>
                        <Text size="sm" fw={600} c="#44566C">
                          {action[1]?.orderedUser?.firstName}
                        </Text>
                      </Group>
                      <Flex gap={5} wrap="nowrap" justify="flex-end">
                        <Text size="sm" fw={500} c="cyan">
                          {dateTimeSecFormat(action[1]?.createdAt)}
                        </Text>
                      </Flex>
                    </div>
                  </Group>
                </Flex>
              </Box>
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                  <Text fw={500}>{"НИЙЛҮҮЛЭГЧ ТАЛ"}</Text>
                  <ActionIcon variant="transparent">
                    {!collapse ? (
                      <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapse(true)} />
                    ) : (
                      <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapse(false)} />
                    )}
                  </ActionIcon>
                </Flex>
              </Box>
              <Collapse in={collapse}>
                <Box
                  sx={(theme) => ({
                    background: theme.white,
                    borderRadius: 5,
                    border: `1px solid ${theme.colors.gray[2]}`,
                    padding: "20px 20px",
                  })}
                  mb={5}>
                  <Grid>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Партнерийн нэр:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.dropperBusiness?.partner?.refCode || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {action[1]?.dropperBusiness?.partner?.businessName || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {"РД:"} {action[1]?.dropperBusiness?.regNumber || "-"}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Борлуулалтын захиалга:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.parent?.salesCode || "-"}
                          </Text>
                          <Badge
                            size="md"
                            radius="sm"
                            variant="outline"
                            style={{
                              border: `${orderStatus.find((c: any) => c.code === action[1]?.parent?.orderStatus)?.color} 1px solid`,
                              color: `${orderStatus.find((c: any) => c.code === action[1]?.parent?.orderStatus)?.color}`,
                            }}>
                            {orderStatus.find((c: any) => c.code === action[1]?.parent?.orderStatus)?.sentName || "-"}
                          </Badge>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Нийлүүлэгчийн менежер:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.orderedUser?.lastName === null ? "" : action[1]?.orderedUser?.lastName[0]}
                            {action[1]?.orderedUser?.lastName ? "." : ""} {action[1]?.orderedUser?.firstName || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {action[1]?.orderedUser?.phone || "-"}, {action[1]?.orderedUser?.email || "-"}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Бизнесийн нэр:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.dropperBusiness?.refCode || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {action[1]?.dropperBusiness?.profileName || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {"РД:"} {action[1]?.dropperBusiness?.regNumber || "-"}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Худалдан авалтын захиалга:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.parent?.purchaseCode || "-"}
                          </Text>
                          <Badge
                            size="md"
                            radius="sm"
                            variant="outline"
                            style={{
                              border: `${(orderStatus || []).find((c: any) => c.code === action[1]?.parent?.orderStatus)?.color} 1px solid`,
                              color: `${(orderStatus || []).find((c: any) => c.code === action[1]?.parent?.orderStatus)?.color}`,
                            }}>
                            {(orderStatus || []).find((c: any) => c.code === action[1]?.parent?.orderStatus)?.sentName || "-"}
                          </Badge>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}></Grid.Col>
                  </Grid>
                </Box>
              </Collapse>
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                  <Text fw={500}>{"ХУДАЛДАН АВАГЧ ТАЛ"}</Text>
                  <ActionIcon variant="transparent">
                    {!collapseOpen ? (
                      <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapseOpen(true)} />
                    ) : (
                      <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapseOpen(false)} />
                    )}
                  </ActionIcon>
                </Flex>
              </Box>
              <Collapse in={collapseOpen}>
                <Box
                  sx={(theme) => ({
                    background: theme.white,
                    borderRadius: 5,
                    border: `1px solid ${theme.colors.gray[2]}`,
                    padding: "20px 20px",
                  })}
                  mb={5}>
                  <Grid>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Партнерийн нэр:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.receiverBusiness?.partner?.refCode || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {action[1]?.receiverBusiness?.partner?.businessName || "-"}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Харилцагчийн нэр:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.receiverBusiness?.refCode || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {action[1]?.receiverBusiness?.profileName || "-"}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                        <Text fw={500} c="gray">
                          {action[1]?.receiverBusiness?.regNumber || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Төлбөрийн нөхцөл:">
                        <Text fw={500} c="gray">
                          {action[1]?.paymentTerm?.description || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="НӨАТ:">
                        <Text fw={500} c="gray">
                          {action[1]?.receiverBusiness?.isVatPayer || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хариуцах менежер:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.receiverStaff?.lastName === null ? "" : action[1]?.receiverStaff?.lastName[0]}
                            {action[1]?.receiverStaff?.lastName ? "." : ""} {action[1]?.receiverStaff?.firstName || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {action[1]?.receiverStaff?.phone || "-"}, {action[1]?.receiverStaff?.email || "-"}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах ажилтан:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.receiverStaff?.lastName === null ? "" : action[1]?.receiverStaff?.lastName[0]}
                            {action[1]?.receiverStaff?.lastName ? "." : ""} {action[1]?.receiverStaff?.firstName || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {action[1]?.receiverStaff?.phone || "-"}, {action[1]?.receiverStaff?.email || "-"}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах салбар:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.receiverBranch?.refCode}
                          </Text>
                          <Text fw={500} c="gray">
                            {action[1]?.receiverBranch?.name}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах хаяг:">
                        <Group>
                          <ActionIcon variant="transparent">
                            <IconMap2 color="#21bbdd" />
                          </ActionIcon>
                          <Text fw={500} c="gray">
                            {action[1]?.receiverBranch?.branchAddress || "-"}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Захиалгыг хүлээн авах:">
                        <Text fw={500} c="gray">
                          {deliveryDateTypes.find((c: any) => c?.code === action[1]?.deliveryType)?.name || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хамгийн ойрхон хүргэх боломжтой хуваарьт өдөр:">
                        <Text fw={500} c="cyan">
                          {values?.deliveryType === "DEFAULT_DATE" ? (
                            <Grid mt={5}>
                              {(staticDay || [])?.map((item: any, index: any) => {
                                return (
                                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} key={index}>
                                    <UnstyledButton className={classes.item}>
                                      <Flex justify="center" align="center" direction="column" wrap="nowrap">
                                        <Text size="sm" fw={500} c="#44566C">
                                          {item?.date}
                                        </Text>
                                        <Text size="sm" fw={500} c="#44566C">
                                          {item?.day}
                                        </Text>
                                      </Flex>
                                    </UnstyledButton>
                                  </Grid.Col>
                                );
                              })}
                            </Grid>
                          ) : (
                            <Text fw={500} c="cyan">
                              {dateTimeSecFormat(action[1]?.deliveryDate)}
                            </Text>
                          )}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}></Grid.Col>
                  </Grid>
                </Box>
              </Collapse>
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                  <Text fw={500}>{"ХҮРГЭЛТ ХИЙХ ТАЛ"}</Text>
                  <ActionIcon variant="transparent">
                    {!collapsedOpenDelivery ? (
                      <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapsedOpenDelivery(true)} />
                    ) : (
                      <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapsedOpenDelivery(false)} />
                    )}
                  </ActionIcon>
                </Flex>
              </Box>
              <Collapse in={collapsedOpenDelivery}>
                <Box
                  sx={(theme) => ({
                    background: theme.white,
                    borderRadius: 5,
                    border: `1px solid ${theme.colors.gray[2]}`,
                    padding: "20px 20px",
                  })}
                  mb={5}>
                  <Grid>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Партнер нэр:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.dropshipperBusiness?.partner?.refCode || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {action[1]?.dropshipperBusiness?.partner?.businessName || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {"РД:"} {action[1]?.dropshipperBusiness?.regNumber || "-"}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Захиалга:">
                        <Group>
                          <Text fw={500} c="gray">
                            {action[1]?.salesCode || "-"}
                          </Text>
                          <Badge
                            size="md"
                            radius="sm"
                            variant="outline"
                            style={{
                              border: `${orderStatus.find((c: any) => c.code === action[1].orderStatus)?.color} 1px solid`,
                              color: `${orderStatus.find((c: any) => c.code === action[1].orderStatus)?.color}`,
                            }}>
                            {orderStatus.find((c: any) => c.code === action[1].orderStatus)?.sentName || "-"}
                          </Badge>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Нийлүүлэгчийн менежер:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.supplierStaff?.lastName === null ? "" : action[1]?.supplierStaff?.lastName[0]}
                            {action[1]?.supplierStaff?.lastName ? "." : ""} {action[1]?.supplierStaff?.firstName || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {action[1]?.supplierStaff?.phone || "-"}, {action[1]?.supplierStaff?.email || "-"}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Бизнесийн нэр:">
                        <Group>
                          <Text fw={500} c="cyan">
                            {action[1]?.dropshipperBusiness?.refCode || "-"}
                          </Text>
                          <Text fw={500} c="gray">
                            {action[1]?.dropshipperBusiness?.profileName || "-"}
                          </Text>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүргэлтийн статус:">
                        <Group>
                          <Text fw={500} c="gray">
                            {action[1]?.deliveryNote?.refCode || "-"}
                          </Text>
                          <Badge
                            size="md"
                            radius="sm"
                            variant="outline"
                            style={{
                              border: `${
                                (deliveryNoteStatus || []).find((c: any) => c?.code === action[1]?.deliveryNote?.deliveryNoteStatus)?.color
                              } 1px solid`,
                              color: `${(deliveryNoteStatus || []).find((c: any) => c?.code === action[1]?.deliveryNote?.deliveryNoteStatus)?.color}`,
                            }}>
                            {(deliveryNoteStatus || []).find((c: any) => c?.code === action[1]?.deliveryNote?.deliveryNoteStatus)?.name || "-"}
                          </Badge>
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                  </Grid>
                </Box>
              </Collapse>

              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                  <Text fw={500}>{"ЗАХИАЛГЫН ЗҮЙЛС"}</Text>
                  <ActionIcon variant="transparent">
                    {!collapsedOpen ? (
                      <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapsedOpen(true)} />
                    ) : (
                      <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapsedOpen(false)} />
                    )}
                  </ActionIcon>
                </Flex>
              </Box>
              <Collapse in={collapsedOpen}>
                <SupplierDropShippingProductForm action={action} setFieldValue={setFieldValue} values={values} />
              </Collapse>
            </PageLayout>
          );
        }}
      </Form>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Борлуулалтын захиалгын жагсаалт",
    to: "/supplier-drop-shipping",
  },
  { label: "Дэлгэрэнгүй" },
];

const useStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    border: `1px solid #868E96`,
    borderRadius: theme.radius.md,
    height: 50,
    width: "100%",

    backgroundColor: theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.05)",
    },
  },
}));
