import { ActionIcon, Badge, Button, Checkbox, Divider, Flex, Grid, Group, LoadingOverlay, Modal, Select, Text, Tooltip } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendarDue, IconChecks, IconChevronDown, IconClearAll, IconExclamationMark, IconInfoSquare } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSwr from "swr";
import { OrderApi } from "../../apis";
import { PurchaseSalesConfirmForm } from "../../components/purchase-sales/confirm-form";
import { PurchaseSalesSOForm } from "../../components/purchase-sales/so-form";
import { StackForm } from "../../components/purchase-sales/stack-form";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IOrder } from "../../interfaces/IOrder";
import { IPermissions } from "../../interfaces/IPermissions";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { Dialog } from "../../utils/confirm-modal";
import { dateTimeSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export function SupplierPurchaseList({
  actions,
  checked,
  setChecked,
  setActions,
}: {
  actions: string[];
  setActions: (e: string[]) => void;
  checked: string[];
  setChecked: (e: string[]) => void;
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { orderStatus, paymentStatus, deliveryNoteStatus, invoiceStatus, paymentTermConditions, orderPurchaseTypes } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const [queryBusiness] = React.useState<string>("");
  const [action, setAction] = React.useState<string[]>([]);
  const ref = React.useRef<ITableRef>(null);
  const [filters, setFilters] = React.useState<any>({
    type: "PURCHASE",
    orderStatus: "",
    businessId: "",
    startDate: "",
    endDate: "",
    invoiceStatus: "",
    paymentStatus: "",
    paymentTermCondition: "",
    excel: `${false}`,
  });

  const columns = useHeader({
    user,
    checked,
    orderStatus,
    paymentStatus,
    invoiceStatus,
    deliveryNoteStatus,
    orderPurchaseTypes,
    onCLickCheck: (key: string, row: any) => {
      if (key === "select_all") {
        setChecked(row);
      }
      if (key === "unselect_all") {
        setChecked([]);
      }
      if (key === "select") {
        if (checked.some((item: any) => item.id === row.id)) {
          setChecked([...checked.filter((item: any) => item.id !== row.id)]);
        } else setChecked([...checked, row]);
      }
    },
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          navigate(`/supplier-purchase/detail/${record?.id}`);
          break;
        }
        case "confirm": {
          setAction(["confirm", record as any, "confirm"]);
          break;
        }
        case "reject": {
          setAction(["reject", record as any, "reject"]);
          break;
        }
        case "cancel": {
          Dialog.confirm("Та борлуулалтын захиалгыг цуцлах гэж байна итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  let res = await OrderApi.cancel(record?.id);
                  if (res.success) {
                    setTimeout(() => {
                      ref.current?.reload();
                    }, 200);
                  }
                  message.success("Амжилттай захиалгыг амжилттай цуцаллаа!");
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  message.error((err as HttpHandler)?.message!);
                }
                break;
              }

              default:
            }
          });
          break;
        }

        default:
      }
    },
  });

  const reload = async () => {
    ref.current?.reload();
  };

  const onCancel = async () => {
    setActions([]);
    setChecked([]);
  };

  const { data: businessData } = useSwr(`/ord/order/business_select/data/${queryBusiness}`, async () => {
    if ((user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0) {
      try {
        let res = await OrderApi.business({ query: queryBusiness, limit: "" });
        return res;
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
      }
    }
  });

  const onClear = () => {
    setFilters({
      type: "PURCHASE",
      orderStatus: null,
      businessId: null,
      startDate: null,
      endDate: null,
      invoiceStatus: null,
      paymentStatus: null,
      paymentTermCondition: null,
    });
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <Flex direction={"column"} gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
        <Grid>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Select
              value={filters.orderStatus}
              data={[{ code: "", sentName: "Бүгд" }, ...(orderStatus || [])].map((item: any) => {
                return {
                  label: item.sentName,
                  value: item.code,
                };
              })}
              placeholder="сонгох"
              leftSectionWidth={150}
              onChange={(e: string | null) => {
                if (e) setFilters({ ...filters, orderStatus: e });
              }}
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              rightSection={<IconChevronDown size="1rem" stroke={1} />}
              clearable
              leftSection={
                <Group w={"calc(100% - 20px)"} align="center">
                  <Text size="sm" fw={450} c="#44566C">
                    {"PO статус"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
            />
          </Grid.Col>

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0 && (
            <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
              <Select
                value={filters?.businessId}
                onChange={(e) => {
                  setFilters({ ...filters, businessId: e });
                }}
                clearable
                searchable
                placeholder="сонгох"
                leftSectionWidth={150}
                style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                rightSection={<IconChevronDown size="1rem" stroke={1} />}
                data={(businessData || [])?.map((item: any) => ({
                  label: item.profileName,
                  value: item.id,
                }))}
                leftSection={
                  <Group w={"calc(100% - 20px)"} align="center">
                    <Text size="sm" fw={450} c="#44566C">
                      {"Бизнесийн нэр"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
              />
            </Grid.Col>
          )}
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Select
              value={filters.invoiceStatus}
              data={[{ code: "", name: "Бүгд" }, ...(invoiceStatus || [])].map((item: any) => {
                return {
                  label: item.name,
                  value: item.code,
                };
              })}
              placeholder="сонгох"
              leftSectionWidth={170}
              onChange={(e: string | null) => {
                setFilters({ ...filters, invoiceStatus: e });
              }}
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              rightSection={<IconChevronDown size="1rem" stroke={1} />}
              clearable
              leftSection={
                <Group w={"calc(100% - 20px)"} align="center">
                  <Text size="sm" fw={450} c="#44566C">
                    {"Нэхэмжлэх статус"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <DatePickerInput
              type="range"
              value={[filters.startDate, filters.endDate]}
              placeholder="сонгох"
              leftSectionWidth={100}
              onChange={(e) => {
                if (e[0] && e[1]) {
                  setFilters({ ...filters, startDate: e[0] && e[0], endDate: e[1] && e[1] });
                }
              }}
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              rightSection={<IconCalendarDue size="1rem" stroke={1} />}
              leftSection={
                <Group w={"calc(100% - 20px)"} align="center">
                  <Text size="sm" fw={450} c="#44566C">
                    {"Огноо"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Select
              value={filters.paymentStatus}
              data={[{ code: "", name: "Бүгд" }, ...(paymentStatus || [])].map((item: any) => {
                return {
                  label: item.name,
                  value: item.code,
                };
              })}
              placeholder="сонгох"
              leftSectionWidth={200}
              onChange={(e: string | null) => {
                setFilters({ ...filters, paymentStatus: e });
              }}
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              rightSection={<IconChevronDown size="1rem" stroke={1} />}
              clearable
              leftSection={
                <Group w={"calc(100% - 20px)"} align="center">
                  <Text size="sm" fw={450} c="#44566C">
                    {"Төлбөрийн статус"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
            />
          </Grid.Col>

          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Flex gap="xs" align="center">
              <Select
                value={filters.paymentTermCondition}
                data={[{ code: "", name: "Бүгд" }, ...(paymentTermConditions || [])].map((item: any) => {
                  return {
                    label: item.name,
                    value: item.code,
                  };
                })}
                placeholder="сонгох"
                leftSectionWidth={200}
                onChange={(e: string | null) => {
                  setFilters({ ...filters, paymentTermCondition: e });
                }}
                style={{ borderRight: "none", borderRadius: "4px 0 0 4px", flex: 1 }}
                rightSection={<IconChevronDown size="1rem" stroke={1} />}
                clearable
                leftSection={
                  <Group w={"calc(100% - 20px)"} align="center">
                    <Text size="sm" fw={450} c="#44566C">
                      {"Төлбөрийн нөхцөл"}
                    </Text>
                    <Text>:</Text>
                  </Group>
                }
              />
              <ActionIcon
                onClick={() => {
                  onClear();
                }}
                color=""
                variant="light"
                size={36}>
                <IconClearAll />
              </ActionIcon>
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider color="gray" mt={15} mb={10} />
        <Table ref={ref} name="order-purchase.list" filters={filters} columns={columns} loadData={(data) => OrderApi.list(data!)} />
      </Flex>
      <Modal
        opened={action[0] === "confirm" || action[0] === "reject"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        size="600px"
        padding={0}
        centered>
        <PurchaseSalesConfirmForm action={action && action[1]} setActions={setAction} constant={action && action[2]} reload={reload} />
      </Modal>
      <Modal
        opened={actions[0] === "purchase"}
        onClose={() => {
          setActions([]);
        }}
        withCloseButton={false}
        size="600px"
        padding={0}
        centered>
        <PurchaseSalesSOForm action={actions} onCancel={onCancel} reload={reload} setChecked={setChecked} />
      </Modal>
      <Modal
        opened={actions[0] === "stack"}
        onClose={() => {
          setActions([]);
        }}
        withCloseButton={false}
        size="80%"
        padding={0}
        centered>
        <StackForm action={actions} onCancel={onCancel} reload={reload} />
      </Modal>
    </>
  );
}

const useHeader = ({
  onClick,
  orderStatus,
  paymentStatus,
  deliveryNoteStatus,
  orderPurchaseTypes,
  invoiceStatus,
  user,
  checked,
  onCLickCheck,
}: {
  user: any;
  checked: string[];
  orderPurchaseTypes: IReference[];
  onClick: (key: string, record: any) => void;
  onCLickCheck: (key: string, record: IOrder) => void;
  orderStatus: IReference[];
  paymentStatus: IReference[];
  deliveryNoteStatus: IReference[];
  invoiceStatus: IReference[];
}): ColumnType<IOrder>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    renderCheck(record: any) {
      let checkedData: any[];
      let recordData: any[];
      checkedData = (checked || []).filter((item: any) => item.orderStatus === "REVIEWED" && user?.currentBusiness?.id === item.receiverBusiness?.id);
      recordData = (record?.rows || []).filter((item: any) => item.orderStatus === "REVIEWED" && user?.currentBusiness?.id === item.receiverBusiness?.id);

      return (
        <>
          <Checkbox
            onChange={() => {}}
            onClick={() => {
              if (Array.isArray(recordData) && Array.isArray(checkedData) && recordData.length === checkedData.length) {
                onCLickCheck("unselect_all", recordData as any);
              } else onCLickCheck("select_all", recordData as any);
            }}
            checked={checkedData?.length === recordData?.length}
          />
        </>
      );
    },
    title: "",
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {}}
          onClick={() => {
            onCLickCheck("select", record);
          }}
          checked={checked.some((item: any) => item.id === record.id)}
          disabled={record?.orderStatus === "REVIEWED" && user?.currentBusiness?.id === record?.receiverBusiness?.id ? false : true}
        />
      );
    },
  },
  {
    title: "Үйлдэл",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isView).length > 0 && (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_RES").length > 0 && (
            <>
              {record?.orderStatus === "REVIEWED" && user?.currentBusiness?.id === record?.receiverBusiness?.id && (
                <Tooltip label="Зөвшөөрөх" position="bottom">
                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("confirm", record)}>
                    <IconChecks />
                  </Button>
                </Tooltip>
              )}
            </>
          )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_RES").length > 0 && (
            <>
              {record?.orderStatus === "REVIEWED" && user?.currentBusiness?.id === record?.receiverBusiness?.id && (
                <Tooltip label="Татгалзах" position="bottom">
                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("reject", record)} color="red">
                    <IconExclamationMark color="red" />
                  </Button>
                </Tooltip>
              )}
            </>
          )}
        </Flex>
      );
    },
  },
  {
    title: "PO №",
    sorter: true,
    dataIndex: "purchaseCode",
    width: "200px",
    render: (record) => {
      return (
        <Text size="sm" c="cyan" w="max-content">
          {record?.purchaseCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "PO Огноо, цаг",
    sorter: true,
    dataIndex: "respondedDate",
    width: "200px",
    render: (record) => {
      return (
        <Text size="sm" c="cyan" w="max-content">
          {dateTimeSecFormat(record?.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "PO төрөл",
    sorter: true,
    dataIndex: "respondedDate",
    render: (record) => {
      return (
        <>
          {record?.purchaseType ? (
            <Badge
              size="md"
              radius="sm"
              variant="outline"
              color="gray"
              style={{
                border: `${(orderPurchaseTypes || []).find((c: any) => c.code === record.purchaseType)?.color} 1px solid`,
                color: `${(orderPurchaseTypes || []).find((c: any) => c.code === record.purchaseType)?.color}`,
              }}
              styles={{
                label: {
                  overflow: "visible",
                },
              }}>
              {(orderPurchaseTypes || [])?.find((c: any) => c?.code === record?.purchaseType)?.name || "-"}
            </Badge>
          ) : (
            <Text size="sm" c="#44566C">
              {"-"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Худалдан авагч",
    sorter: true,
    dataIndex: "senderBusiness",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.senderBusiness?.partner?.refCode || "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.senderBusiness?.partner?.businessName || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "partner",
    width: "250px",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.senderBusiness?.refCode || "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.senderBusiness?.profileName || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Нийлүүлэгч партнер",
    sorter: true,
    dataIndex: "senderBusiness",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.receiverBusiness?.partner?.refCode || "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.receiverBusiness?.partner?.businessName || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "senderBusiness",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.receiverBusiness?.refCode || "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.receiverBusiness?.profileName || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "PO статус",
    sorter: true,
    dataIndex: "repondedUser",
    render: (record) => {
      if (record?.type === "SALES") {
        return (
          <Badge
            size="md"
            radius="sm"
            variant="outline"
            style={{
              border: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
              color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
            }}
            styles={{ label: { overflow: "visible" } }}>
            {orderStatus.find((c: any) => c.code === record.orderStatus)?.sentName || "-"}
          </Badge>
        );
      } else {
        return (
          <Badge
            size="md"
            radius="sm"
            variant="outline"
            style={{
              border: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
              color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
            }}
            styles={{ label: { overflow: "visible" } }}>
            {orderStatus.find((c: any) => c.code === record.orderStatus)?.receivedName || "-"}
          </Badge>
        );
      }
    },
  },
  {
    title: "Хүргэлт огноо",
    sorter: true,
    dataIndex: "deliveryDate",
    width: "200px",
    render: (record) => {
      return (
        <Text size="sm" c="cyan" w="max-content">
          {dateTimeSecFormat(record?.deliveryDate || "-")}
        </Text>
      );
    },
  },
  {
    title: "Хүргэлт статус",
    sorter: true,
    dataIndex: "delivery_status",
    render: (record) => {
      return (
        <>
          {record?.deliveryNote !== null ? (
            <Badge
              size="md"
              radius="sm"
              variant="outline"
              style={{
                border: `${deliveryNoteStatus.find((c: any) => c.code === record?.deliveryNote?.deliveryNoteStatus)?.color} 1px solid`,
                color: `${deliveryNoteStatus.find((c: any) => c.code === record?.deliveryNote?.deliveryNoteStatus)?.color}`,
              }}
              styles={{
                label: {
                  overflow: "visible",
                },
              }}>
              {deliveryNoteStatus.find((c: any) => c?.code === record?.deliveryNote?.deliveryNoteStatus)?.name || "-"}
            </Badge>
          ) : (
            <Text size="sm" c="#44566C">
              {"-"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Хүргэлт хариуцсан",
    sorter: true,
    dataIndex: "deliveryNote-staff",
    render: (record) => {
      return (
        <>
          {record?.deliveryNote !== null ? (
            <div>
              <Text size="sm" c="#44566C" w="max-content">
                {record?.deliveryNote?.staff?.lastName ? record?.deliveryNote?.staff?.lastName[0] : "-"}.{record?.deliveryNote?.staff?.firstName || "-"}
              </Text>
              <Text size="sm" c="cyan" w="max-content">
                {record?.deliveryNote?.staff?.email || "-"}
              </Text>
            </div>
          ) : (
            <Text size="sm" c="#44566C">
              {"-"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Хүлээн авах салбар",
    sorter: true,
    dataIndex: "receiverBranch",
    render: (record) => {
      return (
        <Text size="sm" c="cyan" w="max-content">
          {record?.receiverBranch?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээн авах ажилтан",
    sorter: true,
    dataIndex: "receiverStaff",
    render: (record) => {
      return (
        <Text size="sm" c="cyan" w="max-content">
          {record?.receiverStaff?.lastName || "-"}, {record?.receiverStaff?.firstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Захиалгын дүн",
    sorter: true,
    dataIndex: "orderAmount",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {tugrug(record?.orderAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Төлсөн дүн",
    sorter: true,
    dataIndex: "paidAmount",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {tugrug(record?.paidAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Үлдэгдэл төлбөр",
    sorter: true,
    dataIndex: "amountToPay",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {tugrug(record?.amountToPay || 0)}
        </Text>
      );
    },
  },
  {
    title: "Төлбөрийн статус",
    sorter: true,
    dataIndex: "payment-status",
    render: (record) => {
      return (
        <>
          {record?.invoiceId !== null ? (
            <Badge
              size="md"
              radius="sm"
              variant="outline"
              style={{
                border: `${paymentStatus.find((c: any) => c.code === record?.invoice?.paymentStatus)?.color} 1px solid`,
                color: `${paymentStatus.find((c: any) => c.code === record?.invoice?.paymentStatus)?.color}`,
              }}
              styles={{
                label: {
                  overflow: "visible",
                },
              }}>
              {paymentStatus.find((c: any) => c.code === record?.invoice?.paymentStatus)?.name || "-"}
            </Badge>
          ) : (
            <Text size="sm" c="#44566C">
              {"-"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Нэхэмжлэх №",
    sorter: true,
    dataIndex: "invoice",
    render: (record) => {
      return (
        <>
          {record?.invoiceId !== null ? (
            <Text size="sm" c="cyan" w="max-content">
              {record?.invoice?.refCode || "-"}
            </Text>
          ) : (
            <Text size="sm" c="#44566C">
              {"-"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Нэхэмжлэх статус",
    sorter: true,
    dataIndex: "invoice_status",
    render: (record) => {
      return (
        <>
          {record?.invoiceId !== null ? (
            <Badge
              size="md"
              radius="sm"
              variant="outline"
              style={{
                border: `${invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.color} 1px solid`,
                color: `${invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.color}`,
              }}
              styles={{
                label: {
                  overflow: "visible",
                },
              }}>
              {invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.name || "-"}
            </Badge>
          ) : (
            <Text size="sm" c="#44566C">
              {"-"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Нэхэмжлэх баталсан",
    sorter: true,
    dataIndex: "repondedUser",
    render: (record) => {
      return (
        <Text size="sm" c="#243f60">
          {(record?.type === "SALES" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
            ? `${record.respondedUser?.lastName ? record.respondedUser.lastName[0] : "-"}.${record.respondedUser?.firstName || "-"}`
            : `${record?.reviewedUser?.lastName ? record?.reviewedUser?.lastName[0] : "-"}.${record?.reviewedUser?.firstName || "-"}`}
        </Text>
      );
    },
  },
  {
    title: "Төлбөрийн нөхцөл",
    sorter: true,
    dataIndex: "invoice_status",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.paymentTerm?.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "PO өгсөн",
    sorter: true,
    dataIndex: "repondedUser",
    width: "250px",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.type === "PURCHASE"
            ? `${record?.orderedUser?.lastName ? record?.orderedUser?.lastName[0] : "-"}.${record?.orderedUser?.firstName || "-"}`
            : "-"}
        </Text>
      );
    },
  },
  {
    title: "PO хянасан",
    sorter: true,
    dataIndex: "revieweduser",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C">
          {(record?.type === "PURCHASE" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
            ? `${record?.respondedUser?.lastName ? record?.respondedUser?.lastName[0] : "-"}.${record?.respondedUser?.firstName || "-"}`
            : `${record?.reviewedUser?.lastName ? record?.reviewedUser?.lastName[0] : "-"}.${record?.reviewedUser?.firstName || "-"}`}
        </Text>
      );
    },
  },
];
