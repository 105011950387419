import { Avatar, Button, Group, LoadingOverlay, Table, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import { IconPhoto, IconShoppingCart } from "@tabler/icons-react";
import React from "react";
import { PullSheetApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IDeliveryNotes } from "../../interfaces/IDeliveryNotes";
import { IWareHouse } from "../../interfaces/IWareHouse";
import { IUserType } from "../../models/General";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export default function SupplierDeliveryDistributingTable({
  warehouses,
  deliveryNote,
  staff,
  setPullSheet,
  delivery,
}: {
  warehouses: IWareHouse[];
  deliveryNote: IDeliveryNotes[];
  staff: IUserType;
  setPullSheet: any;
  delivery: any;
}) {
  const { classes } = useStyles();
  const initialData = (warehouses || []).map((item) => ({ id: item.id, shippinDate: "" }));
  const [data, setData] = React.useState<any>(initialData);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  // let [deliveryData] = React.useState<any>((delivery || []).map((c: any) => (c || []).map((row: any) => row)));

  const onSubmit = async (id: string, values: any) => {
    const shouldError = !data?.find((f: any) => f.id === id)?.shippinDate;
    const payload = data?.find((f: any) => f.id === id)?.shippinDate;
    if (shouldError) {
      setError(id);
      message.error("Хүлээлгэж өгөх огноог сонгоно уу");
    } else {
      setLoading(true);
      try {
        await PullSheetApi.create({
          warehouseId: id,
          loadingDate: payload,
          deliveryNoteIds: (values || [])?.map((c: any) => {
            return c.id;
          }),
        });
        message.success("Таны хүсэлт амжилттай!");
        setPullSheet(id);
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
      } finally {
        setLoading(false);
      }
    }
  };

  const onChange = (value: any, id: string) => {
    const tt = data.find((f: any) => f?.id === id);
    const payload = { id: tt.id, shippinDate: value };
    const arr = data.filter((filter: any) => filter.id !== id);
    setData([...arr, payload]);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <div className={classes.container}>
      <div className={classes.scroll}>
        <Table>
          <Table.Thead key={2}>
            <Table.Tr>
              <Table.Th rowSpan={2}></Table.Th>
              <Table.Th rowSpan={2}>Зураг</Table.Th>
              <Table.Th rowSpan={2}>DeHUB код</Table.Th>
              <Table.Th rowSpan={2}>SKU</Table.Th>
              <Table.Th rowSpan={2}>Барааны нэр</Table.Th>
              <Table.Th rowSpan={2}>Хэмжих нэгж</Table.Th>
              <Table.Th rowSpan={2}>Нийт</Table.Th>
              <Table.Th colSpan={deliveryNote?.length}>
                Хүргэлт хариуцсан:{" "}
                <span>
                  {staff?.lastName || "-"} {staff?.firstName || "-"}
                </span>
              </Table.Th>
            </Table.Tr>
            <Table.Tr>
              {(deliveryNote || [])?.map((dn) => (
                <Table.Th key={dn?.id}>{dn?.refCode}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody key={3}>
            {(warehouses || [])?.map((wh) => (
              <>
                <Table.Tr key={`${wh?.id}`}>
                  <Table.Td colSpan={4 + deliveryNote?.length}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                      }}>
                      <span>
                        <Group mt={7}>
                          <Text size="sm" fw={600} c="dimmed">
                            Агуулах:
                          </Text>
                          <Text size="sm" fw={600} c="#495057">
                            {wh?.name}
                          </Text>
                        </Group>
                      </span>
                      <span>
                        <Group mt={7}>
                          <Text size="sm" fw={600} c="dimmed">
                            Нярав:
                          </Text>
                          <Text size="sm" fw={600} c="#495057">
                            {`${wh?.warehouseUser?.lastName || "-"}, ${wh?.warehouseUser?.firstName || "-"}`}
                          </Text>
                        </Group>
                      </span>
                      <span>
                        <DatePickerInput
                          size="xs"
                          error={error === wh.id && !data?.find((f: any) => f.id === wh?.id)?.shippinDate ? "Заавал бөглөнө!" : ""}
                          onChange={(e) => onChange(e, wh?.id)}
                          placeholder="өдөр сонгох"
                          styles={{
                            error: {
                              display: "block",
                            },
                            cell: {
                              padding: "0px !important",
                            },
                          }}
                        />
                      </span>
                      <span>
                        <Button onClick={() => onSubmit(wh?.id, deliveryNote)} variant="outline" leftSection={<IconShoppingCart />} size="xs" key="confirm">
                          {"Зөвшөөрөх"}
                        </Button>
                      </span>
                    </div>
                  </Table.Td>
                </Table.Tr>

                {(wh?.lines || [])?.map((line, index) => (
                  <Table.Tr key={`${wh?.id}${line?.id}`}>
                    <Table.Td>{index + 1}</Table.Td>
                    <Table.Td>
                      <Avatar size={40} src={line?.image !== null ? line?.image : noimage}>
                        <IconPhoto />
                      </Avatar>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {line?.refCode || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {line?.skuCode || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {line?.name || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {line?.unit || "-"}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text size="sm" fw={500} c="#44566C">
                        {line?.baseUnitQuantity || 0}
                      </Text>
                    </Table.Td>

                    {(deliveryNote || []).map((dn) => (
                      <Table.Td key={dn.id}>
                        {(dn.lines || []).filter((c: any) => c.variantId === line.variantId) && (
                          <Text size="sm" fw={500} c="#44566C">
                            {(dn.lines || []).map((c: any) => (
                              <>
                                {c.variantId === line.variantId && (
                                  <Text size="sm" fw={500} c="#44566C">
                                    {c?.quantity}
                                  </Text>
                                )}
                              </>
                            ))}
                          </Text>
                        )}
                      </Table.Td>
                    ))}
                  </Table.Tr>
                ))}
              </>
            ))}
          </Table.Tbody>
        </Table>
      </div>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  scroll: {
    overflowX: "auto",
  },
  table: {
    minWidth: 1000,
  },
  container: {
    position: "relative",
  },
}));
