import { Badge, Button, Flex, Table, Text, Tooltip } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconInfoSquare } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DropShippingApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IOrder } from "../../interfaces/IOrder";
import { IPermissions } from "../../interfaces/IPermissions";
import { tugrug } from "../../utils";
import { dateTimeSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { PopoverText } from "../popover-text/popover-text";
import { ITableRef } from "../table";
import { CustomTable } from "../table/customTable";

export function SupplierDropShipping() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const ref = React.useRef<ITableRef>(null);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { orderStatus, paymentStatus, deliveryNoteStatus, orderTypes, invoiceStatus, orderPurchaseTypes } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );

  const onClick = (key: any, record: any) => {
    switch (key) {
      case "detail": {
        navigate(`/supplier-drop-shipping/detail/${record.id}`);
        break;
      }
      default:
    }
  };

  return (
    <>
      <Flex direction={"column"} gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
        <CustomTable
          ref={ref}
          colCount={34}
          thead={
            <Table.Thead>
              <Table.Tr>
                <Table.Th rowSpan={2}>#</Table.Th>
                {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DRSHP").length > 0 && (
                  <Table.Th rowSpan={2}>Үйлдэл</Table.Th>
                )}
                <Table.Th
                  className={`${classes.control}`}
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={6}>
                  Борлуулалтын захиалга
                </Table.Th>
                <Table.Th
                  className={`${classes.control}`}
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={7}>
                  Харилцагчийн мэдээлэл
                </Table.Th>
                <Table.Th
                  className={`${classes.control}`}
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={4}>
                  Хүргэлтийн мэдээлэл
                </Table.Th>
                <Table.Th
                  className={`${classes.control}`}
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={7}>
                  Төлбөрийн мэдээлэл
                </Table.Th>
                <Table.Th
                  className={`${classes.control}`}
                  style={{
                    textAlign: "center",
                  }}
                  colSpan={6}>
                  PO
                </Table.Th>
              </Table.Tr>
              <Table.Tr>
                <Table.Th className={`${classes.control}`}>SO дугаар</Table.Th>
                <Table.Th className={`${classes.control}`}>Огноо, цаг</Table.Th>
                <Table.Th className={`${classes.control}`}>Төрөл</Table.Th>
                <Table.Th className={`${classes.control}`} style={{ minWidth: 100 }}>
                  Статус
                </Table.Th>
                <Table.Th className={`${classes.control}`}>Бүртгэсэн</Table.Th>
                <Table.Th className={`${classes.control}`}>Хянасан</Table.Th>
                <Table.Th className={`${classes.control}`}>Партнер нэр</Table.Th>
                <Table.Th className={`${classes.control}`}>ТТД</Table.Th>
                <Table.Th className={`${classes.control}`}>Бизнес нэр</Table.Th>
                <Table.Th className={`${classes.control}`}>Хүлээн авах салбар</Table.Th>
                <Table.Th className={`${classes.control}`}>Хүлээн авах ажилтан</Table.Th>
                <Table.Th className={`${classes.control}`}>Бүсчлэл</Table.Th>
                <Table.Th className={`${classes.control}`}>Чиглэл</Table.Th>
                <Table.Th className={`${classes.control}`}>Хүргэх огноо</Table.Th>
                <Table.Th className={`${classes.control}`}>Статус</Table.Th>
                <Table.Th className={`${classes.control}`}>Хүргэлт хариуцсан</Table.Th>
                <Table.Th className={`${classes.control}`}>Хүргэсэн огноо</Table.Th>
                <Table.Th className={`${classes.control}`}>Нийт дүн</Table.Th>
                <Table.Th className={`${classes.control}`}>Төлсөн дүн</Table.Th>
                <Table.Th className={`${classes.control}`}>Төлөх дүн</Table.Th>
                <Table.Th className={`${classes.control}`}>Төлбөрийн статус</Table.Th>
                <Table.Th className={`${classes.control}`}>Нөхцөл</Table.Th>
                <Table.Th className={`${classes.control}`}>Нэхэмжлэх №</Table.Th>
                <Table.Th className={`${classes.control}`}>Нэхэмжлэх статус</Table.Th>
                <Table.Th className={`${classes.control}`}>PO дугаар</Table.Th>
                <Table.Th className={`${classes.control}`}>Огноо, цаг</Table.Th>
                <Table.Th className={`${classes.control}`}>Төрөл</Table.Th>
                <Table.Th className={`${classes.control}`}>Статус</Table.Th>
                <Table.Th className={`${classes.control}`}>Захиалсан</Table.Th>
                <Table.Th className={`${classes.control}`}>Хянасан</Table.Th>
              </Table.Tr>
            </Table.Thead>
          }
          tbody={(rows: IOrder[]) => {
            return (
              <Table.Tbody>
                {rows.map((record, index) => {
                  return (
                    <Table.Tr key={record.id ?? index}>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C">
                          {index + 1}
                        </Text>
                      </Table.Td>
                      {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DRSHP").length > 0 && (
                        <Table.Td>
                          <Tooltip label="Дэлгэрэнгүй" position="bottom">
                            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                              <IconInfoSquare />
                            </Button>
                          </Tooltip>
                        </Table.Td>
                      )}
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.salesCode || "-"}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {dateTimeSecFormat(record?.createdAt)}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {orderTypes?.find((c: any) => c?.code === record?.type)?.name || "-"}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          style={{
                            border: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
                            color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                          }}
                          styles={{
                            label: {
                              overflow: "visible",
                            },
                          }}>
                          {orderStatus.find((c: any) => c.code === record.orderStatus)?.sentName || "-"}
                        </Badge>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {renderName(record?.type === "SALES" ? record.orderedUser : null)}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C">
                          {renderName(
                            (record?.type === "PURCHASE" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
                              ? record.respondedUser
                              : record?.reviewedUser,
                          )}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <div>
                          <Text size="sm" fw={500} c="#44566C" w="max-content">
                            {record?.receiverBusiness?.partner?.refCode || "-"}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-content">
                            {record?.receiverBusiness?.partner?.businessName || "-"}
                          </Text>
                        </div>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.receiverBusiness?.regNumber || "-"}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <div>
                          <Text size="sm" fw={500} c="#44566C" w="max-content">
                            {record?.receiverBusiness?.refCode || "-"}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-content">
                            {record?.receiverBusiness?.profileName || "-"}
                          </Text>
                        </div>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="cyan" w="max-content">
                          {record?.receiverBranch?.name || "-"}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <div>
                          <Text size="sm" fw={500} c="#44566C" w="max-content">
                            {renderName(record?.receiverStaff)}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-content">
                            {record?.receiverStaff?.phone || "-"}
                          </Text>
                        </div>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.region?.name || "-"}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.direction?.name || "-"}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="cyan" w="max-content">
                          {dateTimeSecFormat(record?.deliveryDate || "-")}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          style={{
                            border: `${deliveryNoteStatus.find((c: any) => c.code === record.deliveryNote?.deliveryNoteStatus)?.color} 1px solid`,
                            color: `${deliveryNoteStatus.find((c: any) => c.code === record.deliveryNote?.deliveryNoteStatus)?.color}`,
                          }}
                          styles={{
                            label: {
                              overflow: "visible",
                            },
                          }}>
                          {deliveryNoteStatus.find((c: any) => c?.code === record?.deliveryNote?.deliveryNoteStatus)?.name || "-"}
                        </Badge>
                      </Table.Td>
                      <Table.Td>
                        <div>
                          <Text size="sm" fw={500} c="#44566C" w="max-content">
                            {renderName(record?.deliveryNote?.staff)}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-content">
                            {record?.deliveryNote?.staff?.email ?? "-"}
                          </Text>
                        </div>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {dateTimeSecFormat(record?.deliveryNote?.deliveredDate)}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
                          {tugrug(record?.totalAmount || 0)}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
                          {tugrug(record?.paidAmount || 0)}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
                          {tugrug(record?.amountToPay || 0)}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          style={{
                            border: `${paymentStatus.find((c: any) => c?.code === record?.invoice?.paymentStatus)?.color} 1px solid`,
                            color: `${paymentStatus.find((c: any) => c?.code === record?.invoice?.paymentStatus)?.color}`,
                          }}
                          styles={{
                            label: {
                              overflow: "visible",
                            },
                          }}>
                          {paymentStatus.find((c: any) => c.code === record?.invoice?.paymentStatus)?.name || "-"}
                        </Badge>
                      </Table.Td>
                      <Table.Td>
                        <PopoverText>{record?.paymentTerm?.description || "-"}</PopoverText>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.invoice?.refCode || "-"}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          style={{
                            border: `${invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.color} 1px solid`,
                            color: `${invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.color}`,
                          }}
                          styles={{
                            label: {
                              overflow: "visible",
                            },
                          }}>
                          {invoiceStatus.find((c: any) => c.code === record?.invoice?.invoiceStatus)?.name || "-"}
                        </Badge>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="#44566C" w="max-content">
                          {record?.purchaseCode || "-"}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text size="sm" fw={500} c="cyan" w="max-content">
                          {dateTimeSecFormat(record?.respondedDate)}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          color="gray"
                          style={{
                            border: `${orderPurchaseTypes.find((c: any) => c.code === record.purchaseType)?.color} 1px solid`,
                            color: `${orderPurchaseTypes.find((c: any) => c.code === record.purchaseType)?.color}`,
                          }}
                          styles={{
                            label: {
                              overflow: "visible",
                            },
                          }}>
                          {orderPurchaseTypes?.find((c: any) => c?.code === record?.purchaseType)?.name || "-"}
                        </Badge>
                      </Table.Td>
                      <Table.Td>
                        {record?.type === "SALES" ? (
                          <Badge
                            size="md"
                            radius="sm"
                            variant="outline"
                            style={{
                              border: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
                              color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                            }}
                            styles={{
                              label: {
                                overflow: "visible",
                              },
                            }}>
                            {orderStatus.find((c: any) => c.code === record.orderStatus)?.sentName || "-"}
                          </Badge>
                        ) : (
                          <Badge
                            size="md"
                            radius="sm"
                            variant="outline"
                            style={{
                              border: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color} 1px solid`,
                              color: `${orderStatus.find((c: any) => c.code === record.orderStatus)?.color}`,
                            }}
                            styles={{
                              label: {
                                overflow: "visible",
                              },
                            }}>
                            {orderStatus.find((c: any) => c.code === record.orderStatus)?.receivedName || "-"}
                          </Badge>
                        )}
                      </Table.Td>
                      <Table.Td>
                        <div>
                          <Text size="sm" fw={500} c="#44566C" w="max-content">
                            {renderName(record?.type === "PURCHASE" ? record.orderedUser : null)}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-content">
                            {record?.type === "PURCHASE" ? record?.orderedUser?.phone || "-" : "-"}
                          </Text>
                        </div>
                      </Table.Td>
                      <Table.Td>
                        <div>
                          <Text size="sm" fw={500} c="#243f60" w="max-content">
                            {renderName(
                              (record?.type === "SALES" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
                                ? record.respondedUser
                                : record.reviewedUser,
                            )}
                          </Text>
                          <Text size="sm" fw={500} c="cyan" w="max-content">
                            {(record?.type === "SALES" && record?.orderStatus === "AUTHORIZED") || record?.orderStatus === "APPROVED"
                              ? record.respondedUser?.phone || "-"
                              : record?.reviewedUser?.phone || "-"}
                          </Text>
                        </div>
                      </Table.Td>
                    </Table.Tr>
                  );
                })}
              </Table.Tbody>
            );
          }}
          name="supplier-drop-shipping-delivery.list"
          filters={{
            isDropshipping: `${false}`,
            orderStatus: "",
            excel: `${false}`,
          }}
          loadData={(data: any) => DropShippingApi.list(data!)}
        />
      </Flex>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: "relative",
  },
  table: {
    minWidth: 1000,
  },
  th: {
    padding: "8px!important",
    lineHeight: "14px",
    whiteSpace: "nowrap",
  },
  td: {
    padding: "8px!important",
  },
  style: {
    width: "auto",
    textAlign: "center",
  },
  control: {
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  child: {
    display: "flex",
  },
  icon: {
    display: "flex",
  },
}));
