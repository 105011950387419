import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async (data: any) => {
  return httpRequest.get("/ord/order", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/ord/order/${id}`);
};

export const create = async (data: {
  businessId: string;
  receiverBranchId: string;
  deliveryType: string;
  deliveryDate: string;
  receiverStaffId: string;
  lines: [];
  additionalLines?: [];
  senderNote: string;
  senderAdditionalNote: string;
  shippingAmount: string;
  discountType: string;
  discountValue: string;
  attachments: [];
  toReview: boolean;
  send: boolean;
}) => {
  return httpRequest.post("/ord/order", data);
};

export const update = async (
  id: string,
  data: {
    businessId: string;
    receiverBranchId: string;
    deliveryType: string;
    deliveryDate: string;
    receiverStaffId: string;
    lines: [];
    additionalLines?: [];
    senderNote: string;
    senderAdditionalNote: string;
    shippingAmount: string;
    discountType: string;
    discountValue: string;
    attachments: [];
    toReview: boolean;
    send: boolean;
  },
) => {
  return httpRequest.put(`/ord/order/${id}`, data);
};

export const cancel = async (id: string) => {
  return httpRequest.put(`/ord/order/${id}/cancel`);
};

export const respond = async (id: string, data: { approve: boolean }) => {
  return httpRequest.put(`/ord/order/${id}/respond`, data);
};

export const review = async (id: string, data: { review: boolean }) => {
  return httpRequest.put(`/ord/order/${id}/review`, data);
};

export const business = async (data: any) => {
  return httpRequest.get(`/ord/order/business_select`, data);
};

export const respond_bulk = async (data: any) => {
  return httpRequest.put(`/ord/order/respond_bulk`, data);
};

export const split = async (id: string, data: { id: string }) => {
  return httpRequest.put(`/ord/order/${id}/split`, data);
};
