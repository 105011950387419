import { Button, Divider, Grid, Input, LoadingOverlay, Text } from "@mantine/core";
import { IconCalendarTime } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import * as yup from "yup";
import { BackOrderApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";
import { dateTimeSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { DatePickerField } from "../form/date-picker-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { StackLines } from "./line";

const schema = yup.object({
  deliveryDate: yup.string().required("Заавал бөглөнө!").nullable(),
  senderAdditionalNote: yup.string().required("Заавал бөглөнө!").nullable(),
  lines: yup.array().of(
    yup.object({
      quantity: yup.string().required("Заавал бөглөнө!").nullable(),
      buyerBusinessId: yup.string().required("Заавал бөглөнө!").nullable(),
      supplierBusinessId: yup.string().required("Заавал бөглөнө!").nullable(),
      receiverBranchId: yup.string().required("Заавал бөглөнө!").nullable(),
      receiverStaffId: yup.string().required("Заавал бөглөнө!").nullable(),
    }),
  ),
});

type IFormData = {
  deliveryDate: string;
  senderAdditionalNote: string;
  lines: [];
};

export function StackForm({ action, onCancel, reload }: { action: any[]; onCancel: () => void; reload: any }) {
  const ref = useRef<any>(null);
  const nowMoment = new Date();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { data: LinesData } = useSWR(`/order`, async () => {
    try {
      let res = await BackOrderApi.get(
        ((action && action[1]) || [])?.map((c: any) => {
          return c?.id;
        }),
      );
      return res;
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
  });

  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const [data] = React.useState<IFormData>({
    deliveryDate: "",
    senderAdditionalNote: "",
    lines: [],
  });

  const onSubmit = async (value: any) => {
    setLoading(true);

    if (!value?.lines) {
      message.error("Back order хийдэг бараа байхгүй байна!");
    } else {
      const event = new Date(value.deliveryDate);
      try {
        await BackOrderApi.create({
          orderIds: ((action && action[1]) || [])?.map((c: any) => {
            return c?.id;
          }),
          deliveryDate: event.toISOString(),
          senderAdditionalNote: value.senderAdditionalNote,
          lines: (value.lines || []).map((c: any) => {
            return {
              variantId: c.variantId,
              buyerBusinessId: c?.buyerBusinessId,
              supplierBusinessId: c?.supplierBusinessId,
              receiverBranchId: c?.supplierBusinessId,
              receiverStaffId: c?.receiverStaffId,
              quantity: c?.quantity,
            };
          }),
        });
        message.success("Таны хүсэлт амжилттай!");
        onCancel();
        reload();
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
      }
    }
    setLoading(false);
  };

  return (
    <Form ref={ref} validationSchema={schema} onSubmit={() => {}} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <FormLayout
            title="Захилга багцалж нөөц захиалах"
            subTitle=""
            my={20}
            extra={[
              <Button variant="outline" key="cancel" onClick={() => onCancel()}>
                Буцах
              </Button>,

              <Button
                key="save"
                type="submit"
                onClick={async () => {
                  let values = await ref.current.submit();
                  if (!!values) onSubmit(values);
                }}>
                Захиалга үүсгэх
              </Button>,
            ]}>
            <LoadingOverlay visible={loading} />
            <Divider color="gray.5" my={20} />
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Ажилтны нэр">
                  <Text size="sm" fw={500} c="#44566C">
                    {user?.lastName} {user?.firstName || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Огноо цаг">
                  <Text size="sm" fw={500} c="#44566C">
                    {dateTimeSecFormat(nowMoment)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Багцын код">
                  <Text size="sm" fw={500} c="#44566C">
                    BackOrder_Code_#
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray.5" my={20} label="Нөөц захиалах бизнесийн мэдээлэл" />
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <DatePickerField
                  name="deliveryDate"
                  label="Хүлээн авах өдөр"
                  placeholder="Хүлээн авах өдөр сонгох"
                  required
                  rightSection={<IconCalendarTime stroke={0.6} />}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextareaField name="senderAdditionalNote" label="Хүргэлтийн нэмэлт тэмдэглэл" placeholder="Тэмдэглэл оруулна уу." maxRows={40} />
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              <Grid.Col span={12}>
                <Text fw={500} c="#44566C">
                  Багцлах захиалах мэдээлэл
                </Text>
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider color="gray.5" my={20} />
              </Grid.Col>

              {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_BCK_ORD").length > 0 && (
                <Grid.Col span={{ xs: 12, sm: 12, md: 12 }}>
                  <StackLines list={{ LinesData }} values={values} setFieldValue={setFieldValue} />
                </Grid.Col>
              )}
            </Grid>
          </FormLayout>
        );
      }}
    </Form>
  );
}
