import { Badge, Button, Divider, Flex, Grid, Group, Input, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { dateTimeSecFormat } from "../../utils/date";
import { Form } from "../form";
import { PageLayout } from "../layout";
import { SupplierDeliveryDistributingDetailLine } from "./line-list";

type initData = {
  name: string;
};

const schema = yup.object({});

export function SupplierDeliveryDistributingDetailForm({ action }: { action: any[] }) {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { deliveryNoteStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<initData>({
    name: "",
  });

  return (
    <Form validationSchema={schema} initialValues={data} onSubmit={() => {}}>
      {() => {
        return (
          <PageLayout
            title="Хүргэлтийн дэлгэрэнгүй"
            subTitle="Хүргэлтийн дэлгэрэнгүй мэдээлэл"
            breadcrumb={breadcrumbs}
            extra={[
              <Button
                onClick={() => {
                  navigate("/supplier-delivery");
                }}
                key={1}
                variant="default">
                Буцах
              </Button>,
            ]}>
            <Divider mb="md" />
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Код: ">
                  <Text fw={500} c="cyan">
                    {action[1]?.refCode || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Төлөв: ">
                  <br />
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(deliveryNoteStatus || []).find((c: any) => c.code === action[1]?.deliveryNoteStatus)?.color} 1px solid`,
                      color: `${(deliveryNoteStatus || []).find((c: any) => c.code === action[1]?.deliveryNoteStatus)?.color}`,
                    }}>
                    {(deliveryNoteStatus || []).find((c: any) => c?.code === action[1]?.deliveryNoteStatus)?.name || "-"}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Худалдан авагч бизнес: ">
                  <Text fw={500} c="cyan">
                    {action[1]?.order?.receiverBusiness?.refCode || "-"}, {action[1]?.order?.receiverBusiness?.profileName || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Захиалгын дугаар: ">
                  <Text fw={500} c="cyan">
                    {action[1]?.order?.salesCode || "-"}, {action[1]?.order?.purchaseCode || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_PS_LIST").length > 0 && (
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label={`Хүргэлтэнд хамаарах ачилтын тоо: ${action[1]?.pullSheets?.length || 0}`}>
                    <br />
                    {action[1]?.pullSheets?.length !== 0 ? (
                      <>
                        {(action[1]?.pullSheets || []).map((c: any, index: number) => {
                          return (
                            <Button
                              radius="lg"
                              variant="outline"
                              key={index}
                              style={{ height: 20, width: 105, marginLeft: 1, marginRight: 1 }}
                              onClick={() => {
                                navigate(`/supplier-shipping/detail/${c?.id}`);
                              }}>
                              <Text fw={600} c="cyan">
                                {c?.refCode || "-"}
                              </Text>
                            </Button>
                          );
                        })}
                      </>
                    ) : (
                      <Text fw={500} c="cyan">
                        {"-"}
                      </Text>
                    )}
                  </Input.Wrapper>
                </Grid.Col>
              )}
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Хүргэлт хариуцсан: ">
                  <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
                    <Group>
                      <Text fw={500} c="cyan">
                        {action[1]?.staff?.lastName !== null ? action[1]?.staff?.lastName[0] : "-"}.{" "}
                        {action[1]?.staff?.firstName !== null ? action[1]?.staff?.firstName : "-"}
                      </Text>
                      <Text c="dimmed" fz={14} fw={500}>
                        {`(`}
                        {action[1]?.staff?.phone || "-"}
                        {`)`}
                      </Text>
                    </Group>
                    <Text fw={500} c="cyan">
                      {action[1]?.staff?.email || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Хүргэлт зөвшөөрсөн хэрэглэгч: ">
                  <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
                    <Group>
                      <Text fw={500} c="cyan">
                        {action[1]?.regUser?.lastName !== null ? action[1]?.regUser?.lastName[0] : "-"}.{" "}
                        {action[1]?.regUser?.firstName !== null ? action[1]?.regUser?.firstName : "-"}
                      </Text>
                      <Text c="dimmed" fz={14} fw={500}>
                        {`(`}
                        {action[1]?.regUser?.phone || "-"}
                        {`)`}
                      </Text>
                    </Group>
                    <Text fw={500} c="cyan">
                      {action[1]?.regUser?.email || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Хүргэлт зөвшөөрсөн огноо, цаг: ">
                  <Text fw={500} c="cyan">
                    {dateTimeSecFormat(action[1]?.staffAssignedDate)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Хүргэлтэнд гаргасан: ">
                  <Text fw={500} c="cyan">
                    {dateTimeSecFormat(action[1]?.dispatchedDate)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Хүлээлгэж өгсөн: ">
                  <Text fw={500} c="cyan">
                    {dateTimeSecFormat(action[1]?.deliveredDate)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Хүргэх ёстой: ">
                  <Text fw={500} c="cyan">
                    {action[1]?.toDeliveryCount || 0}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Хүргэсэн: ">
                  <Text fw={500} c="cyan">
                    {action[1]?.deliveredCount || 0}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Хүлээж авсан: ">
                  {action[1]?.receiverStaff !== null ? (
                    <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
                      <Group>
                        <Text fw={500} c="cyan">
                          {action[1]?.receiverStaff?.lastName !== null ? action[1]?.receiverStaff?.lastName[0] : "-"}.{" "}
                          {action[1]?.receiverStaff?.firstName !== null ? action[1]?.receiverStaff?.firstName : "-"}
                        </Text>
                        <Text c="dimmed" fz={14} fw={500}>
                          {`(`}
                          {action[1]?.receiverStaff?.phone || "-"}
                          {`)`}
                        </Text>
                      </Group>
                      <Text fw={500} c="cyan">
                        {action[1]?.receiverStaff?.email || "-"}
                      </Text>
                    </Flex>
                  ) : (
                    <Text fw={500} c="cyan">
                      -
                    </Text>
                  )}
                </Input.Wrapper>
              </Grid.Col>
            </Grid>

            <Divider mt="md" />
            <SupplierDeliveryDistributingDetailLine list={action[1]?.lines || []} />
          </PageLayout>
        );
      }}
    </Form>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/supplier-delivery",
    label: "Хүргэлт удирдлага",
  },
  {
    label: "Хүргэлтийн дэлгэрэнгүй",
  },
];
