import { Group, Radio } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  onChange?: (value: string | null) => void;
  options: { value: string; label: string }[];
  spacing?: any;
  orientation?: "vertical" | "horizontal" | undefined;
  disabled?: boolean;
  withAsterisk?: boolean;
};

export function RadioGroupField({ name, label, onChange: onChangeValue, options = [], disabled = false, orientation, spacing, withAsterisk }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Radio.Group
      label={label}
      value={value}
      onChange={(value) => {
        onChange(value);
        onChangeValue && onChangeValue(value);
      }}
      error={error}
      withAsterisk={withAsterisk}
      required
      style={{ flex: 1 }}>
      <Group>
        {options.map((v: any, index: number) => (
          <Radio key={index} value={v.value} label={v.label} />
        ))}
      </Group>
    </Radio.Group>
  );
}
