import { Anchor, Avatar, Badge, ColorSwatch, Divider, Flex, Grid, Group, Paper, Select, Space, Table, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import {
  IconArrowDown,
  IconArrowUp,
  IconCalendar,
  IconCaretRight,
  IconCash,
  IconDatabase,
  IconPhoto,
  IconSquareRoundedArrowDown,
  IconSquareRoundedArrowUp,
  IconX,
} from "@tabler/icons-react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useScreenDetector } from "../../components/screen-detector";
import { IAuth } from "../../interfaces/IAuth";
import { IDashboard } from "../../interfaces/IDashboard";
import { IGeneral } from "../../interfaces/IGeneral";
import { IByInterval, IByPie, IByStatus, INumberSurvey, IRecentDelivered } from "../../models/Dashboard";
import { tugrug } from "../../utils";
import { dateFormat } from "../../utils/date";

export function DashboardReal({
  data,
  filter,
  setFilter,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  data: IDashboard;
  filter: any;
  setFilter: any;
  startDate: any;
  endDate: any;
  setStartDate: any;
  setEndDate: any;
}) {
  const navigate = useNavigate();
  const { classes, theme } = useStyles();
  const { isMobile } = useScreenDetector();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { dashboardPieFilters } = useSelector((state: { general: IGeneral }) => state.general);

  const getOptions = () => ({
    chart: {
      zoomType: "xy",
    },
    title: {
      text: "",
      align: "left",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xAxis: [
      {
        categories: (data?.byInterval || [])?.map((item: IByInterval, index: number) => {
          return item?.date;
        }),
        crosshair: true,
      },
    ],
    credits: {
      enabled: false,
    },
    yAxis: [
      {
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        title: {
          text: "",
          style: {
            color: "green",
          },
        },
      },
      {
        title: {
          text: "",
          style: {
            color: "black",
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "rigth",
      y: 18,
    },
    series: [
      {
        name: "Захиалгын дүн",
        color: "#15CAB8",
        type: "column",
        data: (data?.byInterval || []).map((item: IByInterval) => {
          return item?.orderAmount || 0;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Хүргэсэн дүн",
        type: "column",
        color: "#44A6E9",
        data: (data?.byInterval || []).map((item: IByInterval) => {
          return item?.deliveredAmount || 0;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Захиалгын тоо",
        type: "spline",
        color: "#FEC600",
        data: (data?.byInterval || []).map((item: IByInterval) => {
          return item?.orderCount || 0;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Хүргэсэн захиалгын тоо",
        type: "spline",
        color: "#FF8548",
        data: (data?.byInterval || []).map((item: IByInterval) => {
          return item?.deliveredCount || 0;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
    ],
  });

  const getPieOptions = () => ({
    title: {
      text: "",
      align: "center",
    },
    subtitle: {
      useHTML: true,
      text: "",
      floating: true,
      verticalAlign: "middle",
      y: 30,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 2,
      valueSuffix: " ₮",
    },

    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "80%",
        dataLabels: {
          enabled: false,
          crop: false,
          distance: "-10%",
          style: {
            fontWeight: "bold",
            fontSize: "16px",
          },
          connectorWidth: 0,
        },
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        data: (data?.byPie || []).map((i: IByPie) => ({
          name: i?.name,
          y: i?.amount,
          color: i?.color,
        })),
      },
    ],
  });

  return (
    <Grid>
      {(data?.numberSurvey || []).map((item: INumberSurvey, index: number) => {
        const DiffIcon = item.changedAmount < 0 ? IconArrowDown : IconArrowUp;

        return (
          <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 3 }} key={index}>
            <Paper withBorder key={index} radius="md">
              <Group p="sm" pb="xs">
                {index === 0 && <IconSquareRoundedArrowDown color={theme.colors.gray[6]} />}
                {index === 1 && <IconSquareRoundedArrowUp color={theme.colors.gray[6]} />}
                {index === 2 && <IconDatabase color={theme.colors.gray[6]} />}
                {index === 3 && <IconCash color={theme.colors.gray[6]} />}
                <Text fz="sm" fw={600} color={theme.colors.gray[6]}>
                  {item.name || "-"}
                </Text>
              </Group>
              <Group>
                <div className={classes.grdnt}></div>
                <Space />
                <Text fz="xl" fw={500}>
                  {tugrug(item.amount || 0)}
                </Text>
              </Group>
              <Group align="right" p="sm">
                <Text fz={13} c={theme.colors.gray[6]} fw={500}>
                  Өмнөх өдрөөс
                </Text>
                <Badge color={item.changedAmount < 0 ? "red" : "green"} className={item.changedAmount < 0 ? classes.red : classes.green}>
                  <Flex align="center" gap={8}>
                    <DiffIcon size="0.8rem" stroke={3} />
                    <Text>
                      {item.changedAmount < 0 && "-"}
                      {Math.abs(item.changedAmount || 0)} %
                    </Text>
                  </Flex>
                </Badge>
              </Group>
            </Paper>
          </Grid.Col>
        );
      })}

      <Grid.Col>
        <Paper withBorder py="xs">
          <Flex justify="space-between">
            <Group>
              <div className={classes.grdnt}></div>
              <Text component="h1">Борлуулалтын захиалга</Text>
            </Group>
            <Group>
              <DatePickerInput
                type="range"
                style={{ borderRadius: "5px" }}
                variant="filled"
                maw="300px"
                w="100%"
                bg={theme.colors.blue[0]}
                placeholder="Огноо хайх"
                leftSection={<IconCalendar size={"1.2rem"} />}
                valueFormat="YYYY-MM-DD"
                value={[startDate, endDate]}
                onChange={(e) => {
                  if (e[0] && e[1]) {
                    setStartDate(e[0] && e[0]);
                    setEndDate(e[1] && e[1]);
                  }
                }}
                rightSection={
                  <IconX
                    stroke={0.8}
                    size={15}
                    onClick={() => {
                      setStartDate("");
                      setEndDate("");
                    }}
                  />
                }
                mr={20}
              />
            </Group>
          </Flex>
          <Paper px="lg">
            <HighchartsReact highcharts={Highcharts} options={getOptions()} />
            <Space h={20} />
            <Group align="right" style={{ marginTop: "-24px" }}>
              <Text fz={"sm"} c="dimmed" bg={"white"} style={{ zIndex: 2 }}>
                Хэмжих нэгж: Сая төгрөгөөр
              </Text>
            </Group>
            <Space h="xs" />
          </Paper>
        </Paper>
      </Grid.Col>

      <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 6 }}>
        <Paper withBorder py="4px">
          <Group align="apart">
            <Group align="center">
              <div className={classes.grdnt}></div>
              <Text component="h1">Борлуулалтын захилга</Text>
            </Group>
            {!isMobile ? (
              <Anchor
                size="sm"
                px="xs"
                fw={500}
                onClick={() => {
                  user?.currentBusiness?.type === "SUPPLIER" ? navigate("/supplier-sales") : navigate("/buyer-sales");
                }}>
                Бүгдийг харах
              </Anchor>
            ) : (
              <Anchor
                px="xs"
                onClick={() => {
                  user?.currentBusiness?.type === "SUPPLIER" ? navigate("/supplier-sales") : navigate("/buyer-sales");
                }}>
                <IconCaretRight />
              </Anchor>
            )}
          </Group>
          <Paper px="lg" style={{ overflow: "auto" }}>
            <Table miw="max-content">
              <thead>
                <tr>
                  <th>Захиалгын төлөв</th>
                  <th>Захиалгын тоо</th>
                  <th>Захиалгын дүн</th>
                  <th>Харилцагчийн тоо</th>
                  <th>Дундаж дүн</th>
                </tr>
              </thead>
              <tbody>
                {(data?.byStatus || []).map((item: IByStatus, index: number) => (
                  <tr key={index}>
                    <td>
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        style={{ background: "white", color: `${item?.color}`, border: `1px solid ${item?.color}` }}>
                        {item.name}
                      </Badge>
                    </td>
                    <td>
                      <Text size="sm" fw={500} c="#44566C">
                        {item.orderCount}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" fw={500} c="#44566C">
                        {tugrug(item.orderAmount || 0)}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" fw={500} c="#44566C">
                        {item.businessCount}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" fw={500} c="#44566C">
                        {tugrug(item.averageAmount || 0)}
                      </Text>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Paper>
        </Paper>
      </Grid.Col>

      <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 3 }}>
        <Paper p="sm" withBorder>
          <Text fz="lg" fw={600}>
            Сүүлд хүргэсэн
          </Text>
          <Divider my="xs" />
          <Flex direction="column" gap="sm">
            {(data?.recentDelivered || []).map((c: IRecentDelivered, index: number) => {
              return (
                <Group key={index} align="start" style={{ background: "#F8F9FA" }}>
                  <Group ml={5}>
                    <Avatar src={c?.receiverBusiness?.logo}>
                      <IconPhoto />
                    </Avatar>
                    <div>
                      <Text fw={500} c="#14182d">
                        {c?.receiverBusiness?.profileName}
                      </Text>
                      <Text c="#557d94" fw={500} size="sm">
                        {c?.receiverBusiness?.refCode}
                      </Text>
                    </div>
                  </Group>
                  <Flex justify="flex-end" align="flex-end" direction="column" wrap="nowrap" mr={5}>
                    <Text fw={500} c="#557d94">
                      {dateFormat(c?.deliveredDate)}
                    </Text>
                    <Text c="#14182d" fw={500} size="sm">
                      {tugrug(c?.orderAmount || 0)}
                    </Text>
                  </Flex>
                </Group>
              );
            })}
          </Flex>
          {user?.currentBusiness?.type === "SUPPLIER" && (
            <Group align="right" mt={5}>
              {!isMobile ? (
                <Anchor size="sm" px="xs" fw={500} onClick={() => navigate("/supplier-delivery")}>
                  Бүгдийг харах
                </Anchor>
              ) : (
                <Anchor px="xs" onClick={() => navigate("/supplier-delivery")}>
                  <IconCaretRight />
                </Anchor>
              )}
            </Group>
          )}
        </Paper>
      </Grid.Col>

      <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 6 }}>
        <Paper withBorder py="4px">
          <Group align="apart">
            <Group align="center">
              <div className={classes.grdnt}></div>
              <Text component="h1">Борлуулалтын захилга</Text>
            </Group>
            <Select
              mr={15}
              value={filter}
              data={(dashboardPieFilters || []).map((i) => {
                return {
                  label: i?.name,
                  value: i?.code,
                };
              })}
              placeholder="сонгох"
              onChange={(e: string | null) => {
                setFilter(e);
              }}
            />
          </Group>
          <Flex justify="space-around" align="center">
            <Group w="100%">
              {data?.byPieAmount !== 0 ? (
                <HighchartsReact highcharts={Highcharts} options={getPieOptions()} />
              ) : (
                <HighchartsReact highcharts={Highcharts} options={getPieOptions()} />
              )}
            </Group>
            <Flex direction="column" gap="sm" w="100%" mr={15}>
              {(data?.byPie || []).map((c: IByPie, index: number) => {
                return (
                  <Group align="apart" key={index} ml={40}>
                    <Group ml={5}>
                      <ColorSwatch color={c.color} size={6} />
                      <div>
                        <Text fw={500} color="#14182d">
                          {c?.name || "-"}
                        </Text>
                        <Text c="#557d94" fw={500} size="sm">
                          {c?.ordersCount}
                        </Text>
                      </div>
                    </Group>
                    <Flex mr={5}>
                      <Text fw={500} color="#557d94">
                        {tugrug(c?.amount || 0)}
                      </Text>
                    </Flex>
                  </Group>
                );
              })}
            </Flex>
          </Flex>
        </Paper>
      </Grid.Col>
    </Grid>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  grdnt: {
    background: theme.colors.cyan[4],
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));
