import { Center, LoadingOverlay, Pagination, Table, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconDatabaseOff } from "@tabler/icons-react";
import qs from "qs";
import React, { useImperativeHandle } from "react";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";
export * from "../table/row-action";

type Props = {
  name: string;
  tbody: (data: any[]) => JSX.Element;
  thead: JSX.Element;
  filters?: { [key: string]: string | number };
  pagination?: boolean;
  dataSource?: any[];
  loadData?: (filter?: IFilter) => Promise<any>;
  limit?: number;
  onResult?: any;
};

export type ITableRef = { reload: () => void };

function CustomTable({ name, filters = {}, pagination = true, loadData, limit = 20, onResult, thead, tbody }: Props, ref: React.Ref<ITableRef>) {
  const { classes } = useStyles();
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const tableRef = React.useRef<HTMLTableElement>(null);
  const [offset, setOffset] = React.useState({ page: 1, limit: limit || 20 });
  const [fallbackData, setFallbackData] = React.useState<{
    count: number;
    rows: any[];
  }>({ count: 0, rows: [] });

  const { data, mutate, isLoading } = useSwr(
    `table.${name}.[${offset.page}, ${offset.limit}]?${qs.stringify(filters)}`,
    async () => {
      if (loadData) {
        let res = await loadData({ offset: offset, filter: filters } as IFilter);
        setFallbackData(res);
        return res;
      }
      return { count: 0, rows: [] };
    },
    {
      fallbackData: fallbackData,
      onSuccess: (data) => {
        onResult && onResult(data);
        if (scrollRef.current) {
          scrollRef.current.scrollTo({ top: 0 });
        }
      },
    },
  );

  useImperativeHandle(ref, () => ({
    reload() {
      return mutate();
    },
  }));

  return (
    <div className={classes.container}>
      <div ref={scrollRef} onScroll={() => {}} className={classes.scroll}>
        <Table
          ref={tableRef}
          style={{
            minWidth: 1000,
          }}>
          {thead}
          {data?.rows?.length > 0 && tbody(data.rows)}
        </Table>

        {data?.count === 0 && (
          <Center sx={(theme) => ({ flexDirection: "column", gap: 10, padding: theme.spacing.xl })}>
            <IconDatabaseOff size={36} stroke={1.5} />
            <Text fw={500} ta="center">
              Мэдээлэл олдсонгүй
            </Text>
          </Center>
        )}

        <LoadingOverlay visible={isLoading} />

        {pagination && (
          <div className={classes.pagination}>
            <Pagination
              onChange={(page) => setOffset((state) => ({ ...state, page: page }))}
              total={data.count / offset.limit + (data.count % offset.limit > 0 ? 1 : 0)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const CustomTableComponent = React.forwardRef(CustomTable);

export { CustomTableComponent as CustomsTable };

const useStyles = createStyles((theme) => ({
  container: {
    position: "relative",
    minWidth: "100%",
  },
  scroll: {
    overflowX: "auto",
  },

  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  child: {
    display: "flex",
  },
  icon: {
    display: "flex",
  },

  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "15px 0",
  },
  table: {
    minWidth: 1000,
  },
  th: {
    padding: "8px!important",
    lineHeight: "14px",
    whiteSpace: "nowrap",
    "&:last-child": {
      textAlign: "center",
    },
  },
  td: {
    padding: "8px!important",
  },
  control: {
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));
