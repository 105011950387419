import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get(`/ord/pull_sheet`, { filter, offset });
};

export const get = async (id: string) => {
  return httpRequest.get(`/ord/pull_sheet/${id}`);
};

export const create = async (data: { warehouseId: string; loadingDate: any; deliveryNoteIds: any }) => {
  return httpRequest.post(`/ord/pull_sheet`, data);
};
