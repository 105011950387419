/* eslint-disable react-hooks/exhaustive-deps */
import { ActionIcon, Avatar, Button, CloseButton, Divider, Flex, Group, LoadingOverlay, Text } from "@mantine/core";
import { IconPhoto, IconTrash } from "@tabler/icons-react";
import React from "react";
import * as yup from "yup";
import { DropShippingApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { PageLayout } from "../layout";
import { ColumnType, Table } from "../table";

const schema = yup.object({
  lines: yup.array().of(
    yup.object({
      quantity: yup.string().required("Заавал бөглөнө!").nullable(),
      buyerBusinessId: yup.string().required("Заавал бөглөнө!").nullable(),
      supplierBusinessId: yup.string().required("Заавал бөглөнө!").nullable(),
    }),
  ),
});

export type IFormData = {
  buyerBusinessId: string;
};

export function SupplierSalesShippingForm({
  action,
  isDropshipping,
  onCancel,
  onSuccess,
}: {
  action: string;
  isDropshipping: any;
  onCancel: () => void;
  onSuccess: () => void;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data] = React.useState<IFormData>({
    lines: [],
    ...(isDropshipping && isDropshipping[0] === "successDropping"
      ? {
          ...isDropshipping[1],
          lines: (isDropshipping[1] || []).map((item: any) => ({
            quantity: item?.quantity,
            variantId: item?.variantId,
            buyerBusinessId: (item?.buyers || []).find((item: any) => item.isDefault)?.id,
            supplierBusinessId: (item?.suppliers || []).find((item: any) => item.isDefault)?.id,
          })),
        }
      : {}),
  });

  const onRemoveRow = (row: any, index: number, values: any, setFieldValue: (name: string, value: any) => void) => {
    setFieldValue("lines", [...values.filter((i: any, ind: number) => ind !== index)]);
  };

  const columns = (values: any, setFieldValue: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useHeader({
      values,
      setFieldValue,
      onRemoveRow,
    });
  };

  const onSubmit = async (value: any) => {
    setLoading(true);
    try {
      await DropShippingApi.create({
        orderId: action,
        lines: (value?.lines || []).map((item: { variantId: string; buyerBusinessId: string; supplierBusinessId: string; quantity: string }) => ({
          variantId: item.variantId,
          buyerBusinessId: item.buyerBusinessId,
          supplierBusinessId: item.supplierBusinessId,
        })),
      });
      message.success("Таны хүсэлт амжилттай");
      onSuccess();
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue, errors }) => {
        return (
          <PageLayout
            title="Нийлүүлэгчид хүргэх барааг захиалгаас салгах"
            extra={[
              <CloseButton
                onClick={() => {
                  onCancel && onCancel();
                }}
                type="submit"
                key={1}></CloseButton>,
            ]}>
            <LoadingOverlay visible={loading} />
            <Divider color="gray" my="xs" />
            <Table columns={columns(values, setFieldValue)} name="supplier-sales-shipping-form.list" dataSource={isDropshipping[1] || []} pagination={false} />
            <Divider color="gray" my="xs" />
            <Flex direction="row" justify="space-between" mt={20}>
              <Button variant="outline" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button variant="filled" type="submit">
                Захиалга салгах
              </Button>
            </Flex>
          </PageLayout>
        );
      }}
    </Form>
  );
}

const useHeader = ({
  values,
  setFieldValue,
  onRemoveRow,
}: {
  values: any;
  setFieldValue: any;
  onRemoveRow: (row: any, index: number, values: any, setFieldValue: (name: string, value: any) => void) => void;
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "item.image",
    width: 60,
    render: (record) => (
      <Group>
        <Avatar size={40} src={record?.image !== null ? record?.image : noimage} radius={5}>
          <IconPhoto />
        </Avatar>
      </Group>
    ),
  },

  {
    title: "Kод",
    sorter: true,
    dataIndex: "refCode",
    width: 140,
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    width: 140,
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.skuCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "refCode",
    width: 200,
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unit",
    width: 140,
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.unit || "-"}
        </Text>
      );
    },
  },
  {
    title: "Захиалгын тоо",
    sorter: true,
    dataIndex: "quantity",
    width: 140,
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.quantity || "-"}
        </Text>
      );
    },
  },
  {
    title: "Худалдан авагчийн нэр",
    sorter: true,
    dataIndex: "balance",
    width: 140,
    render: (record, index) => {
      return (
        <SelectField
          size="xs"
          key={`lines[${index}].buyerBusinessId`}
          name={`lines[${index}].buyerBusinessId`}
          placeholder="сонгох"
          options={(record?.buyers || []).map((item: any) => {
            return {
              value: item?.id,
              label: item?.profileName,
            };
          })}
          onChange={() => {
            setFieldValue(`lines[${index}].supplierBusinessId`, undefined);
          }}
        />
      );
    },
  },
  {
    title: "Нийлүүлэгчийн нэр",
    sorter: true,
    dataIndex: "supplier_name",
    width: 140,
    render: (record, index) => {
      return (
        <>
          <SelectField
            size="xs"
            key={`lines[${index}].supplierBusinessId`}
            name={`lines[${index}].supplierBusinessId`}
            placeholder="сонгох"
            options={(record?.suppliers || [])
              .filter((item: any) => item?.buyerBusinessId === values?.lines[index]?.buyerBusinessId)
              .map((item: any) => {
                return {
                  value: item?.id,
                  label: item?.profileName,
                };
              })}
            disabled={!values?.lines[index]?.buyerBusinessId}
          />
        </>
      );
    },
  },
  {
    title: "Нийлүүлэгчийн үлдэгдэл",
    sorter: true,
    dataIndex: "quantity",
    width: 140,
    render: (record, index) => {
      return (
        <>
          {(record?.suppliers || []).find((item: any) => item.id === values?.lines[index]?.supplierBusinessId)?.availableQuantity >= record?.quantity ? (
            <Text size="sm" fw={500} c="#37B24D">
              {"Боломжтой"}
            </Text>
          ) : (
            <Text size="sm" fw={500} c="#FA5252">
              {"Боломжихгүй"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record, index) => {
      return (
        <>
          <Flex gap={8} align="center" w={"max-content"}>
            <ActionIcon onClick={() => onRemoveRow(record, index, values?.additionalUnits, setFieldValue)} color="red">
              <IconTrash />
            </ActionIcon>
          </Flex>
        </>
      );
    },
  },
];
