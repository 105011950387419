import { ActionIcon, Flex, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconBrandFacebook, IconBrandInstagram, IconBrandTwitter } from "@tabler/icons-react";
import { RegisterHeader } from "./register-header";

export const Layout = ({ children }: { children: any }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <RegisterHeader />
        <div className={classes.child}>{children}</div>
      </div>
      <div className={classes.footerText}>
        <Flex gap={8} align={"center"} justify="space-between" mt={"lg"} mb="md">
          <Text c="dimmed">© {new Date().getFullYear()} Дижитал Эра Пэймэнт</Text>
          <Flex gap={8} align={"center"}>
            <ActionIcon>
              <IconBrandTwitter />
            </ActionIcon>
            <ActionIcon>
              <IconBrandFacebook />
            </ActionIcon>
            <ActionIcon>
              <IconBrandInstagram />
            </ActionIcon>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  child: {
    position: "relative",
  },
  body: {
    flex: 1,
  },
  footerText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 30,
    fontSize: 20,
    color: "white",
  },
}));
export default Layout;
