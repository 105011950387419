import { useParams } from "react-router";
import { OrderApi } from "../../apis";
import useSwr from "swr";
import { LoadingOverlay } from "@mantine/core";
import { BuyerSalesForm } from "../../components/buyer-sales/form";

export function BuyerSalesEdit() {
  const params = useParams();

  const { data, isLoading } = useSwr(`/ord/order/buyer/get.${params.id}`, async () => await OrderApi.get(params.id as string), {
    fallback: { data: null },
  });

  return (
    <>
      {data && (
        <>
          <LoadingOverlay visible={isLoading} />
          <BuyerSalesForm action={["edit", data]} />
        </>
      )}
    </>
  );
}
