import { DatePickerInput } from "@mantine/dates";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  rightSection?: React.ReactNode;
  onChange?: (value: string | null) => void;
  nullable?: boolean;
  required?: boolean;
  minDate?: any;
  maxDate?: any;
};

export function DatePickerField({ name, label, placeholder, disabled = false, rightSection, nullable, required, minDate, maxDate }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <DatePickerInput
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      valueFormat="MM-DD-YYYY"
      error={error}
      clearable={false}
      rightSection={rightSection}
      value={value ? new Date(value) : null}
      onChange={(e: Date | null) => {
        if (e) onChange(`${new Date(e)}`);
      }}
      required={required}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
}
