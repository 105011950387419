import { showNotification } from "@mantine/notifications";

const success = (message: string) => {
  showNotification({
    color: "green",
    title: "Амжилттай!",
    message,
  });
};

const error = (message: string) => {
  showNotification({
    color: "red",
    title: "Алдаа гарлаа!",
    message,
  });
};

export const message = {
  success,
  error,
};
