import { Button, CloseButton, Divider, Flex, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { OrderApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { PageLayout } from "../layout";

const schema = yup.object();

export type IFormData = {
  buyerBusinessId: string;
};

export function SupplierSalesSplitForm({
  isDropshipping,
  onCancel,
  id,
  onSuccess,
}: {
  isDropshipping: any;
  onCancel: () => void;
  id: string;
  onSuccess: () => void;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = async () => {
    setLoading(true);

    try {
      await OrderApi.split(
        id,
        (isDropshipping[1] || [])?.map((item: any) => ({
          id: item?.id,
        })),
      );

      message.success("Таны хүсэлт амжилттай.");
      onSuccess();
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={{}}>
      {() => {
        return (
          <PageLayout
            title="Захиалга салгах"
            extra={[
              <CloseButton
                onClick={() => {
                  onCancel && onCancel();
                }}
                key={1}></CloseButton>,
            ]}>
            <LoadingOverlay visible={loading} />
            <Divider color="gray" mb="xs" />
            <Text
              size="sm"
              fw={500}
              c="#44566C"
              style={{
                textAlign: "justify",
                letterSpacing: "1px",
                textIndent: "20px",
              }}>
              Таны сонгосон <> </>
              {(isDropshipping[1] || []).some((item: any) => !item.isDropshipping) && (
                <>
                  SKU: <> </>
                  {(isDropshipping[1] || [])
                    .filter((item: any) => !item.isDropshipping)
                    .map((item: any, index: number) => (
                      <span key={index}>{item?.skuCode} </span>
                    ))}
                  бүтээгдэхүүний захиалгыг салгах гэж байна итгэлтэй байна уу! <> </>
                </>
              )}
            </Text>
            <Divider color="gray" my="xs" />
            <Flex direction="row" justify="space-between" mt={20}>
              <Button variant="outline" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button variant="filled" type="submit">
                Захиалга салгах
              </Button>
            </Flex>
          </PageLayout>
        );
      }}
    </Form>
  );
}
