import { ActionIcon, Alert, Badge, Box, Button, Collapse, Flex, Grid, Group, Input, Modal, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useColorScheme } from "@mantine/hooks";
import { IconInfoCircle, IconMap2, IconSquareArrowDown, IconSquareArrowUp } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { dateTimeSecFormat } from "../../utils/date";
import { Form, IFormRef } from "../form";
import { PageLayout } from "../layout";
import { SupplierSalesDynamicDetailForm } from "./dynamic-detail-form";
import { SupplierSalesDynamicReviewForm } from "./review-form";
import { SupplierSalesShippingForm } from "./shipping-form";
import { SupplierSalesSplitForm } from "./split-form";

const schema = yup.object({});

export type IFormData = {
  regNumber: string;
};

export function SupplierSalesDetailForm({ action, reload }: { action: any; reload?: any }) {
  const breadcrumbs = useBreadcrumb();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const ref = useRef<IFormRef<any>>(null);
  const [actions, setActions] = React.useState<string[]>([]);
  const [collapseOpen, setCollapseOpen] = React.useState(true);
  const [collapsedOpen, setCollapsedOpen] = React.useState(true);
  const [checked, setChecked] = React.useState<string[]>([]);
  const [isDropshipping, setIsDropshipping] = React.useState<string[]>([]);

  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { orderStatus, deliveryDateTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IFormData>({
    regNumber: "",
  });

  const staticDay = [
    {
      id: "1",
      date: "2021-12-28",
      day: "Мягмар",
    },
    {
      id: "2",
      date: "2021-12-29",
      day: "Лхагва",
    },
    {
      id: "3",
      date: "2021-12-30",
      day: "Пүрэв",
    },
    {
      id: "4",
      date: "2021-12-31",
      day: "Баасан",
    },
    {
      id: "5",
      date: "2022-01-01",
      day: "Бямба",
    },
    {
      id: "6",
      date: "2022-01-02",
      day: "Ням",
    },
  ];

  const onSubmit = (value: any) => {
    if ((value || []).some((item: any) => !item.isDropshipping)) {
      setIsDropshipping(["unSuccessDropping", value as any]);
    } else {
      setIsDropshipping(["successDropping", value as any]);
    }
  };

  const onCancel = async () => {
    setIsDropshipping([]);
    setChecked([]);
  };

  const onSplit = (value: any) => {
    if ((checked || []).some((item: any) => item.isSplit || item.isDropshipping || item.isDropped || item.isLoadable)) {
      setIsDropshipping(["unSuccessSplit", value as any]);
    } else {
      setIsDropshipping(["successSplit", value as any]);
    }
  };

  return (
    <>
      <Form ref={ref} validationSchema={schema} onSubmit={() => {}} initialValues={data}>
        {({ values }) => {
          return (
            <PageLayout
              title="Захиалгын мэдээлэл"
              subTitle="Гэрээт харилцагчийн захиалгын дэлгэрэнгүй мэдээлэл"
              breadcrumb={breadcrumbs}
              extra={[
                <Button onClick={() => navigate("/supplier-sales")} variant="outline" key="cancel">
                  Буцах
                </Button>,
                <>
                  {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isReview).length > 0 && (
                    <span key="review">
                      {action[1]?.salesType !== "SPLIT" && (
                        <Button
                          onClick={() => setActions(["review"])}
                          disabled={action[1]?.orderStatus === "REGISTERED" && user?.currentBusiness?.id === action[1]?.senderBusiness?.id ? false : true}>
                          Хянах
                        </Button>
                      )}
                    </span>
                  )}
                </>,
              ]}>
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 1,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex mih={10} gap="md" justify="space-between" align="center" direction="row" wrap="wrap">
                  <Group>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      style={{
                        border: `${orderStatus.find((c: any) => c.code === action[1].orderStatus)?.color} 1px solid`,
                        color: `${orderStatus.find((c: any) => c.code === action[1].orderStatus)?.color}`,
                      }}>
                      {orderStatus.find((c: any) => c.code === action[1].orderStatus)?.sentName || "-"}
                    </Badge>
                    <Text size="sm" fw={700} c="cyan">
                      {(action && action[1]?.salesCode) || "-"}
                    </Text>
                  </Group>
                  <Group>
                    <div>
                      <Group>
                        <Text size="sm" fw={500} c="#44566C">
                          {"бүртгэсэн"}:
                        </Text>
                        <Text size="sm" fw={600} c="#44566C">
                          {action && action[1]?.orderedUser?.lastName !== null ? action[1]?.orderedUser?.lastName[0] : "-"}.{" "}
                          {action[1]?.orderedUser?.firstName !== null ? action[1]?.orderedUser?.firstName : "-"}
                        </Text>
                      </Group>
                      <Flex gap={5} wrap="nowrap" justify="flex-end">
                        <Text size="sm" fw={500} c="cyan">
                          {dateTimeSecFormat(action[1]?.createdAt)}
                        </Text>
                      </Flex>
                    </div>
                  </Group>
                </Flex>
              </Box>
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 1,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                  <Text fw={500}>{"ХАРИЛЦАГЧИЙН МЭДЭЭЛЭЛ"}</Text>
                  <ActionIcon variant="transparent">
                    {!collapseOpen ? (
                      <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapseOpen(true)} />
                    ) : (
                      <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapseOpen(false)} />
                    )}
                  </ActionIcon>
                </Flex>
              </Box>

              <Collapse in={collapseOpen}>
                <Box
                  sx={(theme) => ({
                    background: theme.white,
                    borderRadius: 1,
                    border: `1px solid ${theme.colors.gray[2]}`,
                    padding: "20px 20px",
                  })}
                  mb={5}>
                  <Grid>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Партнерийн нэр:">
                        <Text fw={500} c="cyan" mt={5}>
                          {action[1]?.receiverBusiness?.partner?.refCode || action[1]?.receiverBusiness?.partner?.businessName
                            ? [action[1]?.receiverBusiness?.partner?.refCode, " ", action[1]?.receiverBusiness?.partner?.businessName]
                            : "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Нийлүүлэгчийн нэр:">
                        <Text fw={500} c="cyan" mt={5}>
                          {action[1]?.receiverBusiness?.refCode || action[1]?.receiverBusiness?.profileName
                            ? [action[1]?.receiverBusiness?.refCode, " ", action[1]?.receiverBusiness?.profileName]
                            : "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                        <Text fw={500} c="cyan" mt={5}>
                          {action[1]?.receiverBusiness?.regNumber || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Төлбөрийн нөхцөл:">
                        <Text fw={500} c="cyan">
                          {action[1]?.paymentTerm?.description || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Нийлүүлэгчийн менежер:">
                        <Text fw={500} c="cyan">
                          {action[1]?.supplierStaff
                            ? action[1]?.supplierStaff?.lastName || action[1]?.supplierStaff?.firstName
                              ? [action[1]?.supplierStaff?.lastName, " ", action[1]?.supplierStaff?.firstName]
                              : "-"
                            : "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Худалдан авагчийн менежер:">
                        <Text fw={500} c="cyan">
                          {action[1]?.buyerStaff
                            ? action[1]?.buyerStaff?.lastName || action[1]?.buyerStaff?.firstName
                              ? [action[1]?.buyerStaff?.lastName, " ", action[1]?.buyerStaff?.firstName]
                              : "-"
                            : "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах ажилтан:">
                        <Text fw={500} c="cyan">
                          {action[1]?.receiverStaff
                            ? action[1]?.receiverStaff?.lastName || action[1]?.receiverStaff?.firstName
                              ? [action[1]?.receiverStaff?.lastName, " ", action[1]?.receiverStaff?.firstName]
                              : "-"
                            : "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах салбар:">
                        <Text fw={500} c="cyan">
                          {action[1]?.receiverBranch?.name || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах хаяг:">
                        <Group>
                          <ActionIcon variant="transparent">
                            <IconMap2 color="#21bbdd" />
                          </ActionIcon>
                          {action[1]?.receiverBranch ? (
                            action[1]?.receiverBranch?.branchAddress ? (
                              <Text fw={500} c="cyan">
                                {action[1]?.receiverBranch?.branchAddress}
                              </Text>
                            ) : (
                              "-"
                            )
                          ) : (
                            "-"
                          )}
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Захиалгыг хүлээн авах:">
                        <Text fw={500} c="cyan">
                          {deliveryDateTypes.find((c: any) => c?.code === action[1]?.deliveryType)?.name || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хамгийн ойрхон хүргэх боломжтой хуваарьт өдөр:">
                        {values?.deliveryType === "DEFAULT_DATE" ? (
                          <Grid mt={5}>
                            {(staticDay || [])?.map((item: any, index: any) => {
                              return (
                                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} key={index}>
                                  <UnstyledButton className={classes.item}>
                                    <Flex justify="center" align="center" direction="column" wrap="nowrap">
                                      <Text size="sm" fw={500} c="#44566C">
                                        {item?.date}
                                      </Text>
                                      <Text size="sm" fw={500} c="#44566C">
                                        {item?.day}
                                      </Text>
                                    </Flex>
                                  </UnstyledButton>
                                </Grid.Col>
                              );
                            })}
                          </Grid>
                        ) : (
                          <Text fw={500} c="cyan">
                            {dateTimeSecFormat(action[1]?.deliveryDate)}
                          </Text>
                        )}
                      </Input.Wrapper>
                    </Grid.Col>
                  </Grid>
                </Box>
              </Collapse>

              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 1,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                  <Text fw={500}>{"ЗАХИАЛГЫН ЗҮЙЛС"}</Text>
                  <ActionIcon variant="transparent">
                    {!collapsedOpen ? (
                      <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapsedOpen(true)} />
                    ) : (
                      <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapsedOpen(false)} />
                    )}
                  </ActionIcon>
                </Flex>
              </Box>

              <Collapse in={collapsedOpen}>
                <SupplierSalesDynamicDetailForm action={action} checked={checked} setChecked={setChecked} onSubmit={onSubmit} onSplit={onSplit} />
              </Collapse>
            </PageLayout>
          );
        }}
      </Form>
      <Modal
        opened={actions[0] === "review"}
        onClose={() => {
          setActions([]);
        }}
        withCloseButton={false}
        size="600px"
        padding={0}
        centered>
        <SupplierSalesDynamicReviewForm action={action && action[1]} setActions={setActions} reviewed={"reviewed"} />
      </Modal>
      <Modal opened={isDropshipping[0] === "unSuccessDropping"} onClose={() => setIsDropshipping([])} withCloseButton={false} size="lg" padding={0} centered>
        <Alert variant="light" color="yellow" title="Санамж" icon={<IconInfoCircle />}>
          <Text
            size="sm"
            fw={500}
            c="#44566C"
            style={{
              textAlign: "justify",
              letterSpacing: "1px",
              textIndent: "20px",
            }}>
            Таны сонгосон бүтээгдэхүүний <> </>
            {(checked || []).some((item: any) => !item.isDropshipping) && (
              <>
                SKU: <> </>
                {(checked || [])
                  .filter((item: any) => !item.isDropshipping)
                  .map((item: any, index: number) => (
                    <span key={index}>{item?.skuCode} </span>
                  ))}
                нийлүүлэгч хүргэх боломжгүй байна. <> </>
              </>
            )}
            {(checked || []).some((item: any) => item.isDropped) && (
              <>
                SKU: <> </>
                {(checked || [])
                  .filter((item: any) => item.isDropped)
                  .map((item: any, index: number) => (
                    <span key={index}>{item?.skuCode} </span>
                  ))}
                кодтой барааны хүргэлтийг нийлүүлэгч рүү шилжүүлсэн байна.
              </>
            )}
            {(checked || []).some((item: any) => item.isConfirmed) && (
              <>
                SKU: <> </>
                {(checked || [])
                  .filter((item: any) => item.isConfirmed)
                  .map((item: any, index: number) => (
                    <span key={index}>{item?.skuCode} </span>
                  ))}
                кодтой барааг түгээлтэд илгээсэн байна.
              </>
            )}
            {(checked || []).some((item: any) => item.isSplit) && (
              <>
                SKU: <> </>
                {(checked || [])
                  .filter((item: any) => item.isSplit)
                  .map((item: any, index: number) => (
                    <span key={index}>{item?.skuCode} </span>
                  ))}
                барааг захиалгаас салгаж тусад нь хүргэлтэнд хувиалсан байна.
              </>
            )}
          </Text>
        </Alert>
      </Modal>
      <Modal
        opened={isDropshipping[0] === "successDropping"}
        onClose={() => {
          setIsDropshipping([]);
        }}
        withCloseButton={false}
        size="60%"
        padding={0}
        centered>
        <SupplierSalesShippingForm
          action={action[1].id}
          isDropshipping={isDropshipping}
          onCancel={onCancel}
          onSuccess={async () => {
            await reload();
            onCancel && onCancel();
          }}
        />
      </Modal>

      <Modal
        opened={isDropshipping[0] === "unSuccessSplit"}
        onClose={() => {
          setIsDropshipping([]);
        }}
        withCloseButton={false}
        size="lg"
        padding={0}
        centered>
        <Alert variant="light" color="yellow" title="Санамж" icon={<IconInfoCircle />}>
          <Text
            size="sm"
            fw={500}
            c="#44566C"
            style={{
              textAlign: "justify",
              letterSpacing: "1px",
              textIndent: "20px",
            }}>
            Таны сонгосон бүтээгдэхүүний <> </>
            {(checked || []).some((item: any) => item.isDropshipping) && (
              <>
                SKU: <> </>
                {(checked || [])
                  .filter((item: any) => item.isDropshipping)
                  .map((item: any, index: number) => (
                    <span key={index}>{item?.skuCode} </span>
                  ))}
                захиалга салгах боломжгүй байна. <> </>
              </>
            )}
            {(checked || []).some((item: any) => item.isDropped) && (
              <>
                SKU: <> </>
                {(checked || [])
                  .filter((item: any) => item.isDropped)
                  .map((item: any, index: number) => (
                    <span key={index}>{item?.skuCode} </span>
                  ))}
                кодтой барааны хүргэлтийг нийлүүлэгч рүү шилжүүлсэн байна.
              </>
            )}
            {(checked || []).some((item: any) => item.isSplit) && (
              <>
                SKU: <> </>
                {(checked || [])
                  .filter((item: any) => item.isSplit)
                  .map((item: any, index: number) => (
                    <span key={index}>{item?.skuCode} </span>
                  ))}
                барааг захиалгаас салгаж тусад нь хүргэлтэнд хувиалсан байна.
              </>
            )}
            {(checked || []).some((item: any) => item.isLoadable) && (
              <>
                SKU: <> </>
                {(checked || [])
                  .filter((item: any) => item.isLoadable)
                  .map((item: any, index: number) => (
                    <span key={index}>{item?.skuCode} </span>
                  ))}
                барааг ачилт хийх зөвшөөрөл өгсөн байна.
              </>
            )}
          </Text>
        </Alert>
      </Modal>

      <Modal
        opened={isDropshipping[0] === "successSplit"}
        onClose={() => {
          setIsDropshipping([]);
        }}
        withCloseButton={false}
        size="60%"
        padding={0}
        centered>
        <SupplierSalesSplitForm
          id={action[1].id}
          isDropshipping={isDropshipping}
          onCancel={onCancel}
          onSuccess={async () => {
            await reload();
            onCancel && onCancel();
          }}
        />
      </Modal>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/supplier-sales",
    label: "Борлуулалтын захиалгын жагсаалт",
  },
  {
    label: "Захиалгын дэлгэрэнгүй",
  },
];

const useStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    border: `1px solid #868E96`,
    borderRadius: theme.radius.md,
    height: 50,
    width: "100%",

    backgroundColor: useColorScheme() === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.05)",
    },
  },
}));
