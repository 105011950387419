import { LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router";
import useSWR from "swr";
import { DeliveryApi } from "../../apis";
import { SupplierDeliveryDistributingDetailForm } from "./detail-form";

export function SupplierDeliveryDistributingDetail() {
  const params = useParams();

  const { data, isLoading } = useSWR(`/delivery_note/detail/${params.id}`, async () => await DeliveryApi.get(params.id as string), {
    fallback: { data: null },
  });

  return (
    <>
      {data && (
        <>
          <LoadingOverlay visible={isLoading} />
          <SupplierDeliveryDistributingDetailForm action={["detail", data]} />
        </>
      )}
    </>
  );
}
