import { Avatar, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import noimage from "../../assets/no-image.png";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";
import { ColumnType, Table } from "../table";
import { BranchForm } from "./branch-form";
import { StaffForm } from "./staff-form";

export function StackLines({ list, values, setFieldValue }: { list: any; values: any; setFieldValue: any }) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const columns = useHeader({ values, setFieldValue, user });

  return <Table name="stack-lines.list" columns={columns} dataSource={(list || []).LinesData} pagination={false} />;
}

const useHeader = ({ values, setFieldValue, user }: { values: any; setFieldValue: any; user: any }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "80px",
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Avatar src={record?.image ?? noimage} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "SKU код",
    dataIndex: "skuCode",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.skuCode ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.unit ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нийт захиалсан тоо",
    dataIndex: "totalOrderedQuantity",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.totalOrderedQuantity ?? 0}
        </Text>
      );
    },
  },
  {
    title: "Qty_Available_Sale",
    dataIndex: "availableQuantity",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.availableQuantity ?? 0}
        </Text>
      );
    },
  },
  {
    title: "Үлдэгдэл",
    dataIndex: "minQuantity",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.minQuantity ?? 0}
        </Text>
      );
    },
  },
  {
    title: "Захиалах тоо",
    dataIndex: "quantity",
    render: (_record, index) => {
      return <NumberField max={9000000} min={0} size="xs" name={`lines[${index}].quantity`} placeholder="Тоо хэмжээ" />;
    },
  },
  {
    title: "Худалдан авагч",
    dataIndex: "buyerBusinessId",
    render: (record, index) => {
      if (record?.buyers) {
        return (
          <SelectField
            name={`lines[${index}].buyerBusinessId`}
            size="xs"
            label=""
            options={(record?.buyers || []).map((c: any) => ({
              label: c.profileName,
              value: c.id,
            }))}
            onChange={() => setFieldValue(`lines[${index}].variantId`, record.variantId)}
            placeholder="Сонгох"
          />
        );
      } else {
        return <>-</>;
      }
    },
  },
  {
    title: "Нийлүүлэгч",
    dataIndex: "supplierBusinessId",
    render: (record, index) => {
      if (record?.suppliers) {
        return (
          <SelectField
            name={`lines[${index}].supplierBusinessId`}
            size="xs"
            label=""
            options={(record?.suppliers || []).map((c: any) => ({
              label: c.profileName,
              value: c.id,
            }))}
            placeholder="Сонгох"
          />
        );
      } else {
        return "-";
      }
    },
  },
  {
    title: "Хүлээн авах ажилтан",
    dataIndex: "unit.name",
    render: (_record, index) => {
      return (
        <>
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isEdit).length > 0 && (
            <>
              {!values?.lines || !values?.lines[index]?.buyerBusinessId ? (
                <Text size="sm" c="#44566C">
                  {"-"}
                </Text>
              ) : (
                <StaffForm
                  id={values?.lines || (values?.lines[index]?.buyerBusinessId && values?.lines[index]?.buyerBusinessId)}
                  values={values}
                  index={index}
                />
              )}
            </>
          )}
        </>
      );
    },
  },
  {
    title: "Хүлээн авах салбар",
    dataIndex: "unit.name",
    render: (_record, index) => {
      return (
        <>
          {!values?.lines || !values?.lines[index]?.buyerBusinessId ? (
            <Text size="sm" c="#44566C">
              {"-"}
            </Text>
          ) : (
            <BranchForm id={values?.lines || (values?.lines[index]?.buyerBusinessId && values?.lines[index]?.buyerBusinessId)} values={values} index={index} />
          )}
        </>
      );
    },
  },
];
