import { ActionIcon, Box, Button, Center, Divider, Grid, Group, Paper, Space, Text, Tooltip } from "@mantine/core";
import { IconBrandFacebook, IconBrandInstagram, IconBrandLinkedin, IconBrandTwitter, IconCircleCheck, IconPhoneCall, IconX } from "@tabler/icons-react";

const VerifyPage = ({ action, setAction }: { action: any; setAction: any }) => {
  return (
    <Paper style={{ overflow: "hidden" }} bg={"transparent"} radius={"xs"}>
      <Paper bg={"#101B5E"} radius={0}>
        <Box p={"xl"}>
          <Group align="center">
            <Text fw={600} ta="center" fz={22} color="white">
              Та DeHUB платформд амжилттай бүртгүүллээ!
            </Text>
            <ActionIcon
              onClick={() => {
                window.location.href = "https://cp.dehub.mn/login";
                setAction([]);
              }}
              variant="subtle">
              <IconX />
            </ActionIcon>
          </Group>
          <Divider mt={"lg"} />
          <Space h={"xs"} />
          <Center p={"xs"}>
            <IconCircleCheck stroke={1.2} size={130} color="white" />
          </Center>
          <Paper bg={"transparent"} p={"md"}>
            <Text ta="center" bg={"transparent"} c="white">
              Хүндэт{" "}
              <Text component="span" fw={700}>
                {action[1] && action[1]?.lastName ? action[1]?.lastName[0] + "." : ""} {(action[1] && action[1]?.firstName) || "-"}
              </Text>{" "}
              <Text component="span">
                та{" "}
                <Text component="span" fw={700}>
                  {action[1] && action[1]?.businessName}
                </Text>
                -ийг төлөөлөх платформын админ боллоо. Таны доорх и-мэйлд бүртгэл баталгаажуулах мэдээлэл илгээсэнг шалгана уу. И-мэйлийн зааврын дагуу
                бүртгэлээ баталгаажуулаарай.
              </Text>
            </Text>
          </Paper>
          <Space h={"lg"} />
          <Paper p={"md"}>
            <Grid gutter={12}>
              <Grid.Col span={6}>
                <Text ta="end">Байгууллагын нэр:</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text fw={600} color="indigo">
                  {action[1] && action[1]?.businessName}
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text ta="end">Хэрэглэгчийн нэр:</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text fw={600} c="indigo">
                  {action[1] && action[1]?.lastName ? action[1]?.lastName[0] + "." : ""} {(action[1] && action[1]?.firstName) || "-"}
                </Text>
              </Grid.Col>
              <Grid.Col span={6}></Grid.Col>
              <Grid.Col span={6}>
                <>
                  <Button
                    onClick={() => {
                      window.location.href = `https://cp.dehub.mn/registration/${action[1] && action[1]?.verifyId}`;
                    }}
                    variant="gradient">
                    Баталгаажуулах код оруулах
                  </Button>
                </>
              </Grid.Col>
            </Grid>
          </Paper>
          <Space h={"lg"} />
          <Paper bg={"transparent"}>
            <Center>
              <Text ta="center" c="white">
                И-мэйл ирэхгүй бол{" "}
                <Tooltip position="top" label="Тун удахгүй..." color="green.4">
                  <Text fw={700} component="a" c="blue.0">
                    ЭНД
                  </Text>
                </Tooltip>{" "}
                дарж дахин и-мэйл авна уу. Та Junk/Spam фолдерийг нь шалгахаа мартуузай.
              </Text>
            </Center>
          </Paper>
          <Space my={"lg"} />
          <Divider h={"lg"} />
          <Text c="white" fz={"xs"} ta="center">
            Дижитал Эра Пэймэнт ХХК
          </Text>
          <Space my={"lg"} />
          <Text c="white">
            <Group align="center">
              <IconBrandInstagram /> <IconBrandFacebook /> <IconPhoneCall /> <IconBrandLinkedin /> <IconBrandTwitter />
            </Group>
          </Text>
        </Box>
      </Paper>
    </Paper>
  );
};

export default VerifyPage;
