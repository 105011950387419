import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async (data: any) => {
  return httpRequest.get("/ord/order/dropshipping", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/ord/order/dropshipping/${id}`);
};

export const create = async (data: {
  orderId: string;
  lines: {
    variantId: string;
    buyerBusinessId: string;
    supplierBusinessId: string;
    quantity: string;
  };
}) => {
  return httpRequest.post(`/ord/order/dropshipping`, data);
};

export const update = async (
  id: string,
  data: {
    senderNote: string;
    senderAdditionalNote: string;
    attachments: [];
  },
) => {
  return httpRequest.put(`/ord/order/dropshipping/${id}`, data);
};

export const split = async (data: any) => {
  return httpRequest.post(`/ord/order/dropshipping`, data);
};
