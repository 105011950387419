import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/ord/delivery_note", { offset, filter });
};

export const assign = async (data: any) => {
  return httpRequest.put(`/ord/delivery_note/assign`, data);
};

export const create = async (data: any) => {
  return httpRequest.post(`/ord/delivery_note`, data);
};

export const approve = async (data: any) => {
  return httpRequest.put(`/ord/delivery_note/approve`, data);
};

export const staffSelect = async (data: any) => {
  return httpRequest.get("/ord/delivery_note/staff_select", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/ord/delivery_note/${id}`);
};
