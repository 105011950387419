import { Badge, Button, Flex, Text, Tooltip } from "@mantine/core";
import { IconInfoSquare } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DeliveryApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IDeliveryNote } from "../../interfaces/IDeliveryNote";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";

export function SupplierDeliveredList() {
  const navigate = useNavigate();

  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { deliveryNoteStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({
    deliveryNoteStatus,
    user,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          return navigate(`/supplier-delivery/detail/${record.id}`);
        }
        default:
      }
    },
  });

  return (
    <Table
      name="supplier-delivery-distribute-confirm.list"
      filters={{
        deliveryNoteStatus: "DELIVERED",
        businessId: "",
        staffId: "",
        startDate: "",
        endDate: "",
        paymentStatus: "",
        paymentTermId: "",
      }}
      columns={columns}
      loadData={(data) => DeliveryApi.list(data!)}
    />
  );
}

const useHeader = ({
  deliveryNoteStatus,
  onClick,
  user,
}: {
  deliveryNoteStatus: IReference[];
  onClick: (key: string, record: IDeliveryNote) => void;
  user: any;
}): ColumnType<IDeliveryNote>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_LIST").length > 0 ? (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Хүргэлт",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          styles={{
            label: {
              overflow: "visible",
            },
          }}>
          {record?.refCode ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хүргэлт статус",
    sorter: true,
    dataIndex: "po_status",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(deliveryNoteStatus || []).find((c: any) => c.code === record.deliveryNoteStatus)?.color} 1px solid`,
            color: `${(deliveryNoteStatus || []).find((c: any) => c.code === record.deliveryNoteStatus)?.color}`,
          }}
          styles={{
            label: {
              overflow: "visible",
            },
          }}>
          {(deliveryNoteStatus || []).find((c: any) => c?.code === record?.deliveryNoteStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хүргэлт огноо",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {dateTimeSecFormat(record?.deliveryDate) ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүргэлт гарсан",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {dateTimeSecFormat(record?.dispatchedDate) ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүргэлт хариуцсан",
    sorter: true,
    dataIndex: "supplier",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {renderName(record.staff)}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.staff?.email ?? "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Худалдан авагч",
    sorter: true,
    dataIndex: "supplier",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.receiverBusiness?.partner?.businessName ?? "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.receiverBusiness?.partner?.refCode ?? "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "receiverBusiness",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.receiverBusiness?.profileName ?? "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.receiverBusiness?.refCode ?? "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Хүлээн авах салбар",
    sorter: true,
    dataIndex: "receiverBranch",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.receiverBranch?.refCode ?? "-"}, {record?.receiverBranch?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "SO#",
    sorter: true,
    dataIndex: "salesCode",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          styles={{
            label: {
              overflow: "visible",
            },
          }}>
          {record?.salesCode ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Төлбөрийн нөхцөл",
    sorter: true,
    dataIndex: "paymentTerm",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.paymentTerm?.description ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээн авсан нийт дүн",
    sorter: true,
    dataIndex: "totalAmount",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.totalAmount ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Төлөх дүн",
    sorter: true,
    dataIndex: "amountToPay",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.amountToPay ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Төлсөн дүн",
    sorter: true,
    dataIndex: "paidAmount",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.paidAmount ?? 0)}
        </Text>
      );
    },
  },
];
