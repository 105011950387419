import { ActionIcon, Button, Flex, LoadingOverlay, Paper, Tabs, Text, Tooltip } from "@mantine/core";
import { IconFileText, IconPackageExport, IconReceipt } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { OrderApi } from "../../apis";
import { BackOrderList } from "../../components/back-order/list";
import { PageLayout } from "../../components/layout";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { SupplierPurchaseList } from "./list";

export function SupplierPurchaseTabs() {
  const breadcrumbs = useBreadcrumb();
  const [activeTab, setActiveTab] = React.useState<string>("APPROVED");
  const [checked, setChecked] = React.useState<string[]>([]);
  const [actions, setActions] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const order = async () => {
    setIsLoading(true);
    try {
      let res = await OrderApi.list({
        filter: {
          type: "PURCHASE",
          orderStatus: "",
          businessId: "",
          startDate: "",
          endDate: "",
          invoiceStatus: "",
          paymentStatus: "",
          paymentTermCondition: "",
          excel: `${true}`,
        },
        offset: {
          limit: 20,
          page: 1,
        },
      });

      window.open(res.excelUrl as string, "_blank");
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
    setIsLoading(false);
  };

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <PageLayout
      title="Харилцагчийн захиалгын жагсаалт"
      subTitle="Гэрээт харилцагчид хүргэх захиалгууд"
      breadcrumb={breadcrumbs}
      extra={[
        <Flex key={1} gap="md" align="center">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_RES").length > 0 && (
            <Button size="sm" key={1} onClick={() => setActions(["purchase", checked as any])} variant="outline" disabled={checked?.length === 0}>
              SO болгох
            </Button>
          )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_BCK_ORD").length > 0 && (
            <Button size="sm" key={2} onClick={() => setActions(["stack", checked as any])} disabled={checked?.length === 0}>
              Багцлах
            </Button>
          )}
          <>
            {activeTab === "APPROVED" ? (
              <Tooltip label="Файл" color="dark" position="bottom" withArrow={false} onClick={() => order()}>
                <ActionIcon variant="outline" size="lg">
                  <IconFileText />
                </ActionIcon>
              </Tooltip>
            ) : (
              ""
            )}
          </>
        </Flex>,
      ]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs defaultValue="1" value={activeTab} onChange={(e) => setActiveTab(e as string)}>
          <Tabs.List>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isView).length > 0 && (
              <Tabs.Tab leftSection={<IconReceipt color="#44566C" />} value="APPROVED">
                <Text size="sm" fw={500} c="#44566C">
                  Хүлээн авсан
                </Text>
              </Tabs.Tab>
            )}
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_BCK_ORD").length > 0 && (
              <Tabs.Tab leftSection={<IconPackageExport color="#44566C" />} value="PACKAGE">
                <Text size="sm" fw={500} c="#44566C">
                  Багцалж илгээсэн
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isView).length > 0 && (
            <Tabs.Panel value="APPROVED" mt="lg">
              <SupplierPurchaseList setChecked={setChecked} actions={actions} checked={checked} setActions={setActions} />
            </Tabs.Panel>
          )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_BCK_ORD").length > 0 && (
            <Tabs.Panel value="PACKAGE" mt="lg">
              <BackOrderList />
            </Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Харилцагчийн захиалгын жагсаалт",
  },
];
