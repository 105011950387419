import useSWR from "swr";
import { BranchApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { SelectField } from "../form/select-field";

export function BranchForm({ id, values, index }: { id: any; values: any; index: any }) {
  const { data } = useSWR(`/ord/branch/select/${id}/${index}`, async () => {
    try {
      let res = await BranchApi.select({ businessId: id[index]?.buyerBusinessId });
      return res;
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
  });

  return (
    <>
      <SelectField
        size="xs"
        name={`lines[${index}].receiverBranchId`}
        placeholder="Сонгох"
        options={(data || []).map((c: any) => ({
          label: c.name,
          value: c.id,
        }))}
      />
    </>
  );
}
