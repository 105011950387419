import { LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router";
import useSwr from "swr";
import { NetworkApi } from "../../apis";
import { SupplierCustomerDetailForm } from "../../components/supplier-customer/detail-form";

export function SupplierCustomerDetail() {
  const params = useParams();

  const { data, isLoading } = useSwr(`/ord/network/detail.${params.id}`, async () => await NetworkApi.get(params.id as string));

  return (
    <>
      {data && (
        <>
          <LoadingOverlay visible={isLoading} />
          <SupplierCustomerDetailForm action={["detail", data]} />
        </>
      )}
    </>
  );
}
