import { MantineTheme } from "@mantine/core";
import { Global } from "@mantine/emotion";

export const GlobalStyle = () => {
  return (
    <Global
      styles={() => ({
        a: {
          color: "inherit",
          textDecoration: "inherit",
        },
        body: {
          backgroundColor: "#010614",
        },
      })}
    />
  );
};

export const themeConfig = {
  primaryColor: "cyan",
  components: {
    Breadcrumbs: {
      styles: (theme: MantineTheme) => ({
        breadcrumb: {
          color: theme.colors.gray[7],
          fontSize: 14,
        },
      }),
    },
  },
};
