import useSWR from "swr";
import { StaffApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { SelectField } from "../form/select-field";

export function StaffForm({ id, values, index }: { id: any; values: any; index: any }) {
  const { data } = useSWR(`/ord/staff/select/${id}/${index}`, async () => {
    try {
      let res = await StaffApi.select({ businessId: id[index]?.buyerBusinessId });
      return res;
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
  });

  return (
    <SelectField
      size="xs"
      name={`lines[${index}].receiverStaffId`}
      placeholder="Сонгох"
      options={(data || []).map((c: any) => ({
        label: c.firstName,
        value: c.id,
      }))}
    />
  );
}
