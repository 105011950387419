import { LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router";
import useSwr from "swr";
import { NetworkApi } from "../../apis";
import { BuyerCustomerDetailForm } from "../../components/buyer-customer/detail-form";

export function BuyerCustomerDetail() {
  const params = useParams();

  const { data, isLoading } = useSwr(`/ord/network/detail/purchase.${params.id}`, async () => await NetworkApi.get(params.id as string));

  return (
    <>
      {data && (
        <>
          <LoadingOverlay visible={isLoading} />
          <BuyerCustomerDetailForm action={["detail", data]} />
        </>
      )}
    </>
  );
}
