import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const variant = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/order/variant", { offset, filter });
};

export const variantSelect = async (data: {
  query: string | null | any;
  businessId?: string | any;
  orderType?: string | any;
  supplierId?: string | any;
  categoryId?: string | any;
  brandId?: string | any;
}) => {
  return httpRequest.get("/erp/order/variant/select", data);
};

export const category = async (data: any) => {
  return httpRequest.get("/erp/order/category", data);
};
