import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/ord/order/backorder", { offset, filter });
};

export const get = async (data: any) => {
  return httpRequest.put(`/ord/order/backorder/get_lines`, data);
};

export const create = async (data: any) => {
  return httpRequest.post("/ord/order/backorder", data);
};
