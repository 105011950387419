import { ActionIcon, Button, Center, CloseButton, Divider, Flex, LoadingOverlay, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconExclamationMark } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router";
import { OrderApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { FormLayout } from "../layout";

type Props = {
  action?: any;
  setActions?: any;
  constant?: any;
  reload?: any;
};

export function PurchaseSalesConfirmForm({ action, setActions, constant, reload }: Props) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = async (value: string) => {
    setLoading(true);
    try {
      switch (value) {
        case "REJECT": {
          await OrderApi.respond(action?.id, {
            approve: false,
          });
          setActions([]);
          message.success("Хүсэлтийг амжилттай гэрээт харилцагчийн захиалгыг цуцалсан.");
          reload();
          break;
        }
        case "CONFIRM": {
          await OrderApi.respond(action?.id, {
            approve: true,
          });
          setActions([]);
          message.success("Хүсэлтийг амжилттай гэрээт харилцагчийн захиалгыг зөвшөөрсөн.");
          reload();
          break;
        }
        case "DETAIL-REJECT": {
          await OrderApi.respond(action?.id, {
            approve: false,
          });
          message.success("Хүсэлтийг амжилттай гэрээт харилцагчийн захиалгыг цуцалсан.");
          navigate("/supplier-purchase");
          break;
        }
        case "DETAIL-CONFIRM": {
          await OrderApi.respond(action?.id, {
            approve: true,
          });
          message.success("Хүсэлтийг амжилттай гэрээт харилцагчийн захиалгыг зөвшөөрсөн.");
          navigate("/supplier-purchase");
          break;
        }
      }
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <div className={classes.style}>
      <FormLayout
        title={`Захиалга ${constant === "confirm" || constant === "detail-confirm" ? "зөвшөөрөх" : "татгалзах"}`}
        subTitle={`Гэрээт харилцагчийн захиалгыг ${constant === "confirm" || constant === "detail-confirm" ? "зөвшөөрөх" : "татгалзах"}`}
        extra={[<CloseButton key="cancel" onClick={() => setActions([])} />]}>
        <LoadingOverlay visible={loading} />
        <Divider color="gray" my="xs" />
        <Flex sx={{ position: "relative" }} direction="column" gap="md">
          <Center>
            <ActionIcon size={100} radius={100} variant="light" color="yellow">
              <IconExclamationMark size="4.125rem" />
            </ActionIcon>
          </Center>
          <Center>
            <Text fw={500} fz={24} c="gray.7">
              Захиалгыг {`${constant === "confirm" || constant === "detail-confirm" ? "зөвшөөрөх" : "татгалзах"}`} итгэлтэй байна уу?
            </Text>
          </Center>
          <Center>
            <Text w={"540px"} ta="center" c="gray.8" fz={18}>
              Таны сонгосон худалдан авагчдын захиалгыг баталгаажуулах гэж байна. Хэрвээ та итгэлтэй байвал{" "}
              {constant === "confirm" || constant === "detail-confirm" ? "'Зөвшөөрөх' сонгот хийнэ үү." : "'Татгалзах' сонголт хийнэ үү."}
            </Text>
          </Center>

          <Divider color="gray" mt={20} />
          <Flex direction="row" justify="space-between" mb={15}>
            <Button variant="outline" onClick={() => setActions([])}>
              Болих
            </Button>
            <Flex gap="md">
              {constant === "reject" && (
                <Button
                  variant="light"
                  color="red"
                  onClick={() => {
                    onSubmit("REJECT");
                  }}>
                  Татгалзах
                </Button>
              )}
              {constant === "detail-reject" && (
                <Button
                  variant="light"
                  color="red"
                  onClick={() => {
                    onSubmit("DETAIL-REJECT");
                  }}>
                  Татгалзах
                </Button>
              )}
              {constant === "confirm" && (
                <Button
                  color="teal"
                  variant="filled"
                  onClick={() => {
                    onSubmit("CONFIRM");
                  }}>
                  Зөвшөөрөх
                </Button>
              )}
              {constant === "detail-confirm" && (
                <Button
                  color="teal"
                  variant="filled"
                  onClick={() => {
                    onSubmit("DETAIL-CONFIRM");
                  }}>
                  Зөвшөөрөх
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </FormLayout>
    </div>
  );
}
const useStyles = createStyles(() => ({
  style: {
    marginTop: 20,
  },
}));
