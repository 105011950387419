import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const get = async (id: any) => {
  return httpRequest.get(`/inv/invoice/${id}`);
};

export const pay = async (data: any) => {
  return httpRequest.post(`/pay/invoice`, data);
};
