import { NumberInput } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  noError?: boolean;
  hideControls?: boolean;
  classNames?: Partial<Record<string, string>> | undefined;
  max?: number | undefined;
  min?: number | undefined;
  errors?: any;
  styles?: any;
  rightSection?: any;
  required?: boolean;
  maxLength?: number | undefined;
  minLength?: number | undefined;
  onChangeCustom?: any;
  w?: any;
  icon?: any;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
};

export function NumberField({
  name,
  label,
  noError = false,
  hideControls = true,
  placeholder,
  disabled = false,
  classNames,
  max,
  min,
  errors,
  styles,
  required,
  rightSection,
  maxLength,
  minLength,
  onChangeCustom,
  w,
  icon,
  size,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <>
      <NumberInput
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        error={errors ? errors : error}
        value={value}
        w={w}
        size={size}
        hideControls={hideControls}
        onChange={(e) => {
          onChange(e);
          onChangeCustom && onChangeCustom(e);
        }}
        classNames={classNames}
        max={max}
        min={min}
        styles={{
          error: {
            display: noError ? (errors ? "block" : "none") : "block",
            marginBottom: errors ? -7 : 0,
          },
          wrapper: {
            marginBottom: error && noError && 0,
            marginTop: errors ? 10 : 0,
          },

          ...styles,
        }}
        leftSection={icon}
        rightSection={rightSection}
        required={required}
        maxLength={maxLength}
        minLength={minLength}
      />
    </>
  );
}
