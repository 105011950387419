import { ActionIcon, Avatar, Badge, Box, Button, Divider, Flex, Grid, Group, Text, Textarea } from "@mantine/core";
import { IconPhoto, IconTextPlus } from "@tabler/icons-react";
import noimage from "../../assets/no-image.png";
import { IAttachments } from "../../interfaces/IAttachments";
import { ILines } from "../../interfaces/ILines";
import { currencyFormat } from "../../utils";
import { dateFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";

type Props = {
  action: any;
};

export function BuyerSalesDynamicDetailForm({ action }: Props) {
  const now_date = new Date();
  const columns = useHeader({
    onClick: (key, record) => {},
  });

  const columnsAttachments = useHeaderAttachments({
    onClick: (key, item) => {},
  });

  const len = Object.keys((action && action[1]?.lines) || [])?.length;

  return (
    <>
      <Box
        sx={(theme) => ({
          background: theme.white,
          borderRadius: 1,
          border: `1px solid ${theme.colors.gray[2]}`,
          padding: "0px 20px",
        })}>
        <Flex mt={20} direction="row" justify="space-between" mb={20}>
          <Text fw={500} c="#25262B">
            {"Бараа бүтээгдэхүүн, ажил үйлчилгээ"}
          </Text>
          <Flex gap="md" align="center">
            <ActionIcon variant="outline" size="md" color="gray">
              <IconTextPlus onClick={() => {}} />
            </ActionIcon>
            <Button variant="outline" size="xs" onClick={() => {}} color="gray">
              Багцаар нэмэх
            </Button>
          </Flex>
        </Flex>
        <Divider color="gray" my="xs" />
        <Table columns={columns} name="supplier-sales-order-list.lines" dataSource={action && (action[1].lines || [])} pagination={false} />
        <Divider color="gray" my="xs" />

        <Flex mt={20} direction="row" justify="space-between" mb={20}>
          <Text fw={500} c="#25262B">
            {"Захиалгын нэгтгэл"}
          </Text>
        </Flex>
        <Divider color="gray" my="xs" />
        <Grid mb={5}>
          <Grid.Col span={{ xs: 12, sm: 6 }}>
            <Grid>
              <Grid.Col span={12}>
                <Textarea value={(action && action[1]?.senderNote) || ""} label="Харилцагчийн нэмэлт мэдээлэл" onChange={() => {}} />
              </Grid.Col>
              <Grid.Col span={12}>
                <Textarea value={(action && action[1]?.senderAdditionalNote) || "Нэмэлт тэмдэглэл:"} label="Тэмдэглэл оруулах" onChange={() => {}} />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6 }}>
            <Box
              h="100%"
              sx={(theme) => ({
                backgroundColor: theme.white,
                borderLeft: `2px outset #5fc6d9`,
              })}
              px={20}
              py={16}>
              <Grid>
                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C">
                    Захиалгад тоо
                  </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {len} төрөл
                  </Text>
                </Grid.Col>

                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C">
                    Нийт тоо ширхэг
                  </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {(action && action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity || 0, 0)) || 0} ширхэг
                  </Text>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Text size="lg" fw={500} c="#25262B">
                    {"Тооцоолол"}
                  </Text>
                </Grid.Col>

                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C">
                    Захиалгын нийт дүн
                  </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat((action && action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0)) || 0)}
                  </Text>
                </Grid.Col>

                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C" ml={47}>
                    Тооцсон НӨАТ
                  </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(action[1]?.lineVatAmount || 0)}
                  </Text>
                </Grid.Col>

                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C" ml={47}>
                    Тооцсон НХАТ
                  </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat((action && action[1]?.lineTaxAmount) || 0)}
                  </Text>
                </Grid.Col>

                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C">
                    Тооцсон хөнгөлөлт
                  </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(
                      ((action && action[1]?.lines) || [])?.reduce(
                        (total: number, item: any) =>
                          total +
                            (item?.discountType === "PERCENTAGE"
                              ? (item?.price * item?.quantity * item?.discountValue) / 100
                              : item?.quantity * item?.discountValue) || 0,
                        0,
                      ) || 0,
                    )}
                  </Text>
                </Grid.Col>

                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C">
                    {"Нийт дүн"}
                  </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat((action && action[1]?.totalAmount) || 0)}
                  </Text>
                </Grid.Col>

                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C">
                    Төлбөр баталгаажуулах
                  </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  {action[1]?.paymentTerm?.condition === "COD" ? (
                    <Text size="lg" fw={500} c="#44566C" ta="right">
                      {currencyFormat((action && action[1]?.advancedAmount) || 0)}
                    </Text>
                  ) : (
                    <Text size="lg" fw={500} c="#44566C" ta="right">
                      {currencyFormat(0)}
                    </Text>
                  )}
                </Grid.Col>

                <Grid.Col span={6}>
                  <Text size="lg" fw={500} c="#44566C">
                    Төлбөр баталгаажуулах огноо, цаг
                  </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  {action[1]?.paymentTerm?.condition === "COD" ? (
                    <Text size="lg" fw={500} c="#44566C" ta="right">
                      {dateFormat(now_date.setDate(now_date.getDate() + action[1]?.paymentTerm?.confirmationDay))}
                    </Text>
                  ) : (
                    <Text size="lg" fw={500} c="#44566C" ta="right">
                      {"-"}
                    </Text>
                  )}
                </Grid.Col>
              </Grid>
            </Box>
          </Grid.Col>
        </Grid>
        <Divider color="gray" my="xs" />
        <Flex mt={20} direction="row" justify="space-between" mb={20}>
          <Text fw={500} c="#25262B">
            {"Хавсралт нэмэх"}
          </Text>
          <Flex gap="md" align="center">
            <ActionIcon variant="outline" size="md" color="gray">
              <IconTextPlus onClick={() => {}} />
            </ActionIcon>
          </Flex>
        </Flex>
        <Divider color="gray" my="xs" />
        <Table columns={columnsAttachments} name="values.attachments" dataSource={action && action[1]?.attachments} pagination={false} />
        <Divider color="gray" my="xs" />
      </Box>
    </>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: ILines) => void }): ColumnType<ILines>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Group>
        <Avatar size={40} src={record?.image !== null ? record?.image : noimage} radius={5}>
          <IconPhoto />
        </Avatar>
      </Group>
    ),
  },
  {
    title: "SKU код",
    dataIndex: "skuCode",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.skuCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    dataIndex: "name",
    render: (record, index) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.unit || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    render: (record, index) => {
      return (
        <Group align="right">
          <Text size="sm" fw={500} c="#44566C">
            {currencyFormat(record?.price || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Хөнгөлөлт",
    dataIndex: "discountValue",
    render: (record, index) => {
      let count;
      count =
        record?.discountType === "PERCENTAGE"
          ? ((record?.price || 0) * (record?.quantity || 0) * record.discountValue) / 100
          : (record?.quantity || 0) * record?.discountValue;
      return (
        <Text size="sm" fw={500} c="#44566C">
          {currencyFormat(count || 0)}
        </Text>
      );
    },
  },
  {
    title: "Захиалах тоо",
    dataIndex: "quantity",
    render: (record, index) => {
      return (
        <Group align="center">
          <Text w={150}>
            <Badge color="cyan" size="md" radius="sm" variant="outline">
              {record.quantity || "0"}
            </Badge>
          </Text>
        </Group>
      );
    },
  },
  {
    title: "НӨАТ",
    dataIndex: "hasVat",
    render: (record) => {
      let count;
      count = (record?.quantity || 0) * record?.vatAmount;
      return (
        <Text size="sm" fw={500} c="#44566C" w={"max-content"}>
          {currencyFormat(count)}
        </Text>
      );
    },
  },
  {
    title: "НХАТ",
    dataIndex: "hasTax",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" w={"max-content"}>
          {currencyFormat(record?.taxAmount)}
        </Text>
      );
    },
  },
  {
    title: "Үнийн дүн",
    dataIndex: "name",
    render: (record: any) => {
      let count;
      count =
        (record?.price || 0) * (record?.quantity || 0) +
        (record?.quantity || 0) * (record?.vatAmount || 0) -
        (record?.discountType === "PERCENTAGE"
          ? ((record?.price || 0) * (record?.quantity || 0) * record.discountValue) / 100
          : (record?.quantity || 0) * record?.discountValue);
      return (
        <Group align="right">
          <Text size="sm" fw={500} c="#44566C" w={"max-content"}>
            {currencyFormat(count || 0)}
          </Text>
        </Group>
      );
    },
  },
];

const useHeaderAttachments = ({ onClick }: { onClick: (key: string, record: IAttachments) => void }): ColumnType<IAttachments>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record, index) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Урл",
    dataIndex: "url",
    render: (record, index) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.url || "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    render: (record, index) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.description || "-"}
        </Text>
      );
    },
  },
];
