import { ActionIcon, Badge, Box, Button, Collapse, Flex, Grid, Group, Input, Modal, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useColorScheme } from "@mantine/hooks";
import { IconMap2, IconSquareArrowDown, IconSquareArrowUp } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { regNumber } from "../../utils";
import { dateTimeSecFormat } from "../../utils/date";
import { Form } from "../form";
import { PageLayout } from "../layout";
import { BuyerSalesDynamicConfirmForm } from "./confirm-form";
import { BuyerSalesDynamicDetailForm } from "./dynamic-detail-form";
import { BuyerSalesDynamicReviewForm } from "./review-form";

const schema = yup.object({
  regNumber: yup.string().when(["types"], (types) => {
    if ((typeof types === "string" && types === "REGNUMBER") || (typeof types === "string" && types === "NONE")) {
      return yup.string().matches(regNumber, "Татвар төлөгчийн дугаарын формат аа шалгана уу.").required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  businessName: yup.string().when(["types"], (types) => {
    if ((typeof types === "string" && types === "PARTNER") || (typeof types === "string" && types === "NONE")) {
      return yup.string().required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  supplierId: yup.string().required("Заавал бөглөнө!").nullable(),
  deliveryType: yup.string().required("Заавал сонгох!").nullable(),
  deliveryDate: yup.string().when(["deliveryType"], (deliveryType) => {
    if (typeof deliveryType === "string" && deliveryType === "CUSTOM_DATE") {
      return yup.string().required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  receiverStaffId: yup.string().required("Заавал бөглөнө!").nullable(),
  receiverBranchId: yup.string().required("Заавал бөглөнө!").nullable(),
});

export type IFormData = {
  regNumber: string;
  businessName: string;
  businessId: string;
  receiverBranchId: string;
  deliveryType: string;
  supplierId: string;
  deliveryDate: string;
  receiverStaffId: string;
  lines: [];
  senderNote: string;
  senderAdditionalNote: string;
  shippingAmount: string;
  discountType: string;
  discountValue: string;
  attachments: [];
  toReview: boolean;
  send: boolean;
  types: string;
};

type Props = {
  action: any[];
};

const initData = {
  regNumber: undefined,
  businessName: undefined,
  businessId: undefined,
  receiverBranchId: undefined,
  deliveryType: undefined,
  deliveryDate: undefined,
  receiverStaffId: undefined,
  lines: [],
  senderNote: undefined,
  senderAdditionalNote: undefined,
  shippingAmount: undefined,
  discountType: undefined,
  discountValue: undefined,
  supplierId: undefined,
  attachments: [],
  toReview: false,
  send: false,
  types: undefined,
};

export function BuyerSalesDetailForm({ action }: Props) {
  const { classes } = useStyles();
  const breadcrumbs = useBreadcrumb();
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const [actions, setActions] = React.useState<string[]>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [collapseOpen, setCollapseOpen] = React.useState(true);
  const [collapsedOpen, setCollapsedOpen] = React.useState(true);
  const { orderStatus, deliveryDateTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<IFormData>({
    deliveryDate: "",
    deliveryType: "",
    regNumber: "",
    businessName: "",
    lines: [],
    attachments: [],
    senderNote: "",
    senderAdditionalNote: "",
    discountType: "",
    discountValue: "",
    shippingAmount: "",
    supplierId: "",
    types: "NONE",
    receiverStaffId: "",
    receiverBranchId: "",
    ...(action && action[0] === "edit"
      ? {
          ...action[1],
          businessName: action && action[1]?.receiverBusiness?.profileName,
          regNumber: parseInt(action && action[1]?.receiverBusiness?.regNumber),
          supplierId: action && action[1]?.receiverBusiness?.partner?.businessName,
          types: "NONE",
        }
      : {}),
  });

  const staticDay = [
    {
      id: "1",
      date: "2021-12-28",
      day: "Мягмар",
    },
    {
      id: "2",
      date: "2021-12-29",
      day: "Лхагва",
    },
    {
      id: "3",
      date: "2021-12-30",
      day: "Пүрэв",
    },
    {
      id: "4",
      date: "2021-12-31",
      day: "Баасан",
    },
    {
      id: "5",
      date: "2022-01-01",
      day: "Бямба",
    },
    {
      id: "6",
      date: "2022-01-02",
      day: "Ням",
    },
  ];

  return (
    <>
      <Form
        ref={ref}
        validationSchema={schema}
        onSubmit={() => {}}
        initialValues={{
          ...initData,
          ...data,
          lines: data?.lines || [],
          attachments: data?.attachments || [],
        }}>
        {({ values }) => {
          return (
            <PageLayout
              title="Захиалгын дэлгэрэнгүй"
              subTitle="Гэрээт нийлүүлэгчид хандаж захиалга бичих"
              breadcrumb={breadcrumbs}
              extra={[
                <Button onClick={() => navigate("/buyer-sales")} key={1} variant="default">
                  Буцах
                </Button>,
                <>
                  {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isReview).length > 0 && (
                    <Button
                      onClick={() => setActions(["review"])}
                      key={2}
                      disabled={action[1]?.orderStatus === "REGISTERED" && user?.currentBusiness?.id === action[1]?.senderBusiness?.id ? false : true}>
                      Хянах
                    </Button>
                  )}
                </>,
                <>
                  {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_RES").length > 0 && (
                    <Button
                      onClick={() => setActions(["order"])}
                      key={3}
                      disabled={action[1]?.orderStatus === "REVIEWED" && user?.currentBusiness?.id === action[1]?.receiverBusiness?.id ? false : true}>
                      Зөвшөөрөх
                    </Button>
                  )}
                </>,
              ]}>
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex mih={10} gap="md" justify="space-between" align="center" direction="row" wrap="wrap">
                  <Group>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      style={{
                        border: `${orderStatus.find((c: any) => c.code === action[1].orderStatus)?.color} 1px solid`,
                        color: `${orderStatus.find((c: any) => c.code === action[1].orderStatus)?.color}`,
                      }}>
                      {orderStatus.find((c: any) => c.code === action[1].orderStatus)?.sentName || "-"}
                    </Badge>
                    <Text size="sm" fw={700} c="cyan">
                      {(action && action[1]?.purchaseCode) || "-"}
                    </Text>
                  </Group>
                  <Group>
                    <div>
                      <Group>
                        <Text size="sm" fw={500} c="#44566C">
                          {"бүртгэсэн"}:
                        </Text>
                        <Text size="sm" fw={600} c="#44566C">
                          {action && action[1]?.orderedUser?.lastName !== null ? action[1]?.orderedUser.lastName[0] : "-"}.{" "}
                          {action[1]?.orderedUser.firstName !== null ? action[1]?.orderedUser.firstName : "-"}
                        </Text>
                      </Group>
                      <Flex gap={5} wrap="nowrap" justify="flex-end">
                        <Text size="sm" fw={500} c="cyan">
                          {dateTimeSecFormat(action[1]?.createdAt)}
                        </Text>
                      </Flex>
                    </div>
                  </Group>
                </Flex>
              </Box>
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                  <Text fw={500}>{"ҮНДСЭН МЭДЭЭЛЭЛ"}</Text>
                  <ActionIcon variant="transparent">
                    {!collapseOpen ? (
                      <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapseOpen(true)} />
                    ) : (
                      <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapseOpen(false)} />
                    )}
                  </ActionIcon>
                </Flex>
              </Box>
              <Collapse in={collapseOpen}>
                <Box
                  sx={(theme) => ({
                    background: theme.white,
                    borderRadius: 5,
                    border: `1px solid ${theme.colors.gray[2]}`,
                    padding: "20px 20px",
                  })}
                  mb={5}>
                  <Grid>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Партнерийн нэр:">
                        <Text fw={500} c="cyan">
                          {action[1]?.receiverBusiness?.partner?.refCode || action[1]?.receiverBusiness?.partner?.businessName
                            ? [action[1]?.receiverBusiness?.partner?.refCode, " ", action[1]?.receiverBusiness?.partner?.businessName]
                            : "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Нийлүүлэгчийн нэр:">
                        <Text fw={500} c="cyan">
                          {action[1]?.receiverBusiness?.refCode || action[1]?.receiverBusiness?.profileName
                            ? [action[1]?.receiverBusiness?.refCode, " ", action[1]?.receiverBusiness?.profileName]
                            : "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                        <Text fw={500} c="cyan">
                          {action[1]?.receiverBusiness?.regNumber || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Төлбөрийн нөхцөл:">
                        <Text fw={500} c="cyan">
                          {action[1]?.paymentTerm?.description || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Нийлүүлэгчийн менежер:">
                        <Text fw={500} c="cyan">
                          {action[1]?.supplierStaff
                            ? action[1]?.supplierStaff?.lastName || action[1]?.supplierStaff?.firstName
                              ? [action[1]?.supplierStaff?.lastName, " ", action[1]?.supplierStaff?.firstName]
                              : "-"
                            : "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Худалдан авагчийн менежер:">
                        <Text fw={500} c="cyan">
                          {action[1]?.buyerStaff
                            ? action[1]?.buyerStaff?.lastName || action[1]?.buyerStaff?.firstName
                              ? [action[1]?.buyerStaff?.lastName, " ", action[1]?.buyerStaff?.firstName]
                              : "-"
                            : "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах ажилтан:">
                        <Text fw={500} c="cyan">
                          {action[1]?.receiverStaff
                            ? action[1]?.receiverStaff?.lastName || action[1]?.receiverStaff?.firstName
                              ? [action[1]?.receiverStaff?.lastName, " ", action[1]?.receiverStaff?.firstName]
                              : "-"
                            : "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах салбар:">
                        <Text fw={500} c="cyan">
                          {action[1]?.receiverBranch?.name || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах хаяг:">
                        <Group>
                          <ActionIcon variant="transparent">
                            <IconMap2 color="#21bbdd" />
                          </ActionIcon>
                          {action[1]?.receiverBranch ? (
                            action[1]?.receiverBranch?.branchAddress ? (
                              <Text fw={500} c="cyan">
                                {action[1]?.receiverBranch?.branchAddress}
                              </Text>
                            ) : (
                              "-"
                            )
                          ) : (
                            "-"
                          )}
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Захиалгыг хүлээн авах:">
                        <Text fw={500} c="cyan">
                          {deliveryDateTypes.find((c: any) => c?.code === action[1]?.deliveryType)?.name || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хамгийн ойрхон хүргэх боломжтой хуваарьт өдөр:">
                        {values?.deliveryType === "DEFAULT_DATE" ? (
                          <Grid mt={5}>
                            {(staticDay || [])?.map((item: any, index: any) => {
                              return (
                                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} key={index}>
                                  <UnstyledButton className={classes.item}>
                                    <Flex justify="center" align="center" direction="column" wrap="nowrap">
                                      <Text size="sm" fw={500} c="#44566C">
                                        {item?.date}
                                      </Text>
                                      <Text size="sm" fw={500} c="#44566C">
                                        {item?.day}
                                      </Text>
                                    </Flex>
                                  </UnstyledButton>
                                </Grid.Col>
                              );
                            })}
                          </Grid>
                        ) : (
                          <Text fw={500} c="cyan">
                            {dateTimeSecFormat(action[1]?.deliveryDate)}
                          </Text>
                        )}
                      </Input.Wrapper>
                    </Grid.Col>
                  </Grid>
                </Box>
              </Collapse>
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                  <Text fw={500}>{"ЗАХИАЛГЫН ЗҮЙЛС"}</Text>
                  <ActionIcon variant="transparent">
                    {!collapsedOpen ? (
                      <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapsedOpen(true)} />
                    ) : (
                      <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapsedOpen(false)} />
                    )}
                  </ActionIcon>
                </Flex>
              </Box>
              <Collapse in={collapsedOpen}>
                <BuyerSalesDynamicDetailForm action={action} />
              </Collapse>
            </PageLayout>
          );
        }}
      </Form>
      <Modal
        opened={actions[0] === "review"}
        onClose={() => {
          setActions([]);
        }}
        withCloseButton={false}
        size="600px"
        padding={0}
        centered>
        <BuyerSalesDynamicReviewForm action={action && action[1]} setActions={setActions} reviewed={"reviewed"} />
      </Modal>

      <Modal
        opened={actions[0] === "order"}
        onClose={() => {
          setActions([]);
        }}
        withCloseButton={false}
        size="600px"
        padding={0}
        centered>
        <BuyerSalesDynamicConfirmForm action={action && action[1]} setActions={setActions} respond={"respond"} />
      </Modal>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/buyer-sales",
    label: "Худалдан авалт",
  },
  {
    label: "Захиалгын дэлгэрэнгүй",
  },
];

const useStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    border: `1px solid #868E96`,
    borderRadius: theme.radius.md,
    height: 50,
    width: "100%",

    backgroundColor: useColorScheme() === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.05)",
    },
  },
}));
