import { createSlice } from "@reduxjs/toolkit";
import { IAuth } from "../interfaces/IAuth";
import { IUser } from "../interfaces/IUser";

const initialState: IAuth = {
  user: null,
  userId: null,
  accessToken: null,
  sessionScope: null,
  approve: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authChange: (state, { payload }: { payload?: IAuth }) => {
      if (payload) {
        state.accessToken = payload?.accessToken;
        state.sessionScope = payload?.sessionScope;
      } else {
        state.user = null;
        state.userId = null;
        state.accessToken = null;
        state.sessionScope = null;
      }

      return state;
    },
    authMe: (state, { payload }: { payload: IUser }) => {
      state.user = payload;
      state.userId = payload?.id;

      return state;
    },
    authDeliveryApprove: (state, { payload }: { payload: any }) => {
      state.approve = payload;
    },
  },
});

export const authReducer = authSlice.reducer;

export const { authChange, authMe, authDeliveryApprove } = authSlice.actions;
