import { Badge, Button, Checkbox, Flex, Text, Tooltip } from "@mantine/core";
import { IconInfoSquare } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DeliveryApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";

export function SupplierDeliveryDistributingList({ checked, setChecked }: { checked: string[]; setChecked: (e: string[]) => void }) {
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { deliveryNoteStatus, pullSheetStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({
    deliveryNoteStatus,
    pullSheetStatus,
    checked,
    user,
    onClick: (key: string, row: any) => {
      if (key === "select_all") {
        setChecked(row);
      }
      if (key === "unselect_all") {
        setChecked([]);
      }
      if (key === "select") {
        if (checked.some((item: any) => item.id === row.id)) {
          setChecked([...checked.filter((item: any) => item.id !== row.id)]);
        } else setChecked([...checked, row]);
      }
      if (key === "detail") {
        return navigate(`/supplier-delivery/detail/${row.id}`);
      }
    },
  });

  return (
    <Table
      name="supplier-delivery-distributing.list"
      filters={{
        deliveryNoteStatus: "",
        businessId: "",
        staffId: "",
        startDate: "",
        endDate: "",
        paymentStatus: "",
        paymentTermId: "",
      }}
      columns={columns}
      loadData={(data) => DeliveryApi.list(data!)}
    />
  );
}

const useHeader = ({
  checked,
  deliveryNoteStatus,
  pullSheetStatus,
  onClick,
  user,
}: {
  checked: string[];
  deliveryNoteStatus: IReference[];
  pullSheetStatus: IReference[];
  onClick: (e: string, record: any) => void;
  user: any;
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_LIST").length > 0 ? (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    renderCheck(record, index) {
      let checkedData: any[];
      let recordData: any[];
      checkedData = (checked ?? []).filter((item: any) => item.isLoadable);
      recordData = (record?.rows ?? []).filter((item: any) => item.isLoadable);
      return (
        <Checkbox
          onChange={() => {}}
          onClick={() => {
            if (Array.isArray(recordData) && Array.isArray(checkedData) && (recordData || []).length === (checkedData || []).length) {
              onClick("unselect_all", recordData);
            } else onClick("select_all", recordData);
          }}
          checked={(checkedData || []).length === (recordData || []).length}
        />
      );
    },
    title: "",
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {}}
          onClick={() => {
            onClick("select", record);
          }}
          checked={checked.some((item: any) => item.id === record.id)}
          disabled={!record.isLoadable}
        />
      );
    },
  },
  {
    title: "Хүргэлт",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          styles={{
            label: {
              overflow: "visible",
            },
          }}>
          {record?.refCode ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хүргэлт огноо",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {dateTimeSecFormat(record?.deliveryDate) ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүргэлтэд гарсан",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {dateTimeSecFormat(record?.dispatchedDate) ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Худалдан авагч",
    sorter: true,
    dataIndex: "supplier",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.receiverBusiness?.partner?.businessName ?? "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.receiverBusiness?.partner?.refCode ?? "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "supplier",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {record?.receiverBusiness?.profileName ?? "-"}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.receiverBusiness?.refCode ?? "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Хүлээн авах салбар",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.receiverBranch?.refCode ?? "-"}, {record?.receiverBranch?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "SO#",
    sorter: true,
    dataIndex: "po_name",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          styles={{
            label: {
              overflow: "visible",
            },
          }}>
          {record?.salesCode ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Төлбөрийн нөхцөл",
    sorter: true,
    dataIndex: "po_status",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.paymentTerm?.description ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүргэлт статус",
    sorter: true,
    dataIndex: "po_status",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `1px solid ${(deliveryNoteStatus ?? []).find((c: any) => c.code === record.deliveryNoteStatus)?.color}`,
            color: `${(deliveryNoteStatus ?? []).find((c: any) => c.code === record.deliveryNoteStatus)?.color}`,
          }}
          styles={{
            label: {
              overflow: "visible",
            },
          }}>
          {(deliveryNoteStatus ?? []).find((c: any) => c?.code === record?.deliveryNoteStatus)?.name ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хүргэлт хариуцсан",
    sorter: true,
    dataIndex: "supplier",
    render: (record) => {
      return (
        <div>
          <Text size="sm" c="#44566C" w="max-content">
            {renderName(record.staff)}
          </Text>
          <Text size="sm" c="cyan" w="max-content">
            {record?.staff?.email ?? "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Ачилт статус",
    sorter: true,
    dataIndex: "po_status",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `1px solid ${pullSheetStatus.find((c: any) => c.code === record.pullSheetStatus)?.color}`,
            color: `${pullSheetStatus.find((c: any) => c.code === record.pullSheetStatus)?.color}`,
          }}
          styles={{
            label: {
              overflow: "visible",
            },
          }}>
          {pullSheetStatus.find((c: any) => c?.code === record?.pullSheetStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Нийт тоо",
    sorter: true,
    dataIndex: "supplier",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.totalItemCount ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нийт дүн",
    sorter: true,
    dataIndex: "supplier",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.totalAmount ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Хөнгөлөлт дүн",
    sorter: true,
    dataIndex: "supplier",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.discountAmount ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Урамшуулал дүн",
    sorter: true,
    dataIndex: "supplier",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.giveawayAmount ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Төлөх дүн",
    sorter: true,
    dataIndex: "supplier",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.amountToPay ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Төлсөн дүн",
    sorter: true,
    dataIndex: "supplier",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.paidAmount ?? 0)}
        </Text>
      );
    },
  },
];
