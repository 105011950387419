import { LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router";
import useSwr from "swr";
import { NetworkApi } from "../../apis";
import { SupplierCustomerForm } from "../../components/supplier-customer/form";

export function SupplierCustomerOrder() {
  const params = useParams();

  const { data, isLoading } = useSwr(`/ord/network/get.${params.id}`, async () => await NetworkApi.get(params.id as string), {
    fallback: { data: null },
  });

  return (
    <>
      <LoadingOverlay visible={!data || isLoading} />
      {data && <SupplierCustomerForm action={["new", data || {}]} />}
    </>
  );
}
