import { LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router";
import useSWR from "swr";
import { DropShippingApi } from "../../apis";
import { SupplierDropShippingForm } from "./form";

export function SupplierDropShippingDetail() {
  const params = useParams();
  const { data, isLoading } = useSWR(`/ord/order/dropshipping/detail/get.${params.id}`, async () => await DropShippingApi.get(params.id as string), {
    fallback: { data: null },
  });

  return (
    <>
      {data && (
        <>
          <LoadingOverlay visible={isLoading} />
          <SupplierDropShippingForm action={["detail", data]} />
        </>
      )}
    </>
  );
}
