import { ActionIcon, Avatar, Badge, Box, Button, Checkbox, Divider, Flex, Grid, Text, Textarea, Tooltip } from "@mantine/core";
import { IconPhoto, IconTextPlus, IconTruckDelivery } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import noimage from "../../assets/no-image.png";
import { IAdditionalLines } from "../../interfaces/IAdditionalLines";
import { IAttachments } from "../../interfaces/IAttachments";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";
import { currencyFormat } from "../../utils";
import { dateTimeSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";

export function SupplierSalesDynamicDetailForm({
  action,
  checked,
  setChecked,
  onSubmit: _onSubmit,
  onSplit: _onSplit,
}: {
  action: any;
  checked: any;
  setChecked: any;
  onSubmit: (value: any) => void;
  onSplit: (value: any) => void;
}) {
  const now_date = new Date();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const columns = useHeader({
    checked,
    onClick: (key: string, record: any) => {
      if (key === "select_all") {
        setChecked(record);
      }
      if (key === "unselect_all") {
        setChecked([]);
      }
      if (key === "select") {
        if (checked.some((item: any) => item.id === record.id)) {
          setChecked([...checked.filter((item: any) => item.id !== record.id)]);
        } else setChecked([...checked, record]);
      }
    },
  });

  const columnsAdditional = useHeaderAdditional();

  const columnsAttachments = useHeaderAttachments();

  const len = Object.keys((action && action[1]?.lines) || [])?.length;

  return (
    <Box
      sx={(theme) => ({
        background: theme.white,
        borderRadius: 1,
        border: `1px solid ${theme.colors.gray[2]}`,
        padding: "0px 20px",
      })}>
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          {"Бараа бүтээгдэхүүн, ажил үйлчилгээ"}
        </Text>

        {action[1].salesType !== "SPLIT" && (
          <Flex gap="md" align="center">
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_SPLIT").length > 0 && (
              <>
                {checked?.length === 0 || action[1]?.orderStatus !== "AUTHORIZED" ? (
                  <Tooltip
                    multiline
                    w={250}
                    withArrow
                    label="Зөвхөн баталгажсан төлөвтэй захиалгаас Drop shipping хийж хэсэгчлэн хүргэнэ."
                    style={{
                      textAlign: "justify",
                    }}
                    position="bottom">
                    <Button variant="outline" size="xs" onClick={() => {}}>
                      Захиалга салгах
                    </Button>
                  </Tooltip>
                ) : (
                  <Button variant="outline" size="xs" onClick={() => _onSplit(checked)}>
                    Захиалга салгах
                  </Button>
                )}
              </>
            )}

            {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DRSHP").length > 0 && (
              <>
                {checked?.length === 0 || action[1]?.orderStatus !== "AUTHORIZED" ? (
                  <Tooltip
                    multiline
                    w={250}
                    withArrow
                    position="bottom"
                    label="Зөвхөн баталгажсан төлөвтэй захиалгаас Dropshipping хийнэ."
                    style={{
                      textAlign: "justify",
                    }}>
                    <Button variant="filled" size="xs" onClick={() => {}}>
                      Dropship захиалах
                    </Button>
                  </Tooltip>
                ) : (
                  <Button variant="filled" size="xs" onClick={() => _onSubmit(checked)}>
                    Dropship захиалах
                  </Button>
                )}
              </>
            )}
          </Flex>
        )}
      </Flex>
      <Divider color="gray" my="xs" />
      <Table key={1} columns={columns} name="supplier-sales-order-list.lines" dataSource={(action && action[1].lines) || []} pagination={false} />
      <Divider color="gray" my="xs" />
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          {"Нэмэлтээр"}
        </Text>
        <Flex gap="md" align="center">
          <ActionIcon variant="outline" size="md" color="gray">
            <IconTextPlus onClick={() => {}} />
          </ActionIcon>
        </Flex>
      </Flex>
      <Divider color="gray" my="xs" />
      <Table
        key={2}
        columns={columnsAdditional}
        name="supplier-sales-order-list.additionalLines"
        dataSource={action && (action[1].additionalLines || [])}
        pagination={false}
      />
      <Divider color="gray" my="xs" />
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          {"Захиалгын нэгтгэл"}
        </Text>
      </Flex>
      <Divider color="gray" my="xs" />
      <Grid mb={5}>
        <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
          <Grid>
            <Grid.Col span={12}>
              <Textarea value={(action && action[1]?.senderNote) || ""} label="Харилцагчийн нэмэлт мэдээлэл" onChange={() => {}} />
            </Grid.Col>
            <Grid.Col span={12}>
              <Textarea value={(action && action[1]?.senderAdditionalNote) || "Нэмэлт тэмдэглэл:"} label="Тэмдэглэл оруулах" onChange={() => {}} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
          <Box
            h="100%"
            sx={(theme) => ({
              backgroundColor: theme.white,
              borderLeft: `2px outset #5fc6d9`,
            })}
            px={20}
            py={16}>
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Захиалгад тоо
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {len} төрөл
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Нийт тоо ширхэг
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {(action && action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity || 0, 0)) || 0} ширхэг
                </Text>
              </Grid.Col>

              <Grid.Col span={12}>
                <Text size="lg" fw={500} c="#25262B">
                  {"Тооцоолол"}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Захиалгын нийт дүн
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat(action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ml={47}>
                  Тооцсон НӨАТ
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat((action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ml={47}>
                  Тооцсон НХАТ
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat((action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Тооцсон хөнгөлөлт
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat(
                    (action[1]?.lines || [])?.reduce(
                      (total: number, item: any) =>
                        total +
                          (item?.discountType === "PERCENTAGE"
                            ? (item?.price * item?.quantity * item?.discountValue) / 100
                            : item?.quantity * item?.discountValue) || 0,
                      0,
                    ) || 0,
                  )}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Хүргэлтийн төлбөр
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat((action && action[1]?.shippingAmount) || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Үнийн дүнгийн хөнгөлөлт
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {action && action[1]?.discountType === "AMOUNT" && currencyFormat((action && action[1]?.discountValue) || 0)}
                  {action && action[1]?.discountType === "PERCENTAGE" && `${(action && action[1]?.discountValue) || 0} %`}
                  {!action && action[1]?.discountType && "-"}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  {"Нэмэлтийн нийт дүн"}
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {!action[1]?.additionalLines
                    ? currencyFormat(0)
                    : currencyFormat(
                        (action[1]?.additionalLines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) -
                          (action[1]?.additionalLines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? ((item?.price || 0) * (item?.quantity || 0) * item.discountValue) / 100
                                  : item?.discountValue) || 0,
                            0,
                          ),
                      )}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  {"Нийт дүн"}
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {!action[1]?.lines
                    ? currencyFormat(0)
                    : currencyFormat(
                        action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                          (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                          (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                          (action[1]?.lines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? (item?.price * item?.quantity * item?.discountValue) / 100
                                  : item?.quantity * item?.discountValue) || 0,
                            0,
                          ) +
                          (action[1]?.additionalLines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) -
                          (action[1]?.additionalLines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? ((item?.price || 0) * (item?.quantity || 0) * item?.discountValue || 0) / 100
                                  : item?.discountValue) || 0,
                            0,
                          ) +
                          (action[1]?.shippingAmount || 0) -
                          (action[1]?.discountType === "PERCENTAGE"
                            ? ((action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                                (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                                (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                                (action[1]?.lines || [])?.reduce(
                                  (total: number, item: any) =>
                                    total +
                                      (item?.discountType === "PERCENTAGE"
                                        ? (item?.price * item?.quantity * item?.discountValue) / 100
                                        : item?.quantity * item?.discountValue) || 0,
                                  0,
                                ) +
                                (action[1]?.additionalLines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) -
                                (action[1]?.additionalLines || [])?.reduce(
                                  (total: number, item: any) =>
                                    total +
                                      (item?.discountType === "PERCENTAGE"
                                        ? ((item?.price || 0) * (item?.quantity || 0) * item?.discountValue || 0) / 100
                                        : item?.discountValue || 0) || 0,
                                  0,
                                ) +
                                (action[1]?.shippingAmount || 0)) *
                                (action[1]?.discountValue || 0)) /
                              100
                            : action[1]?.discountValue || 0),
                      )}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Төлбөр баталгаажуулах
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                {!action[1]?.lines ? (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(0)}
                  </Text>
                ) : action[1]?.paymentTerm?.condition === "COD" ? (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(
                      ((action[1]?.discountType === "AMOUNT"
                        ? action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                          (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                          (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                          (action[1]?.lines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? (item?.price * item?.quantity * item?.discountValue) / 100
                                  : item?.quantity * item?.discountValue) || 0,
                            0,
                          ) +
                          action[1]?.shippingAmount -
                          action[1]?.discountValue
                        : action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                          (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                          (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                          (action[1]?.lines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? (item?.price * item?.quantity * item?.discountValue) / 100
                                  : item?.quantity * item?.discountValue) || 0,
                            0,
                          ) +
                          action[1]?.shippingAmount -
                          ((action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                            (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                            (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                            (action[1]?.lines || [])?.reduce(
                              (total: number, item: any) =>
                                total +
                                  (item?.discountType === "PERCENTAGE"
                                    ? (item?.price * item?.quantity * item?.discountValue) / 100
                                    : item?.quantity * item?.discountValue) || 0,
                              0,
                            ) +
                            action[1]?.shippingAmount) *
                            action[1]?.discountValue) /
                            100) *
                        action[1]?.paymentTerm.advancePercent) /
                        100,
                    )}
                  </Text>
                ) : (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {"-"}
                  </Text>
                )}
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Төлбөр баталгаажуулах огноо, цаг
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                {action[1]?.paymentTerm?.condition === "COD" ? (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {dateTimeSecFormat(now_date.setDate(now_date.getDate() + action[1]?.paymentTerm?.confirmationDay))}
                  </Text>
                ) : (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {"-"}
                  </Text>
                )}
              </Grid.Col>
            </Grid>
          </Box>
        </Grid.Col>
      </Grid>
      <Divider color="gray" my="xs" />
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          {"Хавсралт нэмэх"}
        </Text>
        <Flex gap="md" align="center">
          <ActionIcon variant="outline" size="md" color="gray">
            <IconTextPlus onClick={() => {}} />
          </ActionIcon>
        </Flex>
      </Flex>
      <Divider color="gray" my="xs" />
      <Table key={3} columns={columnsAttachments} name="dynamic-values-attachments.list" dataSource={action && action[1]?.attachments} pagination={false} />
      <Divider color="gray" my="xs" />
    </Box>
  );
}

const useHeader = ({ onClick, checked }: { onClick: (key: string, record: any) => void; checked: string[] }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    renderCheck(record) {
      return (
        <Checkbox
          onChange={() => {}}
          onClick={() => {
            if (Array.isArray(record?.rows) && Array.isArray(checked) && record?.rows.length === checked.length) {
              onClick("unselect_all", record?.rows);
            } else onClick("select_all", record?.rows);
          }}
          checked={checked?.length === record?.rows?.length}
        />
      );
    },
    title: "",
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {}}
          onClick={() => {
            onClick("select", record);
          }}
          checked={checked.some((item: any) => item.id === record.id)}
        />
      );
    },
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Avatar src={record?.image ?? noimage} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "SKU код",
    dataIndex: "skuCode",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.skuCode ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.unit ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.price || 0)}
        </Text>
      );
    },
  },
  {
    title: "Хөнгөлөлт",
    dataIndex: "discountValue",
    render: (record) => {
      let count;
      count =
        record?.discountType === "PERCENTAGE"
          ? ((record?.price || 0) * (record?.quantity || 0) * record.discountValue) / 100
          : (record?.quantity || 0) * record?.discountValue;
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(count ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Захиалах тоо",
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record.quantity ?? "0"}
        </Text>
      );
    },
  },
  {
    title: "НӨАТ",
    dataIndex: "hasVat",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.vatAmount ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "НХАТ",
    dataIndex: "hasTax",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.taxAmount ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Үнийн дүн",
    dataIndex: "name",
    render: (record: any) => {
      let count;
      count = (record?.price || 0) * (record?.quantity || 0) + record?.vatAmount;
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(count ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Dropshipping",
    dataIndex: "isDropshipping",
    render: (record) => {
      return (
        <Badge color={record.isDropshipping ? "green" : "gray"} variant="outline">
          {record.isDropshipping ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Дэд SO",
    dataIndex: "",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.childOrderSalesCode ?? "-"}
        </Text>
      );
    },
  },
];

const useHeaderAdditional = (): ColumnType<IAdditionalLines>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Зүйлийн нэр тайлбар",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.unit ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.price ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Тоо хэмжээ",
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record.quantity ?? "0"}
        </Text>
      );
    },
  },
  {
    title: "Хөнгөлөлт төрөл",
    dataIndex: "discountType",
    render: (record) => {
      return (
        <Badge color="cyan" size="md" radius="sm" variant="outline">
          {record.discountType === "PERCENTAGE" && "Хувь"}
          {record.discountType === "AMOUNT" && "Дүн"}
          {!record?.discountType && "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хөнгөлөлт",
    dataIndex: "discountType",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C">
          {record.discountType === "PERCENTAGE" && `${record?.discountValue} %`}
          {record.discountType === "AMOUNT" && currencyFormat(record?.discountValue || 0)}
          {!record?.discountType && "-"}
        </Text>
      );
    },
  },
  {
    title: "Үнийн дүн",
    dataIndex: "name",
    render: (record: any) => {
      let count;
      count =
        (record?.price || 0) * (record?.quantity || 0) -
        (record?.discountType === "PERCENTAGE" ? ((record?.price || 0) * (record?.quantity || 0) * record.discountValue) / 100 : record?.discountValue);
      return (
        <Text size="sm" fw={500} c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(count || 0)}
        </Text>
      );
    },
  },
  {
    title: "Дэд SO",
    dataIndex: "",
    render: () => {
      return (
        <ActionIcon>
          <IconTruckDelivery color="#3BC9DB" />
        </ActionIcon>
      );
    },
  },
];

const useHeaderAttachments = (): ColumnType<IAttachments>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Урл",
    dataIndex: "url",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.url ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Огноо",
    dataIndex: "url",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {dateTimeSecFormat(record?.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.description ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хавсаргасан",
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {renderName(record.attachedUser)}
        </Text>
      );
    },
  },
];
