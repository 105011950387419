import { ActionIcon, Badge, Box, Button, Collapse, Flex, Grid, Group, Input, Text } from "@mantine/core";
import { IconMap2, IconSquareArrowDown, IconSquareArrowUp } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { IAuth } from "../../interfaces/IAuth";
import { dateTimeSecFormat } from "../../utils/date";
import { Form } from "../form";
import { PageLayout } from "../layout";

type Props = {
  action: any[];
};

export type IFormData = {
  name: string;
  deliveryType: string;
};

export function BuyerCustomerDetailForm({ action }: Props) {
  const breadcrumbs = useBreadcrumb();
  const nowMoment = new Date();
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [collapseOpen, setCollapseOpen] = React.useState(true);

  const [data] = React.useState<IFormData>({
    name: "",
    deliveryType: "",
  });

  return (
    <Form validationSchema={() => {}} onSubmit={() => {}} initialValues={data}>
      {() => {
        return (
          <PageLayout
            title="Бизнесийн дэлгэрэнгүй"
            subTitle="Гэрээт харилцагчид хандаж борлуулалтын захиалга бүртгэх"
            breadcrumb={breadcrumbs}
            extra={[
              <Button onClick={() => navigate("/buyer-customer")} key={1} variant="outline">
                Буцах
              </Button>,
            ]}>
            <Box
              style={{
                borderRadius: 5,
                border: `1px solid #eee`,
                padding: "20px 20px",
                borderLeft: `2px outset #3BC9DB`,
              }}
              mb={5}>
              <Flex mih={10} gap="md" justify="space-between" align="center" direction="row" wrap="wrap">
                <Group>
                  <Badge size="md" radius="sm" variant="outline" color="green">
                    {action[1]?.networkStatus || "-"}
                  </Badge>
                </Group>
                <Group>
                  <div>
                    <Group>
                      <Text size="sm" fw={500} c="#44566C">
                        Бүртгэсэн:
                      </Text>
                      <Text size="sm" fw={600} c="#44566C">
                        {user?.lastName !== null ? user?.lastName[0] : "-"}.{user?.firstName !== null ? user?.firstName : "-"}
                      </Text>
                    </Group>
                    <Flex wrap="nowrap" justify="flex-end">
                      <Text size="sm" fw={500} c="cyan">
                        {dateTimeSecFormat(nowMoment)}
                      </Text>
                    </Flex>
                  </div>
                </Group>
              </Flex>
            </Box>
            <Box
              style={{
                borderRadius: 5,
                border: `1px solid #eee`,
                padding: "20px 20px",
                borderLeft: `2px outset #3BC9DB`,
              }}
              mb={5}>
              <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                <Text fw={500}>{"ХАРИЛЦАГЧИЙН МЭДЭЭЛЭЛ"}</Text>
                <ActionIcon variant="transparent">
                  {!collapseOpen ? (
                    <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapseOpen(true)} />
                  ) : (
                    <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapseOpen(false)} />
                  )}
                </ActionIcon>
              </Flex>
            </Box>
            <Collapse in={collapseOpen}>
              <Box
                style={{
                  background: "fff",
                  borderRadius: 5,
                  border: `1px solid #eee`,
                  padding: "20px 20px",
                }}>
                <Grid>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Партнерийн нэр:">
                      <Text fw={500} c="cyan">
                        {action[1]?.partner?.refCode || action[1]?.partner?.businessName
                          ? [action[1]?.partner?.refCode, " ", action[1]?.partner?.businessName]
                          : "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Нийлүүлэгчийн нэр:">
                      <Text fw={500} c="cyan">
                        {action[1]?.refCode || action[1]?.profileName ? [action[1]?.refCode, " ", action[1]?.profileName] : "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                      <Text fw={500} c="cyan">
                        {action[1]?.regNumber || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Төлбөрийн нөхцөл:">
                      <Text fw={500} c="cyan">
                        {action[1]?.paymentTerm?.description || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Нийлүүлэгчийн менежер:">
                      <Text fw={500} c="cyan">
                        {action[1]?.supplierStaff
                          ? action[1]?.supplierStaff?.lastName || action[1]?.supplierStaff?.firstName
                            ? [action[1]?.supplierStaff?.lastName, " ", action[1]?.supplierStaff?.firstName]
                            : "-"
                          : "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Худалдан авагчийн менежер:">
                      <Text fw={500} c="cyan">
                        {action[1]?.buyerStaff
                          ? action[1]?.buyerStaff?.lastName || action[1]?.buyerStaff?.firstName
                            ? [action[1]?.buyerStaff?.lastName, " ", action[1]?.buyerStaff?.firstName]
                            : "-"
                          : "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Хүлээн авах ажилтан:">
                      <Text fw={500} c="cyan">
                        {action[1]?.receiverStaff
                          ? action[1]?.receiverStaff?.lastName || action[1]?.receiverStaff?.firstName
                            ? [action[1]?.receiverStaff?.lastName, " ", action[1]?.receiverStaff?.firstName]
                            : "-"
                          : "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Хүлээн авах салбар:">
                      <Text fw={500} c="cyan">
                        {action[1]?.receiverBranch?.name || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Хүлээн авах хаяг:">
                      <Flex gap="xs" align="center">
                        <ActionIcon variant="transparent">
                          <IconMap2 color="#21bbdd" />
                        </ActionIcon>
                        {action[1]?.receiverBranch ? (
                          action[1]?.receiverBranch?.branchAddress ? (
                            <Text fw={500} c="cyan">
                              {action[1]?.receiverBranch?.branchAddress}
                            </Text>
                          ) : (
                            "-"
                          )
                        ) : (
                          "-"
                        )}
                      </Flex>
                    </Input.Wrapper>
                  </Grid.Col>
                </Grid>
              </Box>
            </Collapse>
          </PageLayout>
        );
      }}
    </Form>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/buyer-customer",
    label: "Гэрээт нийлүүлэгч",
  },
  {
    label: "Бизнесийн дэлгэрэнгүй",
  },
];
