import { LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router";
import useSwr from "swr";
import { OrderApi } from "../../apis";
import { SupplierSalesDetailForm } from "../../components/supplier-sales/detail-form";

export function SupplierSalesDetail() {
  const params = useParams();

  const { data, isLoading, mutate } = useSwr(`/ord/order/detail/get.${params.id}`, async () => await OrderApi.get(params.id as string), {
    fallback: { data: null },
  });

  return (
    <>
      {data && (
        <>
          <LoadingOverlay visible={isLoading} />
          <SupplierSalesDetailForm action={["detail", data]} reload={mutate} />
        </>
      )}
    </>
  );
}
