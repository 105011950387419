import { Divider, LoadingOverlay, SegmentedControl } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDebouncedValue } from "@mantine/hooks";
import { IconCalendarEvent } from "@tabler/icons-react";
import dayjs from "dayjs";
import React from "react";
import useSwr from "swr";
import { DashboardApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { DashboardReal } from "./real";
import { DashboardStatic } from "./static";

export function Dashboard() {
  const breadcrumbs = useBreadcrumb();
  const nowMoment = new Date();
  const [value, setValue] = React.useState("static");
  nowMoment.setDate(nowMoment.getDate() - 1);
  const [pieFilter, setPieFilter] = React.useState<string>("WEEK");
  const [debouncedFilter] = useDebouncedValue(pieFilter, 600);
  const [date, setDate] = React.useState<Date | null>(nowMoment || null);
  const [startDate, setStartDate] = React.useState<Date | null>();
  const [debouncedStartDate] = useDebouncedValue(startDate, 600);
  const [endDate, setEndDate] = React.useState<Date | null>();
  const [debouncedEndDate] = useDebouncedValue(endDate, 600);

  const { data: chartData, isLoading } = useSwr(
    `/erp/order/category/${debouncedFilter}/${date ? date : nowMoment}/${debouncedStartDate ? debouncedStartDate : nowMoment}/${
      debouncedEndDate ? debouncedEndDate : nowMoment
    }`,
    async () => {
      try {
        let res = await DashboardApi.get({
          date: date ? date.toISOString() : nowMoment.toISOString(),
          startDate: debouncedStartDate ? debouncedStartDate.toISOString() : nowMoment.toISOString(),
          endDate: debouncedEndDate ? debouncedEndDate.toISOString() : nowMoment.toISOString(),
          pieFilter: debouncedFilter,
        });
        return res;
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
      }
    },
  );

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <PageLayout
      title="Тоон тойм"
      subTitle=""
      breadcrumb={breadcrumbs}
      extra={[
        <DatePickerInput
          key={1}
          value={date ? date : nowMoment}
          maxDate={nowMoment || date}
          onChange={setDate}
          placeholder="Өдөр сонгох"
          minDate={dayjs(nowMoment || date)
            .subtract(14, "days")
            .toDate()}
          rightSection={<IconCalendarEvent color="gray" />}
        />,
      ]}>
      <Divider />
      <>
        <SegmentedControl
          mt={20}
          mb={20}
          value={value}
          onChange={setValue}
          w={200}
          data={[
            { label: "Жишээ", value: "static" },
            { label: "Бодит", value: "real" },
          ]}
        />
        {value === "static" ? (
          <DashboardStatic pieFilter={pieFilter} />
        ) : (
          <DashboardReal
            data={chartData}
            filter={pieFilter}
            setFilter={setPieFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        )}
      </>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Захилга модуль",
  },
  {
    label: "Дашбоард",
  },
];
