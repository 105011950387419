import { Select } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
  noError?: boolean;
  options: { value: string; label: string }[];
  clearable?: boolean;
  classNames?: Partial<Record<string, string>> | undefined;
  onSearchChange?: (e: string) => void | any;
  defaultValue?: any;
  rightSection?: React.ReactNode;
  styles?: any;
  required?: boolean;
  SelectItem?: any;
  searchable?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  error?: string;
};

export function SelectField({
  name,
  label,
  placeholder,
  noError = false,
  onChange: onChangeValue,
  options = [],
  disabled = false,
  classNames,
  onSearchChange = () => {},
  clearable = true,
  defaultValue,
  rightSection,
  styles,
  required = false,
  SelectItem,
  searchable,
  error,
  size,
}: Props) {
  const { value, error: err, onChange } = useField(name);

  return (
    <Select
      size={size}
      label={label}
      placeholder={placeholder}
      value={defaultValue ? defaultValue : value}
      disabled={disabled}
      onSearchChange={onSearchChange}
      onChange={(value) => {
        onChange(value);

        onChangeValue && onChangeValue(value);
      }}
      rightSection={rightSection}
      error={err || error}
      required
      clearable={clearable}
      data={options}
      style={{ flex: 1 }}
      classNames={classNames}
      styles={{
        error: {
          display: noError ? "none" : "block",
        },
        wrapper: {
          marginBottom: noError && error && 0,
        },
        ...styles,
      }}
      withAsterisk={required}
      renderOption={SelectItem}
      searchable={searchable}
    />
  );
}
