import { ActionIcon, Avatar, Box, Divider, Flex, Grid, Text, Textarea } from "@mantine/core";
import { IconPhoto, IconTextPlus, IconTrash, IconTruckDelivery } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import noimage from "../../assets/no-image.png";
import { IAdditionalLines } from "../../interfaces/IAdditionalLines";
import { IAuth } from "../../interfaces/IAuth";
import { ILines } from "../../interfaces/ILines";
import { currencyFormat } from "../../utils";
import { dateTimeSecFormat } from "../../utils/date";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { ColumnType, Table } from "../table";

export function SupplierDropShippingProductForm({ action, setFieldValue, values }: { action: any; setFieldValue: any; values: any }) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const now_date = new Date();
  const columns = useHeader();
  const columnsAdditional = useHeaderAdditional();

  const columnsAttachments = useHeaderAttachments({
    action,
    user,
    onClick: (key, item) => {
      if (key === "remove") {
        setFieldValue("attachments", [...values.attachments.filter((i: any, index: number) => item.index !== index)]);
      }
    },
  });
  const len = Object.keys((action && action[1]?.lines) || [])?.length;

  return (
    <Box
      sx={(theme) => ({
        background: theme.white,
        borderRadius: 1,
        border: `1px solid ${theme.colors.gray[2]}`,
        padding: "0px 20px",
      })}>
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          {"Бараа бүтээгдэхүүн, ажил үйлчилгээ"}
        </Text>
      </Flex>
      <Divider color="gray" my="xs" />
      <Table columns={columns} name="supplier-sales-order-lines.list" dataSource={action && (action[1].lines || [])} pagination={false} />
      <Divider color="gray" my="xs" />
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          {"Нэмэлтээр"}
        </Text>
      </Flex>
      <Divider color="gray" my="xs" />
      <Table
        columns={columnsAdditional}
        name="supplier-sales-order-list-additional.list"
        dataSource={action && (action[1].additionalLines || [])}
        pagination={false}
      />
      <Divider color="gray" my="xs" />
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          {"Захиалгын нэгтгэл"}
        </Text>
      </Flex>
      <Divider color="gray" my="xs" />
      <Grid mb={5}>
        <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
          <Grid>
            <Grid.Col span={12}>
              {action && action[0] === "edit" ? (
                <TextareaField name="senderNote" label="Харилцагчийн нэмэлт мэдээлэл" placeholder="Тайлбар оруулах: " required />
              ) : (
                <Textarea value={(action && action[1]?.senderNote) || "Нэмэлт мэдээлэл:"} label="Харилцагчийн нэмэлт мэдээлэл" onChange={() => {}} disabled />
              )}
            </Grid.Col>
            <Grid.Col span={12}>
              {action && action[0] === "edit" ? (
                <TextareaField name="senderAdditionalNote" label="Нэмэлт тэмдэглэл" placeholder="Тэмдэглэл оруулах: " required />
              ) : (
                <Textarea value={(action && action[1]?.senderAdditionalNote) || "Нэмэлт тэмдэглэл:"} label="Тэмдэглэл оруулах" onChange={() => {}} disabled />
              )}
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
          <Box
            h="100%"
            sx={(theme) => ({
              backgroundColor: theme.white,
              borderLeft: `2px outset #5fc6d9`,
            })}
            px={20}
            py={16}>
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Захиалгад тоо
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {len} төрөл
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Нийт тоо ширхэг
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity || 0, 0) || 0} ширхэг
                </Text>
              </Grid.Col>

              <Grid.Col span={12}>
                <Text size="lg" fw={500} c="#25262B">
                  {"Тооцоолол"}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Захиалгын нийт дүн
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  <Text>{currencyFormat(action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) || 0)}</Text>
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ml={47}>
                  Тооцсон НӨАТ
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat((action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ml={47}>
                  Тооцсон НХАТ
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat(action[1]?.items?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Тооцсон хөнгөлөлт
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat(
                    ((action && action[1]?.lines) || [])?.reduce(
                      (total: number, item: any) =>
                        total +
                          (item?.discountType === "PERCENTAGE"
                            ? (item?.price * item?.quantity * item?.discountValue) / 100
                            : item?.quantity * item?.discountValue) || 0,
                      0,
                    ) || 0,
                  )}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Хүргэлтийн төлбөр
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat(action[1]?.shippingAmount || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Үнийн дүнгийн хөнгөлөлт
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {currencyFormat(action[1]?.discountValue || 0)}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  {"Нэмэлтийн нийт дүн"}
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {!action[1]?.additionalLines
                    ? currencyFormat(0)
                    : currencyFormat(
                        (action[1]?.additionalLines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) -
                          (action[1]?.additionalLines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? ((item?.price || 0) * (item?.quantity || 0) * item.discountValue) / 100
                                  : item?.discountValue) || 0,
                            0,
                          ),
                      )}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  {"Нийт дүн"}
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C" ta="right">
                  {!action[1]?.lines
                    ? currencyFormat(0)
                    : currencyFormat(
                        action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                          (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                          (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                          (action[1]?.lines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? (item?.price * item?.quantity * item?.discountValue) / 100
                                  : item?.quantity * item?.discountValue) || 0,
                            0,
                          ) +
                          (action[1]?.additionalLines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) -
                          (action[1]?.additionalLines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? ((item?.price || 0) * (item?.quantity || 0) * item?.discountValue || 0) / 100
                                  : item?.discountValue) || 0,
                            0,
                          ) +
                          (action[1]?.shippingAmount || 0) -
                          (action[1]?.discountType === "PERCENTAGE"
                            ? ((action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                                (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                                (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                                (action[1]?.lines || [])?.reduce(
                                  (total: number, item: any) =>
                                    total +
                                      (item?.discountType === "PERCENTAGE"
                                        ? (item?.price * item?.quantity * item?.discountValue) / 100
                                        : item?.quantity * item?.discountValue) || 0,
                                  0,
                                ) +
                                (action[1]?.additionalLines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) -
                                (action[1]?.additionalLines || [])?.reduce(
                                  (total: number, item: any) =>
                                    total +
                                      (item?.discountType === "PERCENTAGE"
                                        ? ((item?.price || 0) * (item?.quantity || 0) * item?.discountValue || 0) / 100
                                        : item?.discountValue || 0) || 0,
                                  0,
                                ) +
                                (action[1]?.shippingAmount || 0)) *
                                (action[1]?.discountValue || 0)) /
                              100
                            : action[1]?.discountValue || 0),
                      )}
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Төлбөр баталгаажуулах
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                {!action[1]?.lines || !action[1]?.paymentTerm ? (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(0)}
                  </Text>
                ) : action[1]?.paymentTerm?.condition === "COD" ? (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(
                      ((action[1]?.discountType === "AMOUNT"
                        ? action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                          (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                          (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                          (action[1]?.lines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? (item?.price * item?.quantity * item?.discountValue) / 100
                                  : item?.quantity * item?.discountValue) || 0,
                            0,
                          ) +
                          action[1]?.shippingAmount -
                          action[1]?.discountValue
                        : action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                          (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                          (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                          (action[1]?.lines || [])?.reduce(
                            (total: number, item: any) =>
                              total +
                                (item?.discountType === "PERCENTAGE"
                                  ? (item?.price * item?.quantity * item?.discountValue) / 100
                                  : item?.quantity * item?.discountValue) || 0,
                            0,
                          ) +
                          action[1]?.shippingAmount -
                          ((action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                            (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                            (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                            (action[1]?.lines || [])?.reduce(
                              (total: number, item: any) =>
                                total +
                                  (item?.discountType === "PERCENTAGE"
                                    ? (item?.price * item?.quantity * item?.discountValue) / 100
                                    : item?.quantity * item?.discountValue) || 0,
                              0,
                            ) +
                            action[1]?.shippingAmount) *
                            action[1]?.discountValue) /
                            100) *
                        action[1]?.paymentTerm.advancePercent) /
                        100,
                    )}
                  </Text>
                ) : (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {"-"}
                  </Text>
                )}
              </Grid.Col>

              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                <Text size="lg" fw={500} c="#44566C">
                  Төлбөр баталгаажуулах огноо, цаг
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                {action[1]?.paymentTerm?.condition === "COD" ? (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {dateTimeSecFormat(now_date.setDate(now_date.getDate() + action[1]?.paymentTerm?.confirmationDay))}
                  </Text>
                ) : (
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {"-"}
                  </Text>
                )}
              </Grid.Col>
            </Grid>
          </Box>
        </Grid.Col>
      </Grid>
      <Divider color="gray" my="xs" />
      <Flex mt={20} direction="row" justify="space-between" mb={20}>
        <Text fw={500} c="#25262B">
          {"Хавсралт нэмэх"}
        </Text>
        <Flex gap="md" align="center">
          {action && action[0] !== "detail" && (
            <ActionIcon variant="outline" size="md" color="#3BC9DB">
              <IconTextPlus
                onClick={() => {
                  setFieldValue("attachments", [
                    ...(values?.attachments || []),
                    {
                      name: "",
                      url: "",
                      description: "",
                    },
                  ]);
                }}
              />
            </ActionIcon>
          )}
        </Flex>
      </Flex>
      <Divider color="gray" my="xs" />
      <Table
        columns={columnsAttachments}
        name="values.attachments"
        dataSource={action && action[0] === "edit" ? (values || []).attachments : (action[1] || []).attachments}
        pagination={false}
      />
      <Divider color="gray" my="xs" />
    </Box>
  );
}

const useHeader = (): ColumnType<ILines>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Avatar src={record?.image ?? noimage} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "SKU код",
    dataIndex: "skuCode",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.skuCode ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.unit ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.price ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Хөнгөлөлт",
    dataIndex: "discountValue",
    render: (record) => {
      let count;
      count =
        record?.discountType === "PERCENTAGE"
          ? ((record?.price || 0) * (record?.quantity || 0) * record.discountValue) / 100
          : (record?.quantity || 0) * record?.discountValue;
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(count ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Захиалах тоо",
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record.quantity ?? "0"}
        </Text>
      );
    },
  },
  {
    title: "НӨАТ",
    dataIndex: "hasVat",
    render: (record) => {
      let count;
      count = (record?.quantity || 0) * record?.vatAmount;
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(count ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "НХАТ",
    dataIndex: "hasTax",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.taxAmount ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Үнийн дүн",
    dataIndex: "name",
    render: (record: any) => {
      let count;
      count =
        (record?.price || 0) * (record?.quantity || 0) +
        (record?.quantity || 0) * (record?.vatAmount || 0) -
        (record?.discountType === "PERCENTAGE"
          ? ((record?.price || 0) * (record?.quantity || 0) * record.discountValue) / 100
          : (record?.quantity || 0) * record?.discountValue);
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(count ?? 0)}
        </Text>
      );
    },
  },
];

const useHeaderAdditional = (): ColumnType<IAdditionalLines>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Зүйлийн нэр тайлбар",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.unit ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.price || 0)}
        </Text>
      );
    },
  },
  {
    title: "Тоо хэмжээ",
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record.quantity ?? "0"}
        </Text>
      );
    },
  },
  {
    title: "Хөнгөлөлт төрөл",
    dataIndex: "discountType",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record.discountType === "PERCENTAGE" && "Хувь"}
          {record.discountType === "AMOUNT" && "Дүн"}
          {!record?.discountType && "-"}
        </Text>
      );
    },
  },
  {
    title: "Хөнгөлөлт",
    dataIndex: "discountType",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record.discountType === "PERCENTAGE" && `${record?.discountValue} %`}
          {record.discountType === "AMOUNT" && currencyFormat(record?.discountValue ?? 0)}
          {!record?.discountType && "-"}
        </Text>
      );
    },
  },
  {
    title: "Үнийн дүн",
    dataIndex: "name",
    render: (record: any) => {
      let count;
      count =
        (record?.price || 0) * (record?.quantity || 0) -
        (record?.discountType === "PERCENTAGE" ? ((record?.price || 0) * (record?.quantity || 0) * record.discountValue) / 100 : record?.discountValue);
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(count || 0)}
        </Text>
      );
    },
  },
  {
    title: "Дэд SO",
    dataIndex: "",
    render: () => {
      return (
        <ActionIcon>
          <IconTruckDelivery color="#3BC9DB" />
        </ActionIcon>
      );
    },
  },
];

const useHeaderAttachments = ({ onClick, action, user }: { onClick: (e: any, e1: any) => void; action: any; user: any }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record, index) => {
      if (action && action[0] === "edit") {
        return <TextField w={220} name={`attachments[${index}].name`} placeholder="нэр:" disabled={action && action[0] === "detail"} />;
      } else {
        return (
          <Text size="sm" c="#44566C" w="max-content">
            {record?.name ?? "-"}
          </Text>
        );
      }
    },
  },
  {
    title: "Урл",
    dataIndex: "url",
    render: (record, index) => {
      if (action && action[0] === "edit") {
        return <TextField w={220} name={`attachments[${index}].url`} placeholder="урл:" />;
      } else {
        return (
          <Text size="sm" c="#44566C" w="max-content">
            {record?.url ?? "-"}
          </Text>
        );
      }
    },
  },
  {
    title: "Огноо",
    dataIndex: "description",
    render: (record) => {
      if (action && action[0] === "edit") {
        return (
          <Text size="sm" c="#44566C">
            {"-"}
          </Text>
        );
      } else {
        return (
          <Text size="sm" c="#44566C" w="max-content">
            {dateTimeSecFormat(record?.createdAt)}
          </Text>
        );
      }
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    render: (record, index) => {
      if (action && action[0] === "edit") {
        return <TextareaField name={`attachments[${index}].description`} placeholder="Тайлбар:" label="" max={100} />;
      } else {
        return (
          <Text size="sm" c="#44566C" w="max-content">
            {record?.url ?? "-"}
          </Text>
        );
      }
    },
  },
  {
    title: "Хавсаргасан",
    dataIndex: "description",
    render: (record) => {
      if (action && action[0] === "edit") {
        return (
          <Text size="sm" fw={500} c="#44566C" w="max-content">
            {user?.lastName[0] ?? "-"}.{user?.firstName ?? "-"}
          </Text>
        );
      } else {
        return (
          <Text size="sm" c="#44566C" w="max-content">
            {(record?.attachedUser || "-")?.lastName[0] || ""}.{(record?.attachedUser || "-")?.firstName || "-"}
          </Text>
        );
      }
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record, index) => {
      return (
        <ActionIcon
          onClick={() => action && action[0] === "edit" && onClick("remove", { ...record, index })}
          color={action && action[0] === "edit" ? "red" : "gray"}>
          <IconTrash />
        </ActionIcon>
      );
    },
  },
];
