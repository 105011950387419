import { LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router";
import useSwr from "swr";
import { OrderApi } from "../../apis";
import { BuyerSalesDetailForm } from "../../components/buyer-sales/detail-form";

export function BuyerSalesDetail() {
  const params = useParams();

  const { data, isLoading } = useSwr(`/ord/order/detail/get.${params.id}`, async () => await OrderApi.get(params?.id as string), {
    fallback: { data: null },
  });

  return (
    <>
      {data && (
        <>
          <LoadingOverlay visible={isLoading} />
          <BuyerSalesDetailForm action={["detail", data]} />
        </>
      )}
    </>
  );
}
