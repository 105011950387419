import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const me = async () => {
  const res = await httpRequest.get("/ord/auth/me");
  return res;
};

export const createPin = async (data: any) => {
  const res = await httpRequest.post("/2fa/auth/pin", data);
  return res;
};

export const updatePin = async (data: any) => {
  const res = await httpRequest.put("/2fa/auth/pin", data);
  return res;
};

export const updateProfile = async (data: any) => {
  const res = await httpRequest.put("/prt/auth/profile", data);
  return res;
};

export const changePassword = async (data: any) => {
  const res = await httpRequest.post("/2fa/auth/password/change", data);
  return res;
};

export const checkPin = async (data: { pin: string }) => {
  const res = await httpRequest.put("/2fa/auth/pin/check", data);
  return res;
};
