import { ActionIcon, Avatar, Badge, Box, Button, Divider, Flex, Grid, Text, Textarea } from "@mantine/core";
import { IconPhoto, IconTextPlus } from "@tabler/icons-react";
import noimage from "../../assets/no-image.png";
import { IAdditionalLines } from "../../interfaces/IAdditionalLines";
import { IAttachments } from "../../interfaces/IAttachments";
import { ILines } from "../../interfaces/ILines";
import { currencyFormat } from "../../utils";
import { dateTimeSecFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";

type Props = {
  action: any;
};

export function PurchaseSalesDynamicDetailForm({ action }: Props) {
  const now_date = new Date();
  const columns = useHeader({
    onClick: (key, record) => {},
  });

  const columnsAdditional = useHeaderAdditional({
    onClick: (key, record) => {},
  });

  const columnsAttachments = useHeaderAttachments({
    onClick: (key, item) => {},
  });

  const len = Object.keys((action && action[1]?.lines) || [])?.length;

  return (
    <>
      <Box
        sx={(theme) => ({
          background: theme.white,
          borderRadius: 5,
          border: `1px solid ${theme.colors.gray[2]}`,
          padding: "0px 20px",
        })}>
        <Flex mt={20} direction="row" justify="space-between" mb={20}>
          <Text fw={500} c="#25262B">
            {"Бараа бүтээгдэхүүн, ажил үйлчилгээ"}
          </Text>
          <Flex gap="md" align="center">
            <ActionIcon variant="outline" size="md" color="gray">
              <IconTextPlus onClick={() => {}} />
            </ActionIcon>
            <Button variant="outline" size="xs" onClick={() => {}} color="gray">
              Багцаар нэмэх
            </Button>
          </Flex>
        </Flex>

        <Divider color="gray.5" my={20} />

        <Table columns={columns} name="supplier-sales-order-list.lines" dataSource={action && (action[1].lines || [])} pagination={false} />

        <Divider color="gray.5" my={20} />
        <Flex mt={20} direction="row" justify="space-between" mb={20}>
          <Text fw={500} c="#25262B">
            {"Нэмэлтээр"}
          </Text>
          <Flex gap="md" align="center">
            <ActionIcon variant="outline" size="md" color="gray">
              <IconTextPlus onClick={() => {}} />
            </ActionIcon>
          </Flex>
        </Flex>

        <Divider color="gray.5" my={20} />

        <Table
          columns={columnsAdditional}
          name="supplier-sales-order-list.additionalLines"
          dataSource={action && (action[1].additionalLines || [])}
          pagination={false}
        />

        <Divider color="gray.5" my={20} />
        <Flex mt={20} direction="row" justify="space-between" mb={20}>
          <Text fw={500} c="#25262B">
            {"Захиалгын нэгтгэл"}
          </Text>
        </Flex>
        <Divider color="gray.5" my={20} />
        <Grid mb={5}>
          <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
            <Grid>
              <Grid.Col span={12}>
                <Textarea value={(action && action[1]?.senderNote) || ""} label="Харилцагчийн нэмэлт мэдээлэл" onChange={() => {}} />
              </Grid.Col>
              <Grid.Col span={12}>
                <Textarea value={(action && action[1]?.senderAdditionalNote) || "Нэмэлт тэмдэглэл:"} label="Тэмдэглэл оруулах" onChange={() => {}} />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
            <Box
              h="100%"
              sx={(theme) => ({
                backgroundColor: theme.white,
                borderLeft: `2px outset #5fc6d9`,
              })}
              px={20}
              py={16}>
              <Grid>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C">
                    Захиалгад тоо
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {len} төрөл
                  </Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C">
                    Нийт тоо ширхэг
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {(action && action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity || 0, 0)) || 0} ширхэг
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C">
                    Захиалгын нийт дүн
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) || 0)}
                  </Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C" ml={47}>
                    Тооцсон НӨАТ
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat((action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) || 0)}
                  </Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C" ml={47}>
                    Тооцсон НХАТ
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat((action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) || 0)}
                  </Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C">
                    Тооцсон хөнгөлөлт
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat(
                      (action[1]?.lines || [])?.reduce(
                        (total: number, item: any) =>
                          total +
                            (item?.discountType === "PERCENTAGE"
                              ? (item?.price * item?.quantity * item?.discountValue) / 100
                              : item?.quantity * item?.discountValue) || 0,
                        0,
                      ) || 0,
                    )}
                  </Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C">
                    Хүргэлтийн төлбөр
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {currencyFormat((action && action[1]?.shippingAmount) || 0)}
                  </Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C">
                    Үнийн дүнгийн хөнгөлөлт
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {action && action[1]?.discountType === "AMOUNT" && currencyFormat((action && action[1]?.discountValue) || 0)}
                    {action && action[1]?.discountType === "PERCENTAGE" && `${(action && action[1]?.discountValue) || 0} %`}
                    {!action && action[1]?.discountType && "-"}
                  </Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C">
                    {"Нийт дүн"}
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C" ta="right">
                    {!action[1]?.lines
                      ? currencyFormat(0)
                      : action[1]?.discountType === "AMOUNT" && action[1]?.discountValue
                      ? currencyFormat(
                          action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                            (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                            (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                            (action[1]?.lines || [])?.reduce(
                              (total: number, item: any) =>
                                total +
                                  (item?.discountType === "PERCENTAGE"
                                    ? (item?.price * item?.quantity * item?.discountValue) / 100
                                    : item?.quantity * item?.discountValue) || 0,
                              0,
                            ) +
                            action[1]?.shippingAmount -
                            action[1]?.discountValue,
                        )
                      : currencyFormat(
                          action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                            (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                            (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                            (action[1]?.lines || [])?.reduce(
                              (total: number, item: any) =>
                                total +
                                  (item?.discountType === "PERCENTAGE"
                                    ? (item?.price * item?.quantity * item?.discountValue) / 100
                                    : item?.quantity * item?.discountValue) || 0,
                              0,
                            ) +
                            action[1]?.shippingAmount -
                            ((action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                              (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                              (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                              (action[1]?.lines || [])?.reduce(
                                (total: number, item: any) =>
                                  total +
                                    (item?.discountType === "PERCENTAGE"
                                      ? (item?.price * item?.quantity * item?.discountValue) / 100
                                      : item?.quantity * item?.discountValue) || 0,
                                0,
                              ) +
                              action[1]?.shippingAmount) *
                              action[1]?.discountValue) /
                              100,
                        )}
                  </Text>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C">
                    Төлбөр баталгаажуулах
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  {!action[1]?.lines ? (
                    <Text size="lg" fw={500} c="#44566C" ta="right">
                      {currencyFormat(0)}
                    </Text>
                  ) : action[1]?.paymentTerm?.condition === "COD" ? (
                    <Text size="lg" fw={500} c="#44566C" ta="right">
                      {currencyFormat(
                        ((action[1]?.discountType === "AMOUNT"
                          ? action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                            (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                            (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                            (action[1]?.lines || [])?.reduce(
                              (total: number, item: any) =>
                                total +
                                  (item?.discountType === "PERCENTAGE"
                                    ? (item?.price * item?.quantity * item?.discountValue) / 100
                                    : item?.quantity * item?.discountValue) || 0,
                              0,
                            ) +
                            action[1]?.shippingAmount -
                            action[1]?.discountValue
                          : action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                            (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                            (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                            (action[1]?.lines || [])?.reduce(
                              (total: number, item: any) =>
                                total +
                                  (item?.discountType === "PERCENTAGE"
                                    ? (item?.price * item?.quantity * item?.discountValue) / 100
                                    : item?.quantity * item?.discountValue) || 0,
                              0,
                            ) +
                            action[1]?.shippingAmount -
                            ((action[1]?.lines?.reduce((total: number, item: any) => total + item!.quantity * item?.price || 0, 0) +
                              (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.vatAmount || 0, 0) +
                              (action[1]?.lines || [])?.reduce((total: number, item: any) => total + item!.quantity * item?.taxAmount || 0, 0) -
                              (action[1]?.lines || [])?.reduce(
                                (total: number, item: any) =>
                                  total +
                                    (item?.discountType === "PERCENTAGE"
                                      ? (item?.price * item?.quantity * item?.discountValue) / 100
                                      : item?.quantity * item?.discountValue) || 0,
                                0,
                              ) +
                              action[1]?.shippingAmount) *
                              action[1]?.discountValue) /
                              100) *
                          action[1]?.paymentTerm.advancePercent) /
                          100,
                      )}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Text size="lg" fw={500} c="#44566C">
                    Төлбөр баталгаажуулах огноо, цаг
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  {action[1]?.paymentTerm?.condition === "COD" ? (
                    <Text size="lg" fw={500} c="#44566C" ta="right">
                      {dateTimeSecFormat(now_date.setDate(now_date.getDate() + action[1]?.paymentTerm?.confirmationDay))}
                    </Text>
                  ) : (
                    <Text size="lg" fw={500} c="#44566C" ta="right">
                      {"-"}
                    </Text>
                  )}
                </Grid.Col>
              </Grid>
            </Box>
          </Grid.Col>
        </Grid>
        <Divider color="gray.5" my={20} />
        <Flex mt={20} direction="row" justify="space-between" mb={20}>
          <Text fw={500} c="#25262B">
            {"Хавсралт нэмэх"}
          </Text>
          <Flex gap="md" align="center">
            <ActionIcon variant="outline" size="md" color="gray">
              <IconTextPlus onClick={() => {}} />
            </ActionIcon>
          </Flex>
        </Flex>
        <Divider color="gray.5" my={20} />
        <Table columns={columnsAttachments} name="values.attachments" dataSource={action && action[1]?.attachments} pagination={false} />
        <Divider color="gray.5" my={20} />
      </Box>
    </>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: ILines) => void }): ColumnType<ILines>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Avatar src={record?.image ?? noimage} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "SKU код",
    dataIndex: "skuCode",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.skuCode ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Бараа, ажил үйлчилгээ",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.unit ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.price ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Хөнгөлөлт",
    dataIndex: "discountValue",
    render: (record) => {
      let count;
      count =
        record?.discountType === "PERCENTAGE"
          ? ((record?.price || 0) * (record?.quantity || 0) * record.discountValue) / 100
          : (record?.quantity || 0) * record?.discountValue;
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(count ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Захиалах тоо",
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record.quantity ?? "0"}
        </Text>
      );
    },
  },
  {
    title: "НӨАТ",
    dataIndex: "hasVat",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.vatAmount ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "НХАТ",
    dataIndex: "hasTax",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.taxAmount ?? 0)}
        </Text>
      );
    },
  },
  {
    title: "Back order",
    dataIndex: "hasTax",
    render: (record) => {
      return (
        <Badge color={record.backorderable ? "green" : "gray"} variant="outline">
          {record.backorderable ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Үнийн дүн",
    dataIndex: "name",
    render: (record) => {
      let count;
      count = (record?.price || 0) * (record?.quantity || 0) + record?.vatAmount;
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(count ?? 0)}
        </Text>
      );
    },
  },
];

const useHeaderAdditional = ({ onClick }: { onClick: (key: string, record: IAdditionalLines) => void }): ColumnType<IAdditionalLines>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Зүйлийн нэр тайлбар",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unit.name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.unit ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгжийн үнэ",
    dataIndex: "price",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(record?.price || 0)}
        </Text>
      );
    },
  },
  {
    title: "Тоо хэмжээ",
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record.quantity ?? "0"}
        </Text>
      );
    },
  },
  {
    title: "Хөнгөлөлт төрөл",
    dataIndex: "discountType",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record.discountType === "PERCENTAGE" && "Хувь"}
          {record.discountType === "AMOUNT" && "Дүн"}
          {!record?.discountType && "-"}
        </Text>
      );
    },
  },
  {
    title: "Хөнгөлөлт",
    dataIndex: "discountType",
    render: (record) => {
      return (
        <>
          <Text c="#44566C" w="max-content">
            {record.discountType === "PERCENTAGE" && `${record?.discountValue} %`}
          </Text>
          <Text style={{ whiteSpace: "nowrap" }} ta="end">
            {record.discountType === "AMOUNT" && currencyFormat(record?.discountValue ?? 0)}
          </Text>
          <Text size="sm" c="#44566C" w="max-content">
            {!record?.discountType && "-"}
          </Text>
        </>
      );
    },
  },
  {
    title: "Үнийн дүн",
    dataIndex: "name",
    render: (record: any) => {
      let count;
      count = (record?.price || 0) * (record?.quantity || 0);
      return (
        <Text size="sm" fw={500} c="#44566C" style={{ whiteSpace: "nowrap" }} ta="end">
          {currencyFormat(count ?? 0)}
        </Text>
      );
    },
  },
];

const useHeaderAttachments = ({ onClick }: { onClick: (key: string, record: IAttachments) => void }): ColumnType<IAttachments>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Урл",
    dataIndex: "url",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.url ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.description ?? "-"}
        </Text>
      );
    },
  },
];
