import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const select = async (data: any) => {
  return httpRequest.get("/ord/branch/select", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/ord/branch/${id}`);
};
