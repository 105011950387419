import CurrencyFormat from "currency-formatter";
import { store } from "../store";
import { authChange } from "../store/Auth";
import HttpHandler from "./http-handler";
import { HttpRequest as BaseHttpRequest } from "./http-request";

const tugrug = (value: number) =>
  CurrencyFormat.format(value, {
    code: "MNT",
    format: "%v %s",
    thousand: "'",
    decimal: ".",
  });

const regNumber = /^[0-9]{7}$/;
const numberRegex = /[0-9]/gm;

export class HttpRequest extends BaseHttpRequest {
  store = store;
  errorHandler = (statusCode: number, error: HttpHandler): void => {
    if (statusCode === 401) {
      store.dispatch(authChange());

      window.location.href = "https://cp.dehub.mn";
    }

    throw error;
  };
}

function currencyFormat(num: number) {
  return (
    parseFloat(`${num}`)
      ?.toFixed(2)
      ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " ₮"
  );
}

export { currencyFormat, numberRegex, regNumber, tugrug };
