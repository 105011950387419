import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const init = async () => {
  return httpRequest.get("/ord/general/init");
};

export const initPub = async () => {
  return httpRequest.get("/ord/pub/general/init");
};
