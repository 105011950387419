import { Button, Center, CloseButton, Divider, Flex, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { OrderApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { message } from "../../utils/message";
import { FormLayout } from "../layout";

export function PurchaseSalesSOForm({ action, onCancel, reload, setChecked }: { action: any[]; onCancel: () => void; reload: any; setChecked?: any }) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = async () => {
    const resData = (action[1] || [])
      .filter((obj: any) => obj.orderStatus === "REVIEWED" && user?.currentBusiness?.id === obj?.receiverBusiness?.id)
      .map((c: any) => c.id);
    setLoading(true);
    try {
      await OrderApi.respond_bulk(resData);
      setChecked([]);
      setLoading(false);
      message.success("Таны хүсэлт амжилттай.");
      reload();
      onCancel && onCancel();
    } catch (err: any) {
      message.error(err.message || "Хүсэлт амжилтгүй.");
    }
    setLoading(false);
  };

  return (
    <FormLayout title="Захиалга зөвшөөрөх" my={20} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
      <LoadingOverlay visible={loading} />

      <Divider color="gray.5" my={20} />

      <Center>
        <Text fw={500} fz={24} c="gray.7">
          Захиалгуудыг зөвшөөрөхдөө итгэлтэй байна уу?
        </Text>
      </Center>

      <Center>
        <Text w={"540px"} ta="center" c="gray.8" fz={18}>
          Таны сонгосон худалдан авагчдын захиалгууд баталгаажих гэж байна. Хэрвээ та итгэлтэй байвал 'Зөвшөөрөх' дарна уу!
        </Text>
      </Center>

      <Center>
        <Text fw={500} fz={18} c="gray.7"></Text>
      </Center>
      <Divider color="gray.5" my={20} />

      <Flex justify="space-between">
        <Button variant="outline" onClick={() => onCancel && onCancel()}>
          Болих
        </Button>
        <Button variant="filled" onClick={() => onSubmit()}>
          Зөвшөөрөх
        </Button>
      </Flex>
    </FormLayout>
  );
}
